import { api } from '../../app/api/baseApi';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';

export const predictionApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPredictionFilters: builder.query<any, any>({
            query: ({ body, realm, organizationId }) => {
                const bodyClone = JSON.parse(JSON.stringify(body));
                // if (bodyClone?.currentOrg) {
                //     delete bodyClone.currentOrg;
                // }
                return {
                    url: '/predictions/v1/prediction/filter',
                    method: 'POST',
                    body: bodyClone,
                    headers: {
                        ...(realm ? { 'x-realm': realm } : {}),
                        ...(organizationId ? { organizationId: organizationId } : {}),
                    },
                };
            },
        }),
        getMobilePredictions: builder.query<any, any>({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: '/predictions/v1/prediction/filter',
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
        }),

        getPredictionById: builder.query<any, any>({
            query: (predictionId) => ({
                url: `/predictions/v1/prediction/${predictionId}`,
                method: 'GET',
            }),
            providesTags: ['UpcomingById'],
        }),
        updatePredictionAcknowledge: builder.mutation({
            query: ({ upcomingId, body }: any) => ({
                url: `/predictions/v1/prediction/${upcomingId}/acknowledge`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error) => (!error ? ['UpcomingById'] : []),
        }),
        createServiceRequest: builder.mutation({
            query: ({ upcomingId, body }: any) => ({
                url: `/predictions/v1/prediction/${upcomingId}/requestService`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error) => (!error ? ['UpcomingById'] : []),
        }),
        getPredictionEvidence: builder.query<any, any>({
            query: ({ predictionId, type }) => ({
                url: `/predictions/v1/prediction/${predictionId}/${type}/evidence`,
                method: 'GET',
            }),
        }),
        getScoreList: builder.query<any, any>({
            query: () => ({
                url: `/ds/v1/demo/parameters`,
                method: 'GET',
            }),
        }),
        updatePredictionStatus: builder.mutation({
            query: ({ upcomingId, body }: any) => ({
                url: `/predictions/v1/prediction/${upcomingId}/status`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error) => (!error ? ['UpcomingById'] : []),
        }),
    }),
    overrideExisting: true,
});
export const {
    useGetPredictionFiltersQuery,
    useGetMobilePredictionsQuery,
    useGetPredictionByIdQuery,
    useUpdatePredictionAcknowledgeMutation,
    useCreateServiceRequestMutation,
    useGetScoreListQuery,
    useUpdatePredictionStatusMutation,
} = predictionApi;
