import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import SimpleCardSkeleton from '../SimpleCardSkeleton';
import { GroupPath } from 'components';

export const DeviceDetails = ({ isLoading, data }: { isLoading: boolean; data: any }): JSX.Element => {
    const theme: any = useTheme();
    return (
        <Paper>
            <List disablePadding>
                {isLoading ? (
                    <SimpleCardSkeleton count={5} />
                ) : (
                    <>
                        <Stack p={2} className="border-bottom-1">
                            <Typography
                                sx={{ fontSize: '14px', color: theme?.palette?.primary?.main }}
                                fontWeight={'600'}
                            >
                                Device Details
                            </Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            className="border-bottom-1"
                            p={2}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Typography className="text-secondary" fontSize={'15px'}>
                                Name
                            </Typography>
                            <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                {data?.data?.deviceName}
                            </Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            className="border-bottom-1"
                            p={2}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Typography className="text-secondary" fontSize={'15px'}>
                                Group
                            </Typography>
                            <Typography fontSize={'15px'} className="text-secondary">
                                <GroupPath fontWeight={'600'} pathName={data?.data?.groupPath ?? []} />
                            </Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            className="border-bottom-1"
                            p={2}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Typography className="text-secondary" fontSize={'15px'}>
                                Model
                            </Typography>
                            <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                {data?.data?.model ?? '-'}
                            </Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            className="border-bottom-1"
                            p={2}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Typography className="text-secondary" fontSize={'15px'}>
                                Serial #
                            </Typography>
                            <Typography fontWeight={'600'} fontSize={'15px'} className="text-secondary">
                                {data?.data?.serialNumber ?? '-'}
                            </Typography>
                        </Stack>
                    </>
                )}
            </List>
        </Paper>
    );
};
