import React from 'react';
import Close from '@mui/icons-material/Close';
import FilterListOff from '@mui/icons-material/FilterListOff';
import Search from '@mui/icons-material/Search';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    List,
    Paper,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { toFirstLetterUpperCase } from 'utils/helpers';
import { useParams } from 'react-router-dom';
import { CustomTransComponent, Hierarchy, renderGroupHierarchyIcons } from 'components';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';
import { GroupHierarchySkeleton } from 'pages/GroupHierarchy/GroupHierarchySkeleton';
import { usePayloadActions } from '@fiji/common/src/hooks/usePayloadActions';
import { useTheme } from '@mui/material/styles';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
import { useGetDocumentTypesQuery } from '@fiji/common/src/features/documents/documentApi';

export const DocumentFilter = ({
    handleOpenFilter,
    payload,
    setPayload,
    customDate,
    setCustomDate,
}: any): JSX.Element => {
    const [cleanPayload] = usePayloadActions();
    const theme: any = useTheme();
    const { type, typeId, categoryId } = useParams();
    const currentOrg = useTypedSelector(selectedOrg);
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [groupsData, setGroupsData] = React.useState();
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState<{ [key: string]: any }>({});
    const [filters, setFilters] = React.useState<any>({ page: 0, size: 100 });
    const [openSelectBox, setOpenSelectBox] = React.useState(false);
    const closeHandler = (): void => {
        handleOpenFilter(false);
    };
    const resetFilters = (key: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(filters?.filters));
        delete payloadClone[key];
        if (key === 'date') {
            setCustomDate([null, null]);
        }
        setFilters({ filters: payloadClone });
    };

    const handleResetAllFilters = (): void => {
        setFilters({});
        setCustomDate([null, null]);
        setPayload((prev: any) => ({ page: prev.page, size: prev.size }));
        closeHandler();
    };

    const [getHeirarchyData, { data: groupHierarchy }] = useGetAllGroupsMutation({
        fixedCacheKey: 'cachedAllGroupData',
    });

    const { data: documentTypeData } = useGetDocumentTypesQuery({
        ...(type === 'device' && typeId && { deviceIds: [typeId] }),
        ...(type === 'group' && typeId !== currentOrg?.id && { groupIds: [typeId] }),
    });

    React.useEffect(() => {
        if (!currentOrg?.defaultGroupAccess && currentOrg?.id) {
            void getHeirarchyData(currentOrg?.id);
        }
    }, [currentOrg]);
    React.useEffect(() => {
        if (!groupsData) {
            setGroupsData(groupHierarchy?.data?.records);
        }
    }, [groupHierarchy]);

    const handleTreeSelection = (parentNode: any): void => {
        if (parentNode?.type === 'DEVICE') {
            const deviceClone = JSON.parse(JSON.stringify(filters?.filters?.groupsAndDevices?.deviceIds ?? []));
            const existingId = deviceClone?.findIndex((id: any) => id === parentNode?.id);
            if (existingId !== -1) {
                deviceClone?.splice(existingId, 1);
            } else {
                deviceClone.push(parentNode?.id);
            }
            setFilters((prev: any) => ({
                ...prev,
                filters: {
                    ...prev.filters,
                    groupsAndDevices: cleanPayload({
                        ...prev?.filters?.groupsAndDevices,
                        deviceIds: deviceClone,
                    }),
                },
            }));
        } else if (parentNode?.type === 'GROUP' || parentNode?.id === currentOrg?.id) {
            const groupClone = JSON.parse(JSON.stringify(filters?.filters?.groupsAndDevices?.groupIds ?? []));
            const existingId = groupClone?.findIndex((id: any) => id === parentNode?.id);
            if (existingId !== -1) {
                groupClone?.splice(existingId, 1);
            } else {
                groupClone.push(parentNode?.id);
            }
            setFilters((prev: any) => ({
                ...prev,
                filters: {
                    ...prev.filters,
                    groupsAndDevices: cleanPayload({
                        ...prev?.filters?.groupsAndDevices,
                        groupIds: groupClone,
                    }),
                },
            }));
        }
    };

    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => {
            if (!prev === false) {
                setHierarchyMappingData({});
            }
            return !prev;
        });
    };

    const handleCleanHierarchyNode = (parentId: string): void => {
        setHierarchyMappingData((prevState) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };
    const payloadHandler = (id: string, data?: any): void => {
        const payloadClone = JSON.parse(JSON.stringify(filters));
        if (id !== 'documentTypes') {
            payloadClone.filters[id] = data;
        } else {
            const docTypeIndex = payloadClone?.filters?.documentTypes?.findIndex((docType: any) => docType === data);
            if (docTypeIndex === undefined) {
                if (payloadClone?.filters === undefined) {
                    payloadClone.filters = { documentTypes: [data] };
                } else {
                    payloadClone.filters.documentTypes = [data];
                }
            } else if (docTypeIndex === -1) {
                payloadClone?.filters?.documentTypes?.push(data);
            } else {
                payloadClone?.filters?.documentTypes?.splice(docTypeIndex, 1);
            }
        }
        setFilters(payloadClone);
    };
    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);

        const { data: childHierarchyData }: any = await getHeirarchyData({ parent: parentData?.id });

        if (childHierarchyData) {
            setHierarchyMappingData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
        return;
    };

    const submitHandler = (): void => {
        setFilters({});
        const documentPayload = {
            filters: {
                ...filters.filters,
                from: new Date(customDate?.[0])?.getTime() === 0 ? null : new Date(customDate?.[0])?.getTime(),
                to: new Date(customDate?.[1])?.getTime() === 0 ? null : new Date(customDate?.[1])?.getTime(),
            },
        };
        if (filters?.filters?.groupsAndDevices?.groupIds?.length) {
            documentPayload.filters['groupIds'] = filters?.filters?.groupsAndDevices.groupIds ?? [];
        }
        if (filters?.filters?.groupsAndDevices?.deviceIds?.length) {
            documentPayload.filters['deviceIds'] = filters?.filters?.groupsAndDevices.deviceIds ?? [];
        }

        delete documentPayload?.filters?.['date'];
        delete documentPayload?.filters?.['groupsAndDevices'];

        const cleanedPayload = cleanPayload(documentPayload?.filters);

        setPayload({ ...payload, filters: { ...cleanedPayload } });
        closeHandler();
    };

    React.useEffect(() => {
        setFilters({
            ...payload,
            filters: {
                ...(type === 'device' && typeId && { deviceIds: [typeId] }),
                ...(type === 'group' && typeId !== currentOrg?.id && { groupIds: [typeId] }),
                documentTypes: [categoryId],
                ...payload?.filters,
            },
        });
    }, []);

    return (
        <Paper className="documents-filter">
            <Box className="padding-16 flex-center-between">
                <Box className="align-items-center">
                    <IconButton id="close" onClick={closeHandler}>
                        <Close />
                    </IconButton>
                    <Typography variant="h6" fontSize={'16px'} fontWeight={'600'}>
                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.TITLE'} />
                    </Typography>
                </Box>
                <IconButton onClick={handleResetAllFilters}>
                    <FilterListOff />
                </IconButton>
            </Box>
            <Divider />
            {filters?.filters && Boolean(Object?.keys(filters?.filters)?.length) && (
                <Stack className="padding-16" flexDirection={'row'} alignItems={'center'} flexWrap={'wrap'}>
                    {Object?.keys(cleanPayload(filters?.filters))?.map(
                        (key: string) =>
                            key !== 'deviceIds' &&
                            key !== 'groupIds' && (
                                <Chip
                                    key={key}
                                    label={toFirstLetterUpperCase(key)}
                                    onDelete={(): void => resetFilters(key)}
                                    variant="outlined"
                                    className="custom-filter-search-btn"
                                />
                            )
                    )}
                </Stack>
            )}
            <Box className="border-bottom-1">
                <Box className="padding-x-16 padding-y-8">
                    <Typography variant="h6" color="primary" fontSize={'16px'} fontWeight={'600'} pb={'20px'}>
                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.FILE_NAME'} />
                    </Typography>

                    <Box className="margin-bottom-20">
                        <FormControl className="w-100">
                            <Box className="position-relative">
                                <InputWithCustomPlaceholder
                                    id="fileName"
                                    value={payload?.filters?.fileName ?? ''}
                                    // onChange={(e: any): void => {
                                    //     setPayload((prev: any) => ({ ...prev, name: e.target.value }));
                                    // }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                        payloadHandler('fileName', e.target.value)
                                    }
                                    variant="outlined"
                                    placeholder={'DOCUMENTS:DOCUMENT_FILTER.FILE_NAME'}
                                    className="w-100"
                                    InputProps={{
                                        endAdornment: (
                                            <Search
                                                style={{
                                                    color: '#777',
                                                }}
                                                id="search"
                                            />
                                        ),
                                    }}
                                />
                                {/* <TextField
                                    id="fileName"
                                    value={filters?.filters?.name ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                        payloadHandler('name', e.target.value)
                                    }
                                    variant="outlined"
                                    placeholder={
                                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.FILE_NAME'} />
                                    }
                                    className="w-100"
                                    InputProps={{
                                        endAdornment: (
                                            <Search
                                                style={{
                                                    color: '#777',
                                                }}
                                                id="search"
                                            />
                                        ),
                                    }}
                                /> */}
                            </Box>
                        </FormControl>
                    </Box>
                </Box>
                <Divider />
                <Box className="padding-16">
                    <Typography variant="h6" color="primary" fontSize={'16px'} fontWeight={'600'} pb={'20px'}>
                        <CustomTransComponent translationKey={'COMMON:DATE'} />
                    </Typography>
                    <Box
                        sx={{
                            '& .MuiDateRangeCalendar-root': { alignSelf: 'center' },
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDateRangePicker
                                value={customDate ?? [null, null]}
                                onChange={(data: any): void => {
                                    payloadHandler('date', data);
                                    setCustomDate(data);
                                }}
                                slotProps={{
                                    toolbar: { hidden: true },
                                    leftArrowIcon: { color: 'primary' },
                                    rightArrowIcon: { color: 'primary' },
                                    actionBar: { actions: [] },
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Divider />
                <Stack className="border-bottom-1">
                    <Box className="padding-16">
                        <Typography variant="h6" color="primary" fontSize={'16px'} fontWeight={'600'} pb={'20px'}>
                            <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.DOCUMENT_TYPE'} />
                        </Typography>
                    </Box>
                    <List className="padding-x-8">
                        {documentTypeData?.data?.map((docType: any) => (
                            <Stack key={docType.categoryId} direction={'row'} alignItems={'center'} spacing={3}>
                                <Checkbox
                                    checked={filters?.filters?.documentTypes?.includes?.(docType?.categoryId) ?? false}
                                    onChange={(): void => payloadHandler('documentTypes', docType?.categoryId)}
                                />
                                <Typography variant="body1">{docType?.categoryId}</Typography>
                            </Stack>
                        ))}
                    </List>
                </Stack>
                <Divider />
                <Box className="padding-16">
                    <Typography variant="h6" color="primary" fontSize={'16px'} fontWeight={'600'} pb={'20px'}>
                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.GROUPS_DEVICES'} />
                    </Typography>
                    <Box>
                        <FormControl variant="filled" className="w-100 margin-8 min-width-120">
                            <InputLabel id="demo-simple-select-filled-label">
                                <CustomTransComponent translationKey={'GROUP_MANAGEMENT:LABELS.GROUPS'} />
                            </InputLabel>
                            <Select
                                labelId="groupsList-label"
                                disabled={window.location.pathname.includes('details')}
                                id="groupsList"
                                value
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                }}
                                open={openSelectBox}
                                onClose={(): void => {
                                    handleToggleSelectBox();
                                }}
                                onOpen={(): void => {
                                    handleToggleSelectBox();
                                }}
                            >
                                <Hierarchy
                                    data={
                                        currentOrg?.defaultGroupAccess
                                            ? [
                                                  {
                                                      id: currentOrg?.id,
                                                      name: `${currentOrg?.name} (Organization Root)`,
                                                      icon: { web: { family: 'material-ui', name: 'CorporateFare' } },
                                                      groupCount: currentOrg?.groupCount,
                                                      deviceCount: currentOrg?.deviceCount,
                                                  },
                                              ]
                                            : groupsData
                                    }
                                    loadMore={handleFetchHierarchy}
                                    cleanHiearchyNode={handleCleanHierarchyNode}
                                    hierarchyMappingData={hierarchyMappingData}
                                    loadingNode={loadingHierarchyNode}
                                    handleTreeNodeSelection={(parentNode): void => {
                                        handleTreeSelection(parentNode);
                                    }}
                                    selectionType={'checkbox'} // radio, checkbox, node
                                    selectedNodes={[
                                        ...(filters?.filters?.groupsAndDevices?.groupIds ?? []),
                                        ...(filters?.filters?.groupsAndDevices?.deviceIds ?? []),
                                    ]}
                                    hasChild={(treeItem): boolean => treeItem?.groupCount || treeItem?.deviceCount}
                                    getAvatarChild={renderGroupHierarchyIcons}
                                    labelKey="name"
                                    autoChildSelection
                                    loadingSkeleton={<GroupHierarchySkeleton row={1} />}
                                />
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>

            <Box className="padding-16">
                <Button
                    id="apply-filter"
                    variant="contained"
                    sx={{
                        width: '100%',
                        backgroundColor: theme?.palette?.primary?.main,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                    onClick={submitHandler}
                >
                    <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.APPLY_FILTERS'} />
                </Button>
            </Box>
        </Paper>
    );
};
