import React from 'react';

import {
    useGetTimelineTrendsQuery,
    useGetTimelineDetailsByIdQuery,
} from '@fiji/common/src/features/Timeline/timelineApi';

import { useParams } from 'react-router-dom';
import { useDebounce } from '@fiji/common/src/hooks';
import { Accordion, Container, Stack } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { EventDetailsSkeleton, EventsHeader, EventsSummary, EventSummaryDetails } from './';
import {
    setAllExpandedEventCategories,
    setEventExpandedCategory,
} from '@fiji/common/src/features/Timeline/timelineSlice';

export const AllTimelineEvents = (): JSX.Element => {
    const { timelineId } = useParams();
    const dispatch = useAppDispatch();

    const expandedCategories = useTypedSelector((state: any) => state['alarmtimeline']['expandedEventCategories']);

    const searchKey = useTypedSelector((state: any) => state['alarmtimeline']['eventSearch']);

    const [, debouncedValue] = useDebounce(undefined, 300, searchKey);

    const {
        data,
        isLoading: detailsLoading,
        isFetching: detailsFetching,
    } = useGetTimelineDetailsByIdQuery(timelineId, { refetchOnMountOrArgChange: true });

    const {
        data: events,
        isLoading,
        isFetching,
    } = useGetTimelineTrendsQuery(
        {
            params: {
                alarmOccurred: data?.data?.alarmOccurred,
                alarmUpdated: data?.data?.alarmUpdated ?? data?.data?.alarmOccurred,
                ...(debouncedValue && { searchKey: 'displayName', searchValue: debouncedValue }),
            },
            deviceId: data?.data?.deviceId,
        },
        { skip: !data?.data?.deviceId }
    );

    React.useEffect(() => {
        if (events?.data && Object?.keys(events?.data)?.length) {
            dispatch(setAllExpandedEventCategories(events?.data?.alarmOccurred));
        }
    }, [events]);

    return (
        <Container className="padding-y-36">
            <EventsHeader />
            <Stack>
                {isLoading || isFetching || detailsFetching || detailsLoading ? (
                    <EventDetailsSkeleton />
                ) : (
                    events &&
                    Boolean(Object.values(events?.data)?.length) &&
                    Object?.keys(expandedCategories)?.length &&
                    Object.keys(expandedCategories)?.map((item: any) => (
                        <Accordion
                            key={item}
                            defaultExpanded
                            expanded={expandedCategories[item] ?? true}
                            onChange={(): void => dispatch(setEventExpandedCategory(item))}
                        >
                            <EventsSummary category={item} />
                            <EventSummaryDetails category={item} events={events} />
                        </Accordion>
                    ))
                )}
            </Stack>
        </Container>
    );
};
