import React from 'react';

import { RowSkeleton } from './';
import { DeviceRowGrid } from './DeviceRowGrid';
import { useTypedSelector } from '@fiji/common';

export const DeviceRow = ({ drilledEvents }: { drilledEvents?: any }): JSX.Element => {
    const isLoading = useTypedSelector((state) => state['schedule']['isLoading']);
    const schedules = useTypedSelector((state) => state['schedule']['schedules']);
    const isGroupingEnabled = useTypedSelector((state) => state['schedule']['isGroupingEnabled']);

    return isLoading ? <RowSkeleton /> : <DeviceRowGrid data={isGroupingEnabled ? drilledEvents : schedules} />;
};
