import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Container,
    Grid,
    TextField,
    Typography,
    Stack,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useMediaQuery,
    CardContent,
    CardActions,
    Box,
    Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

//components
import { Hierarchy } from '../../components/Hierarchy';
import { AutoCompleteTags, DiscardModal, ErrorModal, SubscriptionLimitModal, SubscriptionPlanModal } from './index';
import { BackdropLoader, CustomHeader, CustomTransComponent, renderGroupHierarchyIcons } from '../../components';
// import { renderGroupHierarchyIcons } from '../../components';
import Element from '../../components/Element';

import { handleWhiteSpaces, toFirstLetterUpperCase } from '../../utils/helpers';

import {
    DEVICE_FIELDS_LIMIT,
    TAGS_LIMIT,
    BLUEPRINT_ORGANIZATION_NAME,
    VENDOR_NAME,
    DEVICE_CATEGORY,
    MasterDataKey,
    GATEWAY_VENDOR,
} from '@fiji/common/src/constants';
import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import {
    useCreateTagMutation,
    useGetTagsListMutation,
    useGetTagsQuery,
} from '@fiji/common/src/features/TagManagement/tagManagementApi';
import {
    useCreateDeviceMutation,
    useGetMasterDataMutation,
    useGetUIMetadataMutation,
    useGenerateDeviceNameMutation,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useGetAllGroupsMutation, useGetGroupDetailsQuery } from '@fiji/common/src/features/group/groupApi';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { Device } from '@fiji/common/src/types/Device';

//styles
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import { TagType } from '@fiji/common/src/types';
import SuccessModal from './SuccessModal';
import { useNavigationBack } from 'hooks';
//Constraint
import { metadata } from '@fiji/common/src/constants/validation';
import { useGetAllSubscriptionsQuery } from '@fiji/common/src/features/Subscriptions/subscriptionApi';

const initialDevicePayload = {
    description: '',
    deviceName: '',
    deviceType: '',
    groupId: '',
    tagIds: [],
    deviceModel: '',
    connectedGateway: '',
};

type DeviceType = {
    key: string;
    name: string;
    type: string;
    vendor: string;
    profileId: string;
};

type DeviceModel = {
    key: string;
    value: string;
    profileId?: string;
    serial: string;
    vendor: string;
};

export const AddDevice = (): JSX.Element => {
    const { t } = useTranslation();
    const theme: any = useTheme();
    const { state } = useLocation();
    const { deviceId } = useParams();
    const dispatch = useAppDispatch();
    const classes = useStyles(theme);
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const currentOrg = useTypedSelector(selectedOrg);
    const navigationBack = useNavigationBack();
    const selectedGroup = useTypedSelector((appState: RootState) => appState.common.selectedNode);

    const [devicePayload, setDevicePayload] = useState<Partial<Device>>({
        ...initialDevicePayload,
    });
    const [responseError, setResponseError] = useState<any[]>([]);
    const [errorSavingModal, setErrorSavingModal] = useState(false);
    const [discardChangesModal, setDiscardChangesModal] = useState(false);
    const [subscriptionLimitModal, setSubscriptionLimitModal] = useState(false);

    const [subscriptionPlanModal, setSubscriptionPlanModal] = useState(false);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
    const [isError, setIsError] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [saveType, setSaveType] = useState('');
    const [openSelectBox, setOpenSelectBox] = useState(false);
    const [openConnectedGateway, setOpenConnectedGateway] = useState(false);
    const [deviceModels, setDeviceModels] = React.useState([]);
    const [deviceModel, setDeviceModel] = useState<DeviceModel | null>(null);
    const [pagePayload, setPagePayload] = React.useState({
        page: 0,
        size: 10,
    });
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState<{ [key: string]: any }>({});
    const [connectedGatewayData, setConnectedGatewayData] = React.useState<{ [key: string]: any }>({});
    const [groupDetails, setGroupDetails] = React.useState<any>({
        destinationGroup: '',
    });
    const [connectedGatewayGroup, setConnectedGatewayGroup] = React.useState<any>({
        id: '',
    });
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [loadingConnectedGatewayNode, setLoadingConnectedGatewayNode] = React.useState([]);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [deviceType, setDeviceType] = useState<DeviceType | null>(null);
    const [elements, setElements] = React.useState<any[]>([]);
    const [DeviceNameValidation] = useState<any>(metadata.validation.addDeviceForm.DeviceName);
    const [TagNameValidation] = useState<any>(metadata.validation.addDeviceForm.Tags);
    const [isTagError, setIsTagError] = useState(false);

    const [getHeirarchyData, { isLoading: hierarchyDataLoader }] = useGetAllGroupsMutation({
        fixedCacheKey: 'cachedAllGroupData',
    });
    const { data: groupData } = useGetGroupDetailsQuery(state?.groupId, {
        skip: !state?.groupId,
    });

    const {
        data: deviceTagsList,
        isLoading: isDeviceTagLoading,
        isFetching: isDeviceTagFetching,
        refetch: refetchDeviceTags,
    } = useGetTagsQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
        refetch: RefetchConfigOptions;
    }>(
        {
            page: 0,
            size: 100,
        },
        { skip: !currentOrg?.id }
    );

    const [createTag, { isLoading: isTagCreating }] = useCreateTagMutation();
    const [getTagsList, { isLoading: isTagsListLoading }]: any = useGetTagsListMutation();
    const [createDevice, { isLoading: isDeviceCreated, error: deviceError, data: deviceSuccess }]: any =
        useCreateDeviceMutation();

    const {
        handleSubmit,
        reset,
        control,
        formState: { isDirty, isValid },
        watch,
    } = useForm({ mode: 'onChange' });

    const [getMasterData, { isLoading: isLoadingMasterData }]: any = useGetMasterDataMutation();
    const [getUIMetadata, { isLoading: isLoadingUiMetadata }]: any = useGetUIMetadataMutation();
    const [generateDeviceName, { isLoading: isLoadingDeviceName }]: any = useGenerateDeviceNameMutation();

    const { realmPrefix } = useParams();
    const {
        data: allSubscriptions,
        isLoading: allSubscriptionsLoader,
        // isFetching,
    } = useGetAllSubscriptionsQuery(
        {
            body: {
                ...pagePayload,

                filters: {
                    profileId: deviceModel?.profileId,
                },
            },
            headers: {
                'x-realm': realmPrefix,
                ...(state?.orgId && {
                    organizationId: state?.orgId,
                }),
            },
            params: {
                status: 'ACTIVE',
            },
        },
        { refetchOnMountOrArgChange: true, skip: !deviceModel }
    );

    const handlepagePayload = (page: any): void => {
        setPagePayload(page);
    };

    useEffect(() => {
        const getDeviceTypes = async (): Promise<void> => {
            const deviceTypesData = await getMasterData({
                vendorName: BLUEPRINT_ORGANIZATION_NAME,
                customerName: VENDOR_NAME,
                key: MasterDataKey.DEVICETYPES,
            });

            setDeviceTypes(deviceTypesData?.data?.data?.data?.value);
        };
        if (currentOrg?.id) {
            void getDeviceTypes();
        }
    }, [currentOrg]);

    useEffect(() => {
        setGroupDetails((prev: any) => ({
            ...prev,
            destinationGroup: currentOrg?.id,
            destinationGroupName: `${currentOrg?.name} (Organization Root)`,
            destinationGroupIcon: { web: { family: 'material-ui', name: 'CorporateFare' } },
        }));
        setConnectedGatewayGroup((prev: any) => ({
            ...prev,
        }));
    }, []);

    useEffect(() => {
        if (selectedGroup) {
            setGroupDetails((prev: any) => ({
                ...prev,
                destinationGroup: selectedGroup?.id,
                destinationGroupName: selectedGroup?.name,
                destinationGroupIcon: selectedGroup?.icon,
            }));
        }
    }, [selectedGroup]);

    useEffect(() => {
        if (state && state?.groupId && groupData) {
            setGroupDetails((prev: any) => ({
                ...prev,
                destinationGroup: groupData?.data?.id,
                destinationGroupName: groupData?.data?.name,
                destinationGroupIcon: groupData?.data?.icon,
            }));
        }
    }, [state, groupData]);

    /* The below code is using the `useEffect` hook in a TypeScript React component. It is setting up a
    side effect that will be triggered whenever the `currentOrg` variable changes. */
    useEffect(() => {
        if (currentOrg?.id) {
            refetchDeviceTags()
                .then(() => {})
                .catch(() => {});
        }
    }, [currentOrg]);

    useEffect(() => {
        if (devicePayload.deviceModel && deviceType?.type !== DEVICE_CATEGORY.child) {
            void fetchUIMetadata(deviceModel?.vendor ?? deviceType?.vendor ?? '');
        }
    }, [devicePayload.deviceModel]);

    useEffect(() => {
        if (!allSubscriptionsLoader && deviceModel) {
            const areSubscriptiomLimitReach = allSubscriptions?.data?.records.every(
                (subscription: any) => subscription.remainingQuantity === 0
            );

            if (areSubscriptiomLimitReach && deviceModel) {
                setIsError(true);
                setSubscriptionLimitModal(true);
            } else {
                setIsError(false);
                setSubscriptionLimitModal(false);
            }
        }
    }, [deviceModel, allSubscriptions]);

    useEffect(() => {
        if (
            connectedGatewayGroup?.name !== '' &&
            devicePayload.deviceModel &&
            deviceType?.type === DEVICE_CATEGORY.child
        ) {
            void fetchUIMetadata(connectedGatewayGroup?.vendorTypeName);
        }
    }, [connectedGatewayGroup]);

    /* The below code is using the useEffect hook in a React component. It is listening for changes in
    the variables `deviceError`, `deviceSuccess`, and `saveType`. */
    useEffect(() => {
        if (deviceError) {
            setErrorSavingModal(true);
            setResponseError(deviceError?.data?.errorMessage);
        }
        if (deviceSuccess) {
            if (
                deviceType?.type === DEVICE_CATEGORY.gateway &&
                (deviceModel?.vendor.toLowerCase() === GATEWAY_VENDOR.dashboard ||
                    deviceModel?.vendor.toLowerCase() === GATEWAY_VENDOR.starfish ||
                    deviceModel?.vendor.toLowerCase() === GATEWAY_VENDOR.generic)
            ) {
                setSuccessModal(true);
            } else {
                dispatch(
                    setMessageContent({
                        isOpen: true,
                        message: t('ADD_DEVICE_FORM:DEVICE_SAVED_SUCCESSFULLY', {
                            replace: { deviceName: devicePayload.deviceName },
                        }),

                        duration: 3000,
                    })
                );

                if (saveType === 'saveAs') {
                    resetForm();
                    // setSaveType('');
                } else {
                    navigationBack();
                }
            }
        }
    }, [deviceError, deviceSuccess]);

    useEffect(() => {
        reset();
        setElements([]);
        setConnectedGatewayGroup({
            id: '',
            name: '',
            icon: '',
            vendorTypeName: '',
        });
    }, [devicePayload.deviceType, devicePayload.deviceModel]);

    useEffect(() => {
        if (groupDetails.destinationGroup && devicePayload.deviceModel !== '') {
            const deviceDetails = getDeviceDetails();
            const getDeviceName = async (): Promise<void> => {
                const { data: deviceNameRes, error } = await generateDeviceName({
                    deviceTypeId: deviceDetails.profileId,
                    groupId: groupDetails.destinationGroup,
                });

                if (!error) {
                    setDevicePayload((prev) => ({ ...prev, deviceName: deviceNameRes.data.deviceName }));
                }
            };

            void getDeviceName();
        }
    }, [devicePayload.deviceType, groupDetails.destinationGroup, devicePayload.deviceModel]);

    const fetchUIMetadata = async (vendor: string): Promise<void> => {
        const uiMetadataData = await getUIMetadata({
            vendorTypeName: vendor,
            deviceTypeName: toFirstLetterUpperCase(deviceType?.type ?? ''),
        });
        setElements(uiMetadataData?.data?.data.data);
    };

    const getDeviceDetails = (): any => {
        let details = {};
        if (deviceType?.type === DEVICE_CATEGORY.gateway || deviceType?.type === DEVICE_CATEGORY.standalone) {
            details = { vendor: deviceModel?.vendor, profileId: deviceModel?.profileId };
        } else if (deviceType?.type === DEVICE_CATEGORY.child) {
            details = { vendor: connectedGatewayGroup?.vendorTypeName, profileId: deviceModel?.profileId };
        } else {
            details = { vendor: deviceType?.vendor, profileId: deviceType?.profileId };
        }
        return details;
    };

    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);

        const { data: childHierarchyData }: any = await getHeirarchyData({ parent: parentData?.id });

        if (childHierarchyData) {
            setHierarchyMappingData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
        return;
    };

    const handleCleanHierarchyNode = (parentId: string): void => {
        setHierarchyMappingData((prevState) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };

    const fetchConnectedGateway = async (parentData: any): Promise<void> => {
        setLoadingConnectedGatewayNode((prevState): any => [...prevState, parentData?.id]);

        const { data: childHierarchyData }: any = await getHeirarchyData({ parent: parentData?.id });

        if (childHierarchyData) {
            setConnectedGatewayData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingConnectedGatewayNode((prevState): any =>
            prevState.filter((loadingNode) => loadingNode !== parentData?.id)
        );
        return;
    };

    const cleanConnectedGatewayNode = (parentId: string): void => {
        setConnectedGatewayData((prevState) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };

    const handleTreeSelection = (parentNode: any): void => {
        setConnectedGatewayGroup((prev: any) => ({
            ...prev,
            id: parentNode.id,
            name: parentNode.name,
            icon: parentNode.icon,
            vendorTypeName: parentNode.vendorTypeName,
        }));
        handleConnectedGatewaySelect();
    };

    /**
     * The `payloadHandler` function updates the `devicePayload` state based on the value of the target
     * element, and also triggers a debounce handler for specific target IDs.
     * @param {any} e - The parameter `e` is an event object that is passed to the `payloadHandler`
     * function. It contains information about the event that triggered the function, such as the
     * target element, the event type, and any additional data associated with the event.
     * @returns The function `payloadHandler` returns `void`, which means it does not return any value.
     */
    const payloadHandler = (e: any): void => {
        let key: string = e.target.id;

        if (e.target.value && key === 'deviceName') {
            // show error right away if it is regexp error
            const regexp = new RegExp(DeviceNameValidation.pattern);
            const testReturn = regexp.test(e.target.value);
            if (!testReturn) {
                return;
            }
        }

        if (e.target.name) {
            key = e.target.name;
        }
        if (e.target.value.length > DEVICE_FIELDS_LIMIT[key]) {
            return;
        }
        setDevicePayload((prev) => ({ ...prev, [key]: e.target.value }));
    };

    const handleDeviceType = async (e: any): Promise<void> => {
        let key: string = e.target.id;
        if (e.target.name) {
            key = e.target.name;
        }

        const selectedDeviceType: DeviceType | undefined = deviceTypes.find(
            (element: any) => element.key === e.target.value
        );
        setDeviceType(selectedDeviceType ?? null);
        setDevicePayload((prev) => ({ ...prev, [key]: e.target.value, deviceModel: '' }));

        if (selectedDeviceType) {
            const deviceModelsData = await getMasterData({
                vendorName: BLUEPRINT_ORGANIZATION_NAME,
                customerName: VENDOR_NAME,
                key: e.target.value,
            });

            setDeviceModels(deviceModelsData.data?.data?.data?.value);
        }
    };

    const handleDeviceModel = (e: any): void => {
        let key: string = e.target.id;
        if (e.target.name) {
            key = e.target.name;
        }
        deviceModels.find((x: any) => (x.key === e.target.value ? setDeviceModel(x) : ''));
        setDevicePayload((prev) => ({ ...prev, [key]: e.target.value }));
    };

    /**
     * The function `selectedTagsHandler` updates the `tagIds` property in the `devicePayload` state
     * based on the selected tags.
     * @param {any} e - The parameter "e" is an event object that represents the event that triggered
     * the selectedTagsHandler function. It is typically used to access information about the event,
     * such as the target element or the event type.
     * @param {any} values - The `values` parameter is an array that contains the selected tags.
     * @returns If the length of `values` is greater than `TAGS_LIMIT`, nothing is being returned.
     * Otherwise, the `setDevicePayload` function is being called with an updated `tagIds` property in
     * the `prev` object.
     */
    const selectedTagsHandler = (e: any, values: any): void => {
        if (values?.length > TAGS_LIMIT) {
            return;
        }
        setDevicePayload((prev: any) => ({ ...prev, tagIds: values }));
    };

    /**
     * The function `handleKeyDown` is an asynchronous function that is triggered when a key is
     * pressed, and if the pressed key is the Enter key and certain conditions are met, it creates a
     * new device tag and updates the device payload.
     * @param {any} e - The parameter `e` is an event object that represents the keydown event. It
     * contains information about the event, such as the key code and target element.
     */
    const handleKeyDown = (e: any): void => {
        setIsTagError(false);
        // show error right away if it is regexp error
        const regexp = new RegExp(TagNameValidation.pattern);
        const testReturn = regexp.test(e.target.value);
        if (!testReturn) {
            setIsTagError(true);
        }

        if (e.code === 'Enter' && devicePayload?.tagIds && devicePayload?.tagIds?.length < TAGS_LIMIT && testReturn) {
            const tagValue = e.target.value;

            const devicePayloadClone = JSON.parse(JSON.stringify(devicePayload));
            devicePayloadClone.tagIds.push(tagValue);
            setDevicePayload(devicePayloadClone);
        }
    };

    const createDeviceTag = async (): Promise<void> => {
        let filteredTagsChosenIds = [];
        const allTagsDataNames = deviceTagsList?.data.records.map((item: TagType) => item.name);
        const newTagsToCreate = devicePayload.tagIds?.filter((x) => !allTagsDataNames.includes(x));

        if (newTagsToCreate?.length) {
            const createTagPromises = newTagsToCreate.map(
                async (tagName) =>
                    await createTag({
                        name: tagName,
                    })
            );
            await Promise.all(createTagPromises);

            const result = await getTagsList({
                page: 0,
                size: 100,
            });
            const resultTags = result?.data?.data?.records;
            filteredTagsChosenIds = resultTags
                ?.filter((x: { name: any }) => devicePayload.tagIds?.includes(x.name))
                .map((item: { id: any }) => item.id);
        } else {
            filteredTagsChosenIds = deviceTagsList?.data.records
                ?.filter((x: { name: any }) => devicePayload.tagIds?.includes(x.name))
                .map((item: { id: any }) => item.id);
        }
        return filteredTagsChosenIds;
    };

    const handleSubscriptionSelect = (data: string): void => {
        setSelectedSubscriptionId(data);
    };
    /**
     * The submitHandler function is used to handle form submission in a TypeScript React component,
     * updating or creating a device based on the form data.
     * @param {any} data - The parameter `data` is an data coming for dynamic form fields.
     * @param {any} e - The parameter `e` is an event object that is passed to the `submitHandler`
     * function. It is typically an object that contains information about the event that triggered the
     * function, such as the target element that was clicked or the form that was submitted. In this
     * case, it is being used to
     */
    const submitHandler = async (data: any, e: any): Promise<void> => {
        setSaveType(e.target.id);
        if (!allSubscriptionsLoader && allSubscriptions.data.records.length > 1) {
            setSubscriptionPlanModal(true);
        } else {
            setSaveType(e.target.id);
            const deviceDetails = getDeviceDetails();
            const deviceTags = await createDeviceTag();

            const payload = {
                deviceTypeName: toFirstLetterUpperCase(deviceType?.type || ''),
                vendorTypeName: deviceDetails.vendor,
                payload: {
                    serial: deviceModel?.key, // for dashboard
                    requestedFrom: 'Remote', // for starfish
                    deviceProfileID: deviceDetails.profileId,
                    name: devicePayload.deviceName,
                    subscriptionId: allSubscriptions.data.records[0].id,
                    groupId: groupDetails.destinationGroup,
                    tagId: deviceTags,
                    description: devicePayload.description,
                    ...(deviceType?.type === DEVICE_CATEGORY.child && {
                        parentDeviceId: connectedGatewayGroup.id,
                        deviceType: deviceModel?.value,
                    }),
                    ...data,
                },
            };

            await createDevice(payload);
        }
    };
    const submitHandlerPlanModal = async (data: any): Promise<void> => {
        setSubscriptionPlanModal(false);

        const deviceDetails = getDeviceDetails();
        const deviceTags = await createDeviceTag();
        const payload = {
            deviceTypeName: toFirstLetterUpperCase(deviceType?.type || ''),
            vendorTypeName: deviceDetails.vendor,
            payload: {
                serial: deviceModel?.key, // for dashboard
                requestedFrom: 'Remote', // for starfish
                deviceProfileID: deviceDetails.profileId,
                name: devicePayload.deviceName,
                subscriptionId: selectedSubscriptionId,
                groupId: groupDetails.destinationGroup,
                tagId: deviceTags,
                description: devicePayload.description,
                ...(deviceType?.type === DEVICE_CATEGORY.child && {
                    parentDeviceId: connectedGatewayGroup.id,
                    deviceType: deviceModel?.value,
                }),
                ...data,
            },
        };

        await createDevice(payload);
    };
    /**
     * The function `handleTagDelete` is used to remove a tag from an array of tag IDs in a device
     * payload.
     * @param {any} data - The `data` parameter is of type `any`, which means it can be any data type.
     * It is not specified what kind of data it represents in this context.
     * @param {number} index - The `index` parameter is the index of the element in the `tagIds` array
     * that needs to be deleted.
     */
    const handleTagDelete = (data: any, index: number): void => {
        const devicePayloadClone = JSON.parse(JSON.stringify(devicePayload));
        devicePayloadClone.tagIds.splice(index, 1);
        setDevicePayload(devicePayloadClone);
    };

    const isValidDeviceModel = (): boolean => {
        if (devicePayload.deviceModel === '') {
            return true;
        }
        return false;
    };

    const isValidConnectedGateway = (): boolean => {
        if (deviceType?.type === DEVICE_CATEGORY.child && connectedGatewayGroup.id === '') {
            return true;
        }
        return false;
    };

    /**
     * The function `isValidCommonField` returns `true` if any of the specified conditions are met,
     * otherwise it returns `false`.
     * @returns The function `isValidCommonField` returns a boolean value.
     */
    const isValidCommonField = (): boolean => {
        if (
            devicePayload.deviceName === '' ||
            devicePayload.deviceType === '' ||
            groupDetails.destinationGroup === '' ||
            !handleWhiteSpaces([devicePayload.deviceName, devicePayload?.description])
        ) {
            return true;
        }
        return false;
    };

    const disableHandler = (): boolean =>
        isValidCommonField() || isValidDeviceModel() || isValidConnectedGateway() || !isValid;

    /**
     * The function `handleToggleSelectBox` toggles the state of `openSelectBox` between `true` and
     * `false`.
     */
    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => {
            if (!prev === false) {
                setHierarchyMappingData({});
            }
            return !prev;
        });
    };
    const handleConnectedGatewaySelect = (): void => {
        setOpenConnectedGateway((prev) => {
            if (!prev === false) {
                setHierarchyMappingData({});
            }
            return !prev;
        });
    };

    /**
     * The function checks if the initial device payload is equal to the current device payload and
     * navigates to a different page if they are, otherwise it sets a discard changes modal to true.
     */
    const handleDiscardChanges = (): void => {
        if (JSON.stringify(initialDevicePayload) !== JSON.stringify(devicePayload) || isDirty) {
            setDiscardChangesModal(true);
        } else {
            navigationBack();
        }
    };

    const handleOk = (): void => {
        setSuccessModal(false);

        // setSubscriptionLimitModal(false);

        if (saveType === 'saveAs') {
            resetForm();
        } else if (saveType === 'save') {
            navigationBack();
        }
    };

    const handleSubscription = (): void => {
        setSubscriptionLimitModal(false);

        setDevicePayload({
            ...initialDevicePayload,
            deviceType: devicePayload.deviceType,
        });
    };

    const resetForm = (): void => {
        setDeviceType(null);
        setDeviceModel(null);
        reset();
        setElements([]);
        setDevicePayload({
            ...initialDevicePayload,
        });
        setSuccessModal(false);
    };

    return (
        <React.Fragment>
            <header>
                <title>{t('COMMON.ADD_DEVICE')}</title>
                <meta name="description" content={t('ADD_DEVICE_FORM.ADD_DEVICE_DESCRIPTION')} />
            </header>
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                }}
                minHeight={'100vh'}
                position={'relative'}
            >
                <CustomHeader
                    title={<CustomTransComponent translationKey={'ROUTE_TITLES:ADD_DEVICE'} />}
                    subtitle={currentOrg?.name ?? ''}
                    handleNavigateBack={handleDiscardChanges}
                />
                <Container fixed className={classes.ContainerWrapper}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardBody}>
                            <Typography variant={'h6'} className={classes.sectionHeader}>
                                <CustomTransComponent
                                    translationKey={'ADD_DEVICE_FORM:HEADER'}
                                    replace={{ orgName: currentOrg?.name ?? '' }}
                                />
                            </Typography>
                            <Typography variant={'body2'} className={classes.sectionHeader}>
                                <CustomTransComponent translationKey={'ADD_DEVICE_FORM:INFORM_MANDATORY_FIELDS'} />
                            </Typography>
                            <FormControl variant={'filled'} className={classes.formControl} required fullWidth>
                                <InputLabel id={'select-device-type'} required={true}>
                                    <CustomTransComponent translationKey={'DEVICE_FORM_COMMON:DEVICE_TYPE'} />
                                </InputLabel>
                                <Select
                                    id="device-type"
                                    disabled={isLoadingMasterData}
                                    labelId={'select-device-type'}
                                    value={devicePayload?.deviceType ?? ''}
                                    name="deviceType"
                                    onChange={(e): void => {
                                        void handleDeviceType(e);
                                    }}
                                >
                                    {deviceTypes &&
                                        deviceTypes.map((device: any) => (
                                            <MenuItem key={`key${device.id}`} value={device?.key}>
                                                {device?.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            {devicePayload.deviceType && (
                                <>
                                    <FormControl variant={'filled'} className={classes.formControl} required fullWidth>
                                        <InputLabel id={'select-device-model'} required={true}>
                                            <CustomTransComponent translationKey={'DEVICE_FORM_COMMON:DEVICE_MODEL'} />
                                        </InputLabel>
                                        <Select
                                            id="device-model"
                                            disabled={isLoadingMasterData}
                                            labelId={'select-device-model'}
                                            value={devicePayload?.deviceModel ?? ''}
                                            name="deviceModel"
                                            onChange={(e): void => {
                                                handleDeviceModel(e);
                                            }}
                                        >
                                            {deviceModels?.map((device: any) => (
                                                <MenuItem key={`key${device.key}`} value={device?.key}>
                                                    {device?.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {deviceType?.type !== DEVICE_CATEGORY.standalone && (
                                        <>
                                            {devicePayload.deviceModel &&
                                                deviceType?.type === DEVICE_CATEGORY.child && (
                                                    <FormControl
                                                        variant={'filled'}
                                                        className={classes.formControl}
                                                        required
                                                        fullWidth
                                                    >
                                                        <InputLabel id={'select-connected-gateway'} required={false}>
                                                            <CustomTransComponent
                                                                translationKey={'EDIT_DEVICE_FORM:CONNECTED_GATEWAY'}
                                                            />
                                                        </InputLabel>
                                                        <Select
                                                            displayEmpty
                                                            id="connectedGateway"
                                                            labelId="select-connected-gateway"
                                                            disabled={hierarchyDataLoader}
                                                            renderValue={(): any => (
                                                                <>
                                                                    <Typography
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignContent: 'center',
                                                                            py: '8px',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            {connectedGatewayGroup?.icon &&
                                                                                renderGroupHierarchyIcons({
                                                                                    icon: connectedGatewayGroup?.icon,
                                                                                })}
                                                                        </div>
                                                                        <span>{connectedGatewayGroup?.name}</span>
                                                                    </Typography>
                                                                </>
                                                            )}
                                                            value={connectedGatewayGroup?.name ? ' ' : ''}
                                                            open={openConnectedGateway}
                                                            onClose={handleConnectedGatewaySelect}
                                                            onOpen={handleConnectedGatewaySelect}
                                                        >
                                                            <Hierarchy
                                                                labelKey="name"
                                                                data={[
                                                                    {
                                                                        id: currentOrg?.id,
                                                                        name: `${currentOrg?.name} (Organization Root)`,
                                                                        icon: {
                                                                            web: {
                                                                                family: 'material-ui',
                                                                                name: 'CorporateFare',
                                                                            },
                                                                        },
                                                                        groupCount: currentOrg?.groupCount,
                                                                        deviceCount: currentOrg?.deviceCount,
                                                                    },
                                                                ]}
                                                                loadMore={fetchConnectedGateway}
                                                                cleanHiearchyNode={cleanConnectedGatewayNode}
                                                                hierarchyMappingData={connectedGatewayData}
                                                                loadingNode={loadingConnectedGatewayNode}
                                                                selectionType={'radio'}
                                                                selectedNodes={connectedGatewayGroup?.id}
                                                                hasChild={(treeItem): boolean =>
                                                                    treeItem?.groupCount ||
                                                                    (treeItem?.deviceCount &&
                                                                        treeItem?.type !== 'GATEWAY')
                                                                }
                                                                getAvatarChild={renderGroupHierarchyIcons}
                                                                handleTreeNodeSelection={(parentNode): void => {
                                                                    handleTreeSelection(parentNode);
                                                                }}
                                                                filters={[
                                                                    {
                                                                        key: 'type',
                                                                        operator: '!==',
                                                                        value: 'GROUP',
                                                                        action: 'disableCheck',
                                                                    },
                                                                    {
                                                                        key: 'id',
                                                                        operator: '!==',
                                                                        value: currentOrg?.id,
                                                                        action: 'disableCheck',
                                                                    },
                                                                    {
                                                                        key: 'type',
                                                                        operator: '!==',
                                                                        value: 'DEVICE',
                                                                        action: 'hidden',
                                                                    },
                                                                    {
                                                                        key: 'type',
                                                                        operator: '!==',
                                                                        value: 'CHILD',
                                                                        action: 'hidden',
                                                                    },
                                                                    {
                                                                        key: 'vendorTypeName',
                                                                        operator: '!==',
                                                                        value: 'Dashboard',
                                                                        action: 'hidden',
                                                                    },
                                                                ]}
                                                            />
                                                        </Select>
                                                    </FormControl>
                                                )}
                                        </>
                                    )}

                                    {!isError && devicePayload.deviceModel && (
                                        <>
                                            <FormControl
                                                variant={'filled'}
                                                className={classes.formControl}
                                                required
                                                fullWidth
                                            >
                                                <InputLabel id={'select-Assign-to-Group'} required={false}>
                                                    <CustomTransComponent translationKey={'COMMON:ASSIGN_TO_GRP'} />
                                                </InputLabel>
                                                <Select
                                                    displayEmpty
                                                    id="groupId"
                                                    labelId="demo-customized-select-label"
                                                    disabled={hierarchyDataLoader}
                                                    renderValue={(): any => (
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignContent: 'center',
                                                                    py: '8px',
                                                                }}
                                                            >
                                                                <div>
                                                                    {renderGroupHierarchyIcons({
                                                                        icon: groupDetails?.destinationGroupIcon,
                                                                    })}
                                                                </div>
                                                                <span>{groupDetails?.destinationGroupName}</span>
                                                            </Typography>
                                                        </>
                                                    )}
                                                    value={groupDetails?.destinationGroupName ? ' ' : ''}
                                                    open={openSelectBox}
                                                    onClose={handleToggleSelectBox}
                                                    onOpen={handleToggleSelectBox}
                                                >
                                                    <Hierarchy
                                                        labelKey="name"
                                                        data={[
                                                            {
                                                                id: currentOrg?.id,
                                                                name: `${currentOrg?.name} (Organization Root)`,
                                                                icon: {
                                                                    web: {
                                                                        family: 'material-ui',
                                                                        name: 'CorporateFare',
                                                                    },
                                                                },
                                                                groupCount: currentOrg?.groupCount,
                                                                deviceCount: currentOrg?.deviceCount,
                                                            },
                                                        ]}
                                                        loadMore={handleFetchHierarchy}
                                                        cleanHiearchyNode={handleCleanHierarchyNode}
                                                        hierarchyMappingData={hierarchyMappingData}
                                                        loadingNode={loadingHierarchyNode}
                                                        selectionType={'radio'}
                                                        selectedNodes={groupDetails?.destinationGroup}
                                                        hasChild={(treeItem): boolean => treeItem?.groupCount}
                                                        getAvatarChild={renderGroupHierarchyIcons}
                                                        handleTreeNodeSelection={(parentNode): void => {
                                                            setGroupDetails((prev: any) => ({
                                                                ...prev,
                                                                destinationGroup: parentNode.id,
                                                                destinationGroupName: parentNode.name,
                                                                destinationGroupIcon: parentNode.icon,
                                                            }));
                                                            handleToggleSelectBox();
                                                        }}
                                                        filters={[
                                                            {
                                                                key: 'type',
                                                                operator: '!==',
                                                                value: 'DEVICE',
                                                                action: 'hidden',
                                                            },
                                                            {
                                                                key: 'type',
                                                                operator: '!==',
                                                                value: 'GATEWAY',
                                                                action: 'hidden',
                                                            },
                                                            {
                                                                key: 'type',
                                                                operator: '!==',
                                                                value: 'CHILD',
                                                                action: 'hidden',
                                                            },
                                                        ]}
                                                    />
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                className={classes.formControl}
                                                value={devicePayload?.deviceName}
                                                id="deviceName"
                                                required
                                                label={
                                                    <CustomTransComponent
                                                        translationKey={'DEVICE_FORM_COMMON:DEVICE_NAME'}
                                                    />
                                                }
                                                fullWidth
                                                variant={'filled'}
                                                onChange={payloadHandler}
                                                sx={{ marginBottom: '0 !important' }}
                                                inputProps={{
                                                    maxLength: DeviceNameValidation.maxLength,
                                                }}
                                            />
                                            <Typography
                                                variant="body2"
                                                className="custom-add-device-field-lengths-style"
                                            >
                                                {`${devicePayload?.deviceName?.length}/${DEVICE_FIELDS_LIMIT['deviceName']}`}
                                            </Typography>

                                            {!isLoadingUiMetadata && elements
                                                ? elements.map(
                                                      (field: any) =>
                                                          (watch('communicationType') === field.show ||
                                                              !field.show) && (
                                                              <Element key={field.id} field={field} control={control} />
                                                          )
                                                  )
                                                : null}

                                            <Divider
                                                sx={{
                                                    mb: 3,
                                                    mx: 'auto',
                                                }}
                                            />

                                            <AutoCompleteTags
                                                error={isTagError}
                                                value={devicePayload?.tagIds}
                                                disabled={isDeviceTagFetching || isDeviceTagLoading}
                                                onChange={selectedTagsHandler}
                                                options={
                                                    deviceTagsList?.data.records.map((device: any) => device.name) ?? []
                                                }
                                                handleTagDelete={handleTagDelete}
                                                onKeyDown={handleKeyDown}
                                                placeholder={t('COMMON:TAGS')}
                                            />
                                            <Box className="modal-space-between">
                                                <Typography
                                                    variant="body2"
                                                    sx={{ paddingLeft: '16px' }}
                                                    className="custom-add-device-field-lengths-style"
                                                >
                                                    <CustomTransComponent
                                                        translationKey={'DEVICE_FORM_COMMON:TAGS_LIMIT_TEXT'}
                                                        replace={{ count: TAGS_LIMIT }}
                                                    />
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    className="custom-add-device-field-lengths-style"
                                                >
                                                    {`${devicePayload?.tagIds?.length || 0}/${TAGS_LIMIT}`}
                                                </Typography>
                                            </Box>
                                            <TextField
                                                value={devicePayload?.description}
                                                id="description"
                                                label={<CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />}
                                                fullWidth
                                                variant={'filled'}
                                                onChange={payloadHandler}
                                                sx={{ marginBottom: '0 !important' }}
                                            />
                                            <Typography
                                                variant="body2"
                                                sx={{ marginBottom: '0 !important' }}
                                                className="custom-add-device-field-lengths-style"
                                            >
                                                {`${devicePayload?.description?.length}/${DEVICE_FIELDS_LIMIT['description']}`}
                                            </Typography>
                                        </>
                                    )}
                                </>
                            )}
                        </CardContent>
                        <CardActions className={classes.cardFooter}>
                            {sm ? null : (
                                <Stack spacing={2} direction={'column'} sx={{ width: '100%' }}>
                                    <Button
                                        id="save"
                                        sx={{
                                            backgroundColor: theme?.palette?.primary?.main,
                                            '&:hover': {
                                                backgroundColor: theme?.palette?.primary?.main,
                                            },
                                            '&.Mui-disabled': {
                                                color: theme?.palette?.primary?.[200],
                                                backgroundColor: theme?.palette?.primary?.[50],
                                            },
                                        }}
                                        variant={'contained'}
                                        onClick={(e): void => {
                                            void handleSubmit(submitHandler)(e);
                                        }}
                                        data-test="save-device-btn"
                                    >
                                        {<CustomTransComponent translationKey={'ADD_DEVICE_FORM:SAVE_DEVICE'} />}
                                    </Button>
                                    <Button
                                        id="saveAs"
                                        sx={{
                                            border: `1px solid ${theme?.palette?.primary?.main}`,
                                            '&:hover': {
                                                backgroundColor: theme?.palette?.primary?.[50],
                                                color: theme?.palette?.primary?.main,
                                            },
                                            '&.Mui-disabled': {
                                                border: `1px solid ${theme?.palette?.primary?.[200]}`,
                                                color: theme?.palette?.primary?.[200],
                                            },
                                        }}
                                        variant={'outlined'}
                                        data-test="save-and-add-new-device-btn"
                                    >
                                        {
                                            <CustomTransComponent
                                                translationKey={'ADD_DEVICE_FORM:SAVE_AND_ADD_NEW_DEVICE'}
                                            />
                                        }
                                    </Button>
                                    <Button
                                        sx={{
                                            border: `1px solid ${theme?.palette?.primary?.main}`,
                                            '&:hover': {
                                                backgroundColor: theme?.palette?.primary?.[50],
                                                color: theme?.palette?.primary?.main,
                                            },
                                            '&.Mui-disabled': {
                                                border: `1px solid ${theme?.palette?.primary?.[200]}`,
                                                color: theme?.palette?.primary?.[200],
                                            },
                                        }}
                                        variant={'outlined'}
                                        onClick={handleDiscardChanges}
                                        data-test="cancel-btn"
                                    >
                                        <CustomTransComponent translationKey={'COMMON:CANCEL'} />
                                    </Button>
                                </Stack>
                            )}
                            {smDown ? null : (
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Button
                                            sx={{
                                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                                '&:hover': {
                                                    backgroundColor: theme?.palette?.primary?.[50],
                                                    color: theme?.palette?.primary?.main,
                                                },
                                                '&.Mui-disabled': {
                                                    border: `1px solid ${theme?.palette?.primary?.[200]}`,
                                                    color: theme?.palette?.primary?.[200],
                                                },
                                            }}
                                            variant={'outlined'}
                                            onClick={handleDiscardChanges}
                                            data-test="cancel-btn"
                                            id="cancel"
                                        >
                                            <CustomTransComponent translationKey={'COMMON:CANCEL'} />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                                            {!deviceId && (
                                                <Button
                                                    sx={{
                                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                                        '&:hover': {
                                                            backgroundColor: theme?.palette?.primary?.[50],
                                                            color: theme?.palette?.primary?.main,
                                                        },
                                                        '&.Mui-disabled': {
                                                            border: `1px solid ${theme?.palette?.primary?.[200]}`,
                                                            color: theme?.palette?.primary?.[200],
                                                        },
                                                    }}
                                                    variant={'outlined'}
                                                    disabled={disableHandler()}
                                                    id="saveAs"
                                                    onClick={(e): void => {
                                                        void handleSubmit(submitHandler)(e);
                                                    }}
                                                    data-test="save-and-add-new-device-btn"
                                                >
                                                    <CustomTransComponent
                                                        translationKey={'ADD_DEVICE_FORM:SAVE_AND_ADD_NEW_DEVICE'}
                                                    />
                                                </Button>
                                            )}

                                            <Button
                                                sx={{
                                                    backgroundColor: theme?.palette?.primary?.main,
                                                    '&:hover': {
                                                        backgroundColor: theme?.palette?.primary?.main,
                                                    },
                                                    '&.Mui-disabled': {
                                                        color: theme?.palette?.primary?.[200],
                                                        backgroundColor: theme?.palette?.primary?.[50],
                                                    },
                                                }}
                                                id="save"
                                                variant={'contained'}
                                                disabled={disableHandler()}
                                                onClick={(e): void => {
                                                    void handleSubmit(submitHandler)(e);
                                                }}
                                                data-test="save-device-btn"
                                            >
                                                <CustomTransComponent translationKey={'ADD_DEVICE_FORM:SAVE_DEVICE'} />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )}
                        </CardActions>
                    </Card>
                </Container>
                <ErrorModal
                    key="errorModal"
                    retryHandler={(e): any => {
                        setErrorSavingModal(false);
                        void handleSubmit(submitHandler)(e);
                    }}
                    isVisible={errorSavingModal}
                    messages={responseError}
                    modalHandler={(action: boolean): void => setErrorSavingModal(action)}
                    clearPayload={(): void => setDevicePayload(initialDevicePayload)}
                    deviceId={deviceId}
                />
                <DiscardModal
                    key="discardModal"
                    isVisible={discardChangesModal}
                    modalHandler={(action: boolean): void => setDiscardChangesModal(action)}
                />
                <SuccessModal
                    key="successModal"
                    isVisible={successModal}
                    modalHandler={(action: boolean): void => setSuccessModal(action)}
                    connectionString={deviceSuccess?.data?.connectionString}
                    okHandler={(): void => {
                        handleOk();
                    }}
                />

                <SubscriptionLimitModal
                    key="subscriptionLimit"
                    isVisible={subscriptionLimitModal}
                    modalHandler={(action: boolean): void => setSubscriptionLimitModal(action)}
                    deviceModal={devicePayload.deviceModel}
                    okHandler={(): void => {
                        handleSubscription();
                    }}
                />
                <SubscriptionPlanModal
                    key="subscriptionPlan"
                    isVisible={subscriptionPlanModal}
                    handlepagePayload={handlepagePayload}
                    modalHandler={(action: boolean): void => setSubscriptionPlanModal(action)}
                    isLoading={allSubscriptionsLoader}
                    subscriptionPlan={allSubscriptions?.data?.records}
                    total={allSubscriptions?.data?.total}
                    handleSubscriptionSelect={handleSubscriptionSelect}
                    okHandler={(e): any => {
                        void handleSubmit(submitHandlerPlanModal)(e);
                    }}
                />
                {(isLoadingUiMetadata ||
                    isLoadingMasterData ||
                    isDeviceCreated ||
                    isLoadingDeviceName ||
                    isDeviceTagLoading ||
                    isTagCreating ||
                    isTagsListLoading) && <BackdropLoader isOpen={true} />}
            </Box>
        </React.Fragment>
    );
};
