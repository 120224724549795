import React, { ReactNode } from 'react';
import { CustomModal } from './CustomModal';
import { Box, Stack } from '@mui/material';
import DOMPurify from 'dompurify';
import { CustomTransComponent } from './CustomTransComponent';

/**
 * The `ConfirmModalProps` type defines the props for a confirm modal component in a TypeScript React
 * application.
 * @property {boolean} isVisible - A boolean value indicating whether the confirm modal is visible or
 * not.
 * @property {string} header - The `header` property is a string that represents the title or heading
 * of the confirm modal.
 * @property {string} actionButtonText - The `actionButtonText` property is a string that represents
 * the text to be displayed on the action button of the confirm modal.
 * @property {string} type - The `type` property is an optional string that can be used to specify the
 * type of the confirm modal. It can be used for styling or to differentiate between different types of
 * confirm modals.
 * @property {React.ReactNode | string} description - The `description` property is used to provide the
 * content of the modal. It can be either a React node or a string.
 * @property confirmClick - A function that will be called when the user clicks on the action button to
 * confirm the action.
 * @property onCancel - The `onCancel` property is a function that will be called when the user cancels
 * or closes the modal.
 * @property {boolean} isLoading - The `isLoading` property is a boolean that indicates whether a
 * loading state should be displayed in the confirm modal. When `isLoading` is `true`, it typically
 * means that an action is being processed and the user should wait for the action to complete before
 * interacting with the modal.
 * @property {'text' | 'outlined' | 'contained'} leftButtonVariant - The `leftButtonVariant` property
 * is an optional string that specifies the variant of the left button in the confirm modal. It can
 * have one of the following values: 'text', 'outlined', or 'contained'.
 * @property {'text' | 'outlined' | 'contained'} rightButtonVariant - The `rightButtonVariant` property
 * is an optional string that specifies the variant of the right button in the confirm modal. It can
 * have one of the following values: 'text', 'outlined', or 'contained'.
 * @property {'success' | 'error'} buttonColor - The `buttonColor` property is an optional string that
 * specifies the color of the action button in the confirm modal. It can have two possible values:
 * "success" or "error".
 * @property sx - The `sx` property is an optional object that allows you to pass custom styling to the
 * ConfirmModal component. It accepts a key-value pair where the key is a CSS property and the value is
 * the corresponding styling value. This allows you to customize the appearance of the ConfirmModal
 * component according to your needs
 * @property {unknown} [key: unknown] - The `[key: unknown]` indicates that the `ConfirmModalProps`
 * object can have additional properties with unknown keys and values. This allows for flexibility in
 * adding any additional properties to the `ConfirmModalProps` object without explicitly defining them
 * in the type definition.
 */
type ConfirmModalProps = {
    noCancel?: boolean;
    isVisible: boolean;
    header: ReactNode;
    actionButtonText: ReactNode;
    type?: string;
    description: ReactNode | TrustedHTML;
    icon?: React.ReactNode;
    confirmClick: () => void;
    onCancel: () => void;
    isLoading?: boolean;
    unTrimHeader?: boolean;
    leftButtonVariant?: 'text' | 'outlined' | 'contained';
    rightButtonVariant?: 'text' | 'outlined' | 'contained';
    buttonColor?: 'success' | 'error';
    sx?: { [key: string]: unknown };
    isStacked?: boolean;
    [key: string]: unknown;
};

/* The code is defining and exporting a React component called `ConfirmModal`. This component is a
memoized functional component, meaning it will only re-render if its props change. */
export const ConfirmModal = React.memo((props: ConfirmModalProps): JSX.Element => {
    const confirmModalId = React.useId();
    const confirmModalRef = React.useRef(null);
    const getActionButtonSx = (): { [key: string]: unknown } => {
        if (props?.buttonColor === 'error') {
            return {
                backgroundColor: '#CA3C3D',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#CA3C3D',
                    color: '#fff',
                },
            };
        } else if (props?.sx?.action) {
            return { ...props?.sx?.action };
        }
        return {};
    };

    const getButtonClassName = (): string => {
        const actionButtonStyles = getActionButtonSx();
        return actionButtonStyles.backgroundColor === '#CA3C3D' ? 'error-btn' : '';
    };

    const actions = [
        {
            key: 'cancel',
            ...(props?.isStacked && { stackedKey: 'stacked' }),
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: props?.leftButtonVariant ?? 'outlined',
            handleClick: (): void => {
                props.onCancel();
            },
            sx: props?.sx?.cancel ? { ...props?.sx?.cancel } : {},
        },
        {
            key: 'confirm',
            ...(props?.isStacked && { stackedKey: 'stacked' }),
            label: props?.actionButtonText,
            variant: props?.rightButtonVariant ?? 'contained',
            sx: getActionButtonSx(),
            className: getButtonClassName(),
            color: props?.buttonColor,
            handleClick: props.confirmClick,
            disabled: props?.isLoading ?? false,
        },
    ];

    return (
        <CustomModal
            isOpen={props.isVisible}
            type={props?.type ?? 'primary'}
            ref={confirmModalRef}
            actions={props?.noCancel ? actions?.filter((action) => action.key !== 'cancel') : actions}
            isStacked={props?.isStacked}
            key={confirmModalId}
            unTrimHeader={props?.unTrimHeader}
            {...props}
        >
            {props?.icon && (
                <Box justifyContent={'center'} pb={4} display={'flex'}>
                    {props?.icon}
                </Box>
            )}
            <Stack id="alert-dialog-description">
                {typeof props?.description === 'string'
                    ? ((
                          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props?.description ?? '') }}></div>
                      ) as any)
                    : props?.description ?? <></>}
            </Stack>
        </CustomModal>
    );
});
