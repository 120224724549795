import { api } from '../../app/api/baseApi';

const schdeuleApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllSchedules: builder.query({
            query: (body) => ({
                url: `automation/v1/schedule/filter`,
                method: 'POST',
                body: body,
            }),
            providesTags: ['Schedules'],
        }),
        createSchedule: builder.mutation({
            query: (body) => ({
                url: `automation/v1/schedule`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Schedules'] : []),
        }),
        overLapSchedule: builder.mutation({
            query: (body) => ({
                url: `automation/v1/schedule/overlap`,
                method: 'POST',
                body,
            }),
        }),
        deleteSchedule: builder.mutation<any, any>({
            query: (body) => ({
                url: `/automation/v1/schedule`,
                method: 'DELETE',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Schedules'] : []),
        }),
        getScheduleById: builder.query<any, any>({
            query: ({ scheduleId }) => ({
                url: `/automation/v1/schedule/${scheduleId}`,
                method: 'GET',
            }),
        }),
        updateSchedule: builder.mutation<any, any>({
            query: ({ body, scheduleId }) => ({
                url: `/automation/v1/schedule/${scheduleId}`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Schedules'] : []),
        }),
        editScheduleStatus: builder.mutation<any, any>({
            query: ({ params, scheduleId }) => ({
                url: `/automation/v1/schedule/${scheduleId}/status`,
                method: 'PATCH',
                params,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Schedules'] : []),
        }),
    }),
});

export const {
    useGetAllSchedulesQuery,
    useOverLapScheduleMutation,
    useCreateScheduleMutation,
    useDeleteScheduleMutation,
    useGetScheduleByIdQuery,
    useUpdateScheduleMutation,
    useEditScheduleStatusMutation,
} = schdeuleApi;
