import React from 'react';

import { useLocation } from 'react-router-dom';
import { CardContent } from '@mui/material';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { DetailsCardSkeleton } from '../common/Skeletons';
import { DynamicWidget, Loader } from 'components';
import { useWidgetConfigHandlers } from '../common';
import { getWidgetTypeValidationConfig } from '@fiji/common/src/utils/helpers';

export const PreviewWidgetCardContent = (): JSX.Element => {
    const { state } = useLocation();
    const configurationLoader = useTypedSelector((data) => data.widgetConfiguration?.['isLoading']);
    const configurationPayload = useTypedSelector((data) => data.widgetConfiguration?.['data']);

    const {
        renderSx,
        details: { widgetDetails },
        isLoading,
    } = useWidgetConfigHandlers();

    const renderLoader = (): JSX.Element => {
        if (getWidgetTypeValidationConfig(state?.typeId)['loaderType'] === 'skeleton') {
            return (
                <DetailsCardSkeleton
                    secondarylength={5}
                    {...(getWidgetTypeValidationConfig(state?.typeId)?.['primarySkeletonCount'] && {
                        primarylength: getWidgetTypeValidationConfig(state?.typeId)?.['primarySkeletonCount'],
                    })}
                />
            );
        }
        return <Loader size={55} />;
    };

    return (
        <CardContent
            className="padding-0"
            {...((configurationLoader || isLoading) && {
                sx: renderSx(),
            })}
        >
            {configurationLoader || isLoading ? (
                renderLoader()
            ) : (
                <DynamicWidget
                    mode="configured"
                    widgetTypeId={widgetDetails?.widgetType?.id}
                    widgetData={{
                        internalCdnJsLink: widgetDetails?.widgetType?.internalCdnJsLink,
                        widgetTypeId: widgetDetails?.widgetType?.id,
                        widgetName: widgetDetails?.name,
                        ...configurationPayload,
                    }}
                    {...(widgetDetails?.widgetType?.id === 'map' && {
                        minHeight: '400px',
                        countKey: 'deviceCount',
                    })}
                />
            )}
        </CardContent>
    );
};
