import React from 'react';
import { useParams } from 'react-router-dom';
import { useCountdownTimer } from 'hooks/useCountdownTimer';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { CustomTransComponent } from 'components';
import { Avatar, Button } from '@mui/material';
import { Snooze } from '@mui/icons-material';
import { setCloseSnooze } from '@fiji/common/src/features/Timeline/timelineSlice';

export const SnoozeHeader = React.memo((): JSX.Element => {
    const dispatch = useAppDispatch();
    const { deviceId } = useParams();
    const time: any = useCountdownTimer(deviceId ?? '', false);
    const timerString: string = useTypedSelector((state) => state.alarmtimeline.timerString);
    const closeSnooze: boolean = useTypedSelector((state) => state.alarmtimeline.closeSnooze);

    React.useEffect(() => {
        let timeOut: any;
        if (deviceId) {
            dispatch(setCloseSnooze(false));
            timeOut = setTimeout(() => {
                dispatch(setCloseSnooze(true));
            }, 10000);
        }
        return (): any => clearTimeout(timeOut);
    }, [deviceId]);
    return (
        time?.isVisible &&
        !closeSnooze && (
            <InfoListItem
                title={<CustomTransComponent translationKey={'COMMON:DEVICE_SNOOZED'} />}
                subtitle={timerString}
                divider={'full'}
                iconAlign="center"
                statusColor={'#983FEF'}
                icon={
                    <Avatar sx={{ bgcolor: '#983FEF' }}>
                        <Snooze sx={{ color: '#fff' }} />
                    </Avatar>
                }
                rightComponent={
                    <Button variant="outlined" onClick={(): void => dispatch(setCloseSnooze(true))}>
                        <CustomTransComponent translationKey={'ADD_DEVICE_FORM:OK_BTN'} />
                    </Button>
                }
                ripple
            />
        )
    );
});
