import './mqtt-patch';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import './scss/main.scss';
import { StyledEngineProvider } from '@mui/material/styles';
import '@brightlayer-ui/react-themes/open-sans';
import { Provider } from 'react-redux';
import { BackdropLoader } from './components';
import { setupStore } from '@fiji/common/src/app/store';

const RootApp = lazy(() => import('./RootApp'));

const container = document.getElementById('root');
const root = createRoot((container as HTMLElement) || document.createDocumentFragment());

const store = setupStore(window?.APP_CONFIG?.API_URL, window?.APP_CONFIG?.SUFFIX);

root.render(
    <Provider store={store}>
        <Suspense fallback={<BackdropLoader isOpen={true} showDefault={false} />}>
            <StyledEngineProvider injectFirst>
                <RootApp />
            </StyledEngineProvider>
        </Suspense>
    </Provider>
);

if (module.hot) module.hot.accept();
