import React from 'react';

import { useParams } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import {
    useGetDeviceByIdQuery,
    useUpdateThresholdMutation,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';

type ReturnProps = {
    payload: any;
    setPayload: any;
    resetPayload: any;
    setExpandedCategories: any;
    expandedCategories: any;
    handleAllExpandCollapse: () => void;
    thresholdUpdateLoader: boolean;
    submitHandler: (data: any, cancelCallback: any) => Promise<void>;
};

const initialPayload = [
    { name: 'Low Critical', id: 'lowCritical', value: 0, isEnabled: false },
    { name: 'Low Warning', id: 'lowWarning', value: 0, isEnabled: false },
    { name: 'High Warning', id: 'highWarning', value: 0, isEnabled: false },
    { name: 'High Critical', id: 'highCritical', value: 0, isEnabled: false },
];

export const useHandleDeviceDetails = (deviceDetails?: any): ReturnProps => {
    const { deviceId } = useParams();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);

    const { data: deviceData }: any = useGetDeviceByIdQuery(deviceId, { skip: !currentOrg?.id });

    const [updateThreshold, { isLoading: thresholdUpdateLoader }] = useUpdateThresholdMutation();

    const [payload, setPayload] = React.useState(initialPayload);

    const [expandedCategories, setExpandedCategories] = React.useState<{ [key: string]: boolean }>({});

    React.useEffect(() => {
        if (deviceDetails?.data && Object?.keys(deviceDetails?.data)?.length) {
            const requiredCategories: { [key: string]: boolean } = {};
            Object.keys(deviceDetails?.data)?.forEach((category: any) => {
                requiredCategories[category] = true;
            });
            setExpandedCategories(requiredCategories);
        }
    }, [deviceDetails]);

    const handleAllExpandCollapse = (): void => {
        const isExpanded = Object?.values(expandedCategories)?.every((item: any) => item === true);
        const expandedCategoriesClone = JSON.parse(JSON.stringify(expandedCategories));
        for (const key in expandedCategoriesClone) {
            if (isExpanded) {
                expandedCategoriesClone[key] = false;
            } else {
                expandedCategoriesClone[key] = true;
            }
        }
        setExpandedCategories(expandedCategoriesClone);
    };

    const submitHandler = async (elementData: any, cancelCallback: any): Promise<void> => {
        const { error }: any = await updateThreshold({
            body: payload?.map((item: any) => ({ name: item?.id, value: item?.value, isEnabled: item?.isEnabled })),
            channelKey: elementData?.channelId,
            deviceId,
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: `${
                        elementData?.displayName ?? deviceData?.data?.name ?? '-'
                    } thresholds has been modified`,
                })
            );
            cancelCallback();
            setPayload(initialPayload);
        }
    };

    return {
        payload,
        setPayload,
        submitHandler,
        expandedCategories,
        setExpandedCategories,
        thresholdUpdateLoader,
        handleAllExpandCollapse,
        resetPayload: (): void => setPayload(initialPayload),
    };
};
