import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type MessageType = {
    isOpen: boolean;
    duration?: number;
    message?: any;
    isUnauthorized?: boolean;
    extraArgs?: any;
};

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        urlSuffix: '',
        message: {
            isOpen: false,
            duration: 0,
            message: '',
        },
        isLoader: false,
        isSuperRealm: false,
        isAdminRealm: false,
        selectedNodeLoader: true,
        selectedTabAdminPortal: '',
        selectedNode: undefined,
        pageHeader: {},
        tabKey: undefined,
        selectedRealm: undefined,
        xRealm: undefined,
        appHeaderTitle: undefined,
        selectedRealmNode: undefined,
        appHeaderSubTitle: undefined,
        appTitle: '',
        applicationColor: '',
        isDirtyForm: false,
        confirmDirtyForm: false,
        isDrawerOpen: true,
        drawerBottomsheeetOpen: false,
        drawerSwitchOrg: false,
        authModal: false,
        logout: false,
        mqttCredentials: {},
        selectedOrgNameServiceAdminPortal: '',
        lastInteractionTime: '',
        deleteConfirmationKey: '',
        headerColor: '#007BC1',
        timelineStatusColor: '',
        deviceSideBar: {
            isEnable: false,
            sideBarOpen: false,
            selectedIds: [],
        },
        snoozeDuration: '',
        mobilePermissions: [],
        globalPermissions: [],
        googleMapLoaded: false,
        internalPageHeader: {
            bottomSheetOpen: false,
            selectedPageHeader: false,
            headerCount: 0,
        },
        mqttConnectionStatus: false,
        needsUpdate: false,
    },
    reducers: {
        setMessageContent: (state: { message: MessageType }, action: PayloadAction<MessageType>) => {
            state.message = action.payload;
        },
        toggleLoader: (state, action: PayloadAction<boolean>) => {
            state.isLoader = action.payload;
        },
        setSelectedNodeLoader: (state, action: PayloadAction<any>) => {
            state.selectedNodeLoader = action.payload;
        },
        setSelectedNode: (state, action: PayloadAction<any>) => {
            state.selectedNode = action.payload;
        },
        setSelectedTabAdminPortal: (state, action: PayloadAction<any>) => {
            state.selectedTabAdminPortal = action.payload;
        },
        setPageHeaderData: (state, action: PayloadAction<any>) => {
            state.pageHeader = action.payload;
        },
        setAppHeaderTitle: (state, action: PayloadAction<any>) => {
            state.appHeaderTitle = action.payload;
        },
        setRealmNode: (state, action: PayloadAction<any>) => {
            state.selectedRealmNode = action.payload;
        },
        setTabKey: (state: RootState, action: PayloadAction<any>) => {
            state['tabKey'] = action.payload;
        },
        setMqttCredentials: (state: RootState, action: PayloadAction<{ [key: string]: any }>) => {
            state['mqttCredentials'] = action.payload;
        },
        setAppHeaderSubTitle: (state, action: PayloadAction<any>) => {
            state.appHeaderSubTitle = action.payload;
        },
        setAppTitle: (state, action: PayloadAction<any>) => {
            state.appTitle = action.payload;
        },
        setSelectedRealm: (state, action: PayloadAction<any>) => {
            state.selectedRealm = action.payload;
        },
        setXRealm: (state, action: PayloadAction<any>) => {
            state.xRealm = action.payload;
        },
        setApplicationColor: (state, action: PayloadAction<any>) => {
            state.applicationColor = action.payload;
        },
        setIsSuperRealm: (state, action: PayloadAction<any>) => {
            state.isSuperRealm = action.payload;
        },
        setIsAdminRealm: (state, action: PayloadAction<any>) => {
            state.isAdminRealm = action.payload;
        },
        setIsDirtyForm: (state, action: PayloadAction<boolean>) => {
            state.isDirtyForm = action.payload;
        },
        setConfirmDirtyForm: (state, action: PayloadAction<boolean>) => {
            state.confirmDirtyForm = action.payload;
        },
        setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
            state.isDrawerOpen = action.payload;
        },
        setDrawerBottomsheetOpen: (state, action: PayloadAction<boolean>) => {
            state.drawerBottomsheeetOpen = action.payload;
        },
        setDrawerSwitchOrg: (state, action: PayloadAction<boolean>) => {
            state.drawerSwitchOrg = action.payload;
        },
        setAuthModal: (state, action: PayloadAction<any>) => {
            state.authModal = action.payload;
        },
        setLogout: (state, action: PayloadAction<any>) => {
            state.logout = action.payload;
        },
        setSelectedOrgNameServiceAdminPortal: (state, action: PayloadAction<string>) => {
            state.selectedOrgNameServiceAdminPortal = action.payload;
        },
        setLastInteractionTime: (state, action: PayloadAction<any>) => {
            state.lastInteractionTime = action.payload;
        },
        setDeleteConfirmationKey: (state, action) => {
            state.deleteConfirmationKey = action.payload;
        },
        resetAlarmDetailsHeaderColor: (state, action: PayloadAction<any>) => {
            state.headerColor = action.payload;
        },
        setDeviceSideBar: (state, action) => {
            state.deviceSideBar = action.payload;
        },
        setSnoozeDuration: (state, action) => {
            state.snoozeDuration = action.payload;
        },
        setMobileResetPermissions: (state) => {
            if (state.mobilePermissions.length && state.globalPermissions.length) {
                state.mobilePermissions = state.globalPermissions;
                state.globalPermissions = [];
                return;
            }
        },

        setMobilePermissions: (state, action) => {
            if (state.mobilePermissions.length && !state.globalPermissions.length) {
                state.globalPermissions = state.mobilePermissions;
                return;
            }
            const permissions: any = action.payload;
            state.mobilePermissions = permissions;
        },
        setGoogleMapLoaded: (state, action) => {
            state.googleMapLoaded = action.payload;
        },
        setInternalPageHeader: (state: RootState, action: PayloadAction<any>) => {
            state['internalPageHeader'][action.payload.key] = action.payload.value;
        },
        setTimelineStatusColor: (state: RootState, action: PayloadAction<any>) => {
            state.timelineStatusColor = action.payload;
        },
        setMqttConnectionStatus: (state: RootState, action: PayloadAction<any>) => {
            state['mqttConnectionStatus'] = action.payload;
        },
        setNeedsUpdate: (state, action: PayloadAction<any>) => {
            state.needsUpdate = action.payload;
        },
    },
});

export const {
    setSelectedNode,
    setMessageContent,
    setIsSuperRealm,
    setIsAdminRealm,
    setSelectedTabAdminPortal,
    setTabKey,
    toggleLoader,
    setMqttCredentials,
    setSelectedRealm,
    setXRealm,
    setPageHeaderData,
    setAppHeaderTitle,
    setAppTitle,
    setIsDirtyForm,
    setConfirmDirtyForm,
    setSelectedNodeLoader,
    setApplicationColor,
    setIsDrawerOpen,
    setDrawerBottomsheetOpen,
    setDrawerSwitchOrg,
    setAppHeaderSubTitle,
    setAuthModal,
    setLogout,
    setSelectedOrgNameServiceAdminPortal,
    setRealmNode,
    setLastInteractionTime,
    setDeleteConfirmationKey,
    resetAlarmDetailsHeaderColor,
    setDeviceSideBar,
    setSnoozeDuration,
    setMobilePermissions,
    setMobileResetPermissions,
    setGoogleMapLoaded,
    setInternalPageHeader,
    setTimelineStatusColor,
    setMqttConnectionStatus,
    setNeedsUpdate,
} = commonSlice.actions;

export default commonSlice;
