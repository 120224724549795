import React from 'react';

import Edit from '@mui/icons-material/Edit';
import CustomIcon from '../../components/CustomIcon';
import { useParams } from 'react-router-dom';

import { ChannelValue, InfoListItem } from '@brightlayer-ui/react-components';
import { Chip, Container, IconButton, List, Paper } from '@mui/material';
import { useGetDevicePropertiesQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import moment from 'moment';
import { UpdateModal } from './UpdateModal';
import { LocationText } from './LocationText';
import { ListSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import { useTypedSelector } from '@fiji/common';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';

export const Properties = (): JSX.Element => {
    const { deviceId } = useParams();
    const updateModalRef = React.useRef<any>(null);
    const { data: properties, isLoading, isFetching } = useGetDevicePropertiesQuery({ deviceId: deviceId });
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canUpdateDevice = hasPermission('edit-devices');

    const getRightComponent = (data: any): JSX.Element => {
        if (Array?.isArray(data?.value)) {
            return data?.value?.map((item: any) => (
                <Chip key={item?.id ?? item?.groupId} label={item?.name ?? item?.groupName} />
            ));
        }
        if (data?.key === 'batteryInstallDate') {
            return <>{moment(data?.value ?? Date.now())?.format('LL')}</>;
        }
        if (data?.key === 'location') {
            return <LocationText data={data} />;
        }
        return (
            <ChannelValue
                sx={{
                    '& h6': { wordBreak: 'break-word' },
                    maxWidth: '650px',
                }}
                value={data?.value?.groupName ?? data?.value ?? '-'}
                units={data?.unit ?? '-'}
            />
        );
    };

    return (
        <>
            <Container maxWidth="lg" className="padding-y-24">
                <Paper>
                    {isLoading || isFetching ? (
                        <ListSkeleton total={10} customSize={25} />
                    ) : (
                        <List disablePadding>
                            {properties?.data?.map((item: any) => (
                                <InfoListItem
                                    sx={item?.key}
                                    key={item?.key}
                                    className="border-bottom-1"
                                    icon={
                                        <CustomIcon
                                            family={item?.icon?.web?.family ?? ''}
                                            iconName={item?.icon?.web?.name ?? ''}
                                        />
                                    }
                                    title={item?.displayName}
                                    rightComponent={
                                        <>
                                            {getRightComponent(item)}
                                            {item?.isEditable && canUpdateDevice && (
                                                <IconButton
                                                    id="edit"
                                                    onClick={(): void =>
                                                        updateModalRef?.current?.handleModalAction(true, item)
                                                    }
                                                    className="padding-12"
                                                >
                                                    <Edit className="color-gray-500 width-18 height-18" />
                                                </IconButton>
                                            )}
                                        </>
                                    }
                                />
                            ))}
                        </List>
                    )}
                </Paper>
            </Container>
            <UpdateModal ref={updateModalRef} />
        </>
    );
};
