import React from 'react';

import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import DeleteForever from '@mui/icons-material/DeleteForever';
import MoveDown from '@mui/icons-material/MoveDown';
import Tag from '@mui/icons-material/Tag';
import { CustomMenu, CustomTransComponent } from 'components';
import { DeviceConfiguration } from '@brightlayer-ui/icons-mui';

export const DevicesUpdateMenu = ({
    data,
    deleteClick,
    tagClick,
    editClick,
    moveClick,
}: {
    data: any;
    deleteClick: any;
    tagClick: any;
    editClick: any;
    moveClick: any;
}): JSX.Element => {
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);

    const canDeleteDevice = hasPermission('delete-devices');
    const canUpdateDevice = hasPermission('edit-devices');
    const manageTags = hasPermission('manage-device-tags');
    const getActionItems = (): any => {
        const menuGroups = [];
        if (canUpdateDevice) {
            if (manageTags) {
                menuGroups.push({
                    title: <CustomTransComponent translationKey={'DEVICES:OPTIONS.ADD_EDIT_TAGS'} />,
                    icon: <Tag fontSize="small" />,
                    onClick: (): void => tagClick(data),
                });
            }
            menuGroups.push(
                {
                    title: <CustomTransComponent translationKey={'DEVICES:OPTIONS.DEVICE_CONFIGURATION'} />,
                    icon: <DeviceConfiguration fontSize="small" />,
                    onClick: (): void => editClick(data?.id),
                },
                {
                    title: <CustomTransComponent translationKey={'DEVICES:OPTIONS.MOVE_DEVICE'} />,
                    icon: <MoveDown fontSize="small" />,
                    onClick: moveClick,
                }
            );
        }
        if (canDeleteDevice) {
            menuGroups.push({
                title: <CustomTransComponent translationKey={'DEVICE_MANAGEMENT:DELETE_DEVICE_BTN'} />,
                icon: <DeleteForever fontSize="small" />,
                onClick: (): void => deleteClick(data.id),
            });
        }
        return {
            id: 'profile-menu',
            menuGroups,
            menuTitle: '',
            menuSubtitle: '',
        };
    };
    return (canUpdateDevice || canDeleteDevice) && <CustomMenu menuList={getActionItems()} />;
};
