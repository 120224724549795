import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { setSchedulesForComponent } from '@fiji/common/src/features/component/schedule/scheduleSlice';

type ReturnProps = {
    viewMode: string;
    selectedDate: any;
    groupingEnabled: boolean;
    resetNodes: any;
};

export const useHandleSchedule = (): ReturnProps => {
    const dispatch = useAppDispatch();

    const mode = useTypedSelector((state: any) => state['schedule']['mode']);
    const selectedDate = useTypedSelector((state: any) => state['schedule']['selectedDate']);
    const groupingEnabled = useTypedSelector((state: any) => state['schedule']['isGroupingEnabled']);

    const resetNodes = (): void => {
        dispatch(setSchedulesForComponent([]));
    };

    return {
        viewMode: mode,
        selectedDate,
        resetNodes,
        groupingEnabled: groupingEnabled,
    };
};
