// eslint-disable-next-line @typescript-eslint/naming-convention
import MQTT from 'mqtt';
export let client: MQTT.MqttClient;

/**
 * The `connectMQTT` function establishes a connection to an MQTT broker and handles events such as
 * connection, close, and reconnect.
 * @param [onClientConnect] - The `onClientConnect` parameter is a callback function that will be
 * called when the MQTT client successfully connects to the broker. It is an optional parameter, so you
 * can pass a function or leave it undefined if you don't need to perform any action on client connect.
 * @param {any} [options] - The `options` parameter is an object that contains the configuration
 * options for connecting to the MQTT broker. It can include properties such as `host`, `port`,
 * `clientId`, `username`, `password`, `clean`, `will`, `keepalive`, `reconnectPeriod`,
 * `connectTimeout`,
 */
export const connectMQTT = (onClientConnect?: () => void, options?: any): any => {
    // console.log('options: ', options);
    let count = 0;

    client = MQTT.connect(options);

    /* The code `client?.on('connect', ...)` is setting up an event listener for the 'connect' event of
    the MQTT client. When the client successfully connects to the MQTT broker, the callback function
    provided will be executed. */
    client?.on('connect', () => {
        if (onClientConnect) onClientConnect();
    });

    /* The code `client?.on('close', ...)` sets up an event listener for the 'close' event of the MQTT
    client. When the client connection is closed, the callback function provided will be executed. */
    client?.on('close', () => {
        if (count > 4) {
            client.end();
        }
    });

    /* The code `client?.on('reconnect', () => { count++; });` sets up an event listener for the
    'reconnect' event of the MQTT client. */
    client?.on('reconnect', () => {
        count++;
    });

    client?.on('disconnect', () => {});
};

/**
 * The `subscribeTopic` function subscribes to a MQTT topic and handles incoming messages by parsing
 * them as JSON and passing them to a callback function.
 * @param {string} topic - The topic is a string that represents the MQTT topic to subscribe to. MQTT
 * topics are used to categorize and filter messages in the MQTT protocol.
 * @param mqttMessageHandler - The `mqttMessageHandler` parameter is a callback function that will be
 * called whenever a message is received on the subscribed topic. It takes two parameters:
 */
export const subscribeTopic = (
    topic: string | string[],
    mqttMessageHandler: (message: any, messageTopic: string) => void
): void => {
    client?.subscribe(topic, (err: any) => {
        if (!err) {
            client?.on('message', (messageTopic: string, message: any) => {
                let modifiedMessage = message;
                try {
                    modifiedMessage = JSON.parse(modifiedMessage.toString());
                } catch (e) {
                    modifiedMessage = modifiedMessage.toString() || '[]';
                }
                mqttMessageHandler(modifiedMessage, messageTopic);
            });
        }
    });
};

/**
 * The `publishTopic` function publishes a message to a specified MQTT topic and calls a handler
 * function if the publish is successful.
 * @param {string} topic - The topic is a string that represents the MQTT topic to which the message
 * will be published. MQTT topics are used to categorize and filter messages in the MQTT protocol.
 * @param {any} publishMessage - The `publishMessage` parameter is the message that you want to publish
 * to the MQTT topic. It can be of any type, as specified by the `any` type in the function signature.
 * @param mqttPublishHandler - mqttPublishHandler is a callback function that will be called after the
 * message is successfully published to the MQTT broker.
 */
export const publishTopic = (topic: string, publishMessage: any, mqttPublishHandler: () => void): void => {
    client?.publish(topic, publishMessage, (err: any) => {
        if (!err) {
            mqttPublishHandler();
        }
    });
};

/**
 * The `unsubscribeTopic` function unsubscribes from a specified topic.
 * @param {string} topic - The `topic` parameter is a string that represents the topic from which the
 * client wants to unsubscribe.
 */
export const unsubscribeTopic = (topic: string | string[]): void => {
    if (client) client?.unsubscribe(topic);
};

/**
 * The function `closeMqttConnection` closes an MQTT connection if it exists.
 */
export const closeMqttConnection = (): void => {
    if (client) client?.end();
};
