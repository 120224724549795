import React from 'react';

import { setCurrentTime } from '@fiji/common/src/features/component/schedule/scheduleSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { Box, Stack, Typography } from '@mui/material';

export const WeekViewCurrentTime = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentTime = useTypedSelector((state) => state.schedule.currentTime);
    const currentHour = new Date(currentTime)?.getHours();
    const currentMinutes = new Date(currentTime)?.getMinutes();

    const topPosition = ((currentHour * 60 + currentMinutes) / 1440) * 100;

    React.useEffect(() => {
        const interval = setInterval(() => {
            dispatch(setCurrentTime(new Date()?.toISOString()));
        }, 60000);

        return () => clearInterval(interval);
    }, []);
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            sx={{
                position: 'absolute',
                top: `${topPosition}%`,
                left: '-56px',
                marginTop: '-10px',
                width: `calc(100% + 56px)`,
            }}
        >
            <Typography variant="caption" color="primary" fontSize={'12px'}>
                {`${currentHour}:${currentMinutes}`}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} className="w-100">
                <Box className="height-5 width-5 border-radius-50 bg-black-500" />
                <Box className="bg-black-500 w-100 height-1" sx={{ zIndex: 2 }} />
            </Stack>
        </Stack>
    );
};
