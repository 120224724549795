import { createSlice, current } from '@reduxjs/toolkit';
export const initialCreateScheduleState = {
    deviceTypeId: '',
    deviceIds: [],
    groupIds: [],
    actions: [],
    timezone: null,
    recurrencePattern: {},
    frequency: 'ONCE',
    startDate: Date.now(),
    actionStartTime: new Date().toISOString(),
    excludeHolidays: [],
};
const initialState: any = {
    listing: { showMenu: false, listType: 'calendar' },
    createSchedule: initialCreateScheduleState,
    groupSelected: true,
    selectAction: null,
    expandedOverlappingSchedules: [],
    selectedSchedules: [],
    endTimeVisible: false,
};

const scheduleMobileSlice = createSlice({
    name: 'scheduleMobile',
    initialState,
    reducers: {
        setListingShowMenu: (state, action) => {
            state.listing.showMenu = action.payload;
        },
        setListingType: (state, action) => {
            state.listing.listType = action.payload;
        },
        handleChangeScheduleState: (state, action) => {
            if (action.payload.dataType === 'array') {
                const currentClone = current(state);
                const selectedIndex = currentClone?.[action.payload.key]?.findIndex((item: any) => {
                    if (typeof item === 'object') {
                        return item?.id === action?.payload?.value?.id;
                    }
                    return item === action?.payload?.value;
                });
                if (selectedIndex !== -1) {
                    state?.[action.payload.key]?.splice(selectedIndex, 1);
                } else {
                    state?.[action.payload.key]?.push(action.payload.value);
                }
            } else {
                state[action.payload.key] = action.payload.value;
            }
        },
        handleChangeCreateScheduleState: (state, action) => {
            if (action.payload.dataType === 'array') {
                const currentClone = current(state);
                const selectedIndex = currentClone?.createSchedule?.[action.payload.key]?.findIndex((item: any) => {
                    if (typeof item === 'object') {
                        return item?.id === action?.payload?.value?.id;
                    }
                    return item === action?.payload?.value;
                });
                if (selectedIndex !== -1) {
                    state?.createSchedule?.[action.payload.key]?.splice(selectedIndex, 1);
                } else {
                    state?.createSchedule?.[action.payload.key]?.push(action.payload.value);
                }
            } else {
                state.createSchedule[action.payload.key] = action.payload.value;
            }
        },
        handleAddNewAction: (state, action) => {
            if (action.payload.id && !state.createSchedule.actions.length) {
                state.createSchedule.actions = [action.payload];
            } else if (action.payload.id && state.createSchedule.actions.length) {
                state.createSchedule.actions.push(action.payload);
            }
        },
        handleUpdateAction: (state, action) => {
            state.createSchedule.actions[action.payload.actionIndex] = action.payload.actionPayload;
        },
        handleSetActionValue: (state, action) => {
            state.createSchedule.actions[action.payload.actionIndex][`${action.payload.type}Value`] =
                action.payload.value;
        },
        handleRemoveActionState: (state, action) => {
            state.createSchedule.actions.splice(action.payload.actionIndex, 1);
        },
        handleChangeRecurrencePattern: (state, action) => {
            state.createSchedule.recurrencePattern[action.payload.key] = action.payload.value;
        },
    },
});

export const {
    setListingShowMenu,
    setListingType,
    handleChangeCreateScheduleState,
    handleAddNewAction,
    handleRemoveActionState,
    handleUpdateAction,
    handleSetActionValue,
    handleChangeScheduleState,
    handleChangeRecurrencePattern,
}: any = scheduleMobileSlice.actions;

export default scheduleMobileSlice;
