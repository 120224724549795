import React from 'react';

import { List, Stack, ListItem, Typography, ListItemIcon, ListItemText, ListItemButton, Tooltip } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import CustomIcon from '../../../components/CustomIcon';
import { TypesSkeleton } from './Skeletons';
import { useHandleAddWidgets } from 'pages/DashboardAndWidgets/AddWidget/hooks';
import { useGetAllWidgetTypesQuery } from '@fiji/common/src/features/widgetManagement/widgetApi';
import { addWidgetModalData, setPayload } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { CustomTransComponent } from 'components';

export const WidgetTypesGrid = (): JSX.Element => {
    const theme: any = useTheme();
    const dispatch = useAppDispatch();

    const modalControls = useTypedSelector(addWidgetModalData);

    const { handleIsSelected } = useHandleAddWidgets();

    const {
        currentData: widgetTypes,
        isLoading: widgetTypesLoading,
        isFetching: widgetTypesFetching,
        isSuccess,
        isError,
    }: any = useGetAllWidgetTypesQuery(
        {},
        {
            skip: !modalControls?.isOpen,
        }
    );

    return (
        <Stack py={3} px={2} className="bg-content-wrapper h-100">
            <Typography color="primary" fontSize={'16px'} variant="subtitle1" fontWeight={'600'}>
                <CustomTransComponent translationKey={'WIDGETS:ADD_WIDGET_MODAL.FILTER_TYPE'} />
            </Typography>
            <List disablePadding className="margin-top-24 height-450 bg-white overflow-y-scroll">
                {(widgetTypesLoading || widgetTypesFetching) && !(isSuccess || isError) && (
                    <TypesSkeleton items={9} circularDimension={30} />
                )}
                {Boolean(widgetTypes?.data?.records?.length) &&
                    !(widgetTypesLoading || widgetTypesFetching) &&
                    widgetTypes?.data?.records?.map((item: any) => (
                        <Tooltip key={item.id} title={item?.description ?? ''}>
                            <ListItem
                                className="border-bottom-1"
                                sx={{
                                    '&:hover': {
                                        backgroundColor: theme?.palette?.primary?.[50],
                                    },
                                    backgroundColor: handleIsSelected('widgetType', item)
                                        ? theme?.palette?.primary?.[50]
                                        : '',
                                    color: handleIsSelected('widgetType', item) ? theme?.palette?.primary?.main : '',
                                }}
                                onClick={(): void => {
                                    dispatch(setPayload({ key: 'widgetType', value: item }));
                                }}
                            >
                                <ListItemButton className="list-item-button" role={undefined} dense>
                                    <ListItemIcon>
                                        <CustomIcon family={item?.icon?.web?.family} iconName={item?.icon?.web?.name} />
                                    </ListItemIcon>
                                    <ListItemText primary={item?.label} />
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    ))}
            </List>
        </Stack>
    );
};

export default WidgetTypesGrid;
