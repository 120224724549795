import React from 'react';
import Close from '@mui/icons-material/Close';
import FilterListOff from '@mui/icons-material/FilterListOff';
import Search from '@mui/icons-material/Search';
import {
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { toFirstLetterUpperCase } from 'utils/helpers';
import { useParams } from 'react-router-dom';
import { CustomTransComponent, Hierarchy, renderGroupHierarchyIcons } from 'components';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';
import { GroupHierarchySkeleton } from 'pages/GroupHierarchy/GroupHierarchySkeleton';
import { usePayloadActions } from '@fiji/common/src/hooks/usePayloadActions';
import { useTheme } from '@mui/material/styles';

const ApplyFilterBox = ({ payload, setIsFilterApplied, customDate, setPayload, reportFilter }: any): JSX.Element => {
    const theme: any = useTheme();
    const [cleanPayload] = usePayloadActions();

    const submitHandler = (): void => {
        setIsFilterApplied(true);
        const documentPayload = {
            filters: {
                ...reportFilter.filters,
                from: new Date(customDate?.[0])?.getTime() === 0 ? null : new Date(customDate?.[0])?.getTime(),
                to: new Date(customDate?.[1])?.getTime() === 0 ? null : new Date(customDate?.[1])?.getTime(),
            },
        };

        // if (payload?.filters?.groupsAndDevices) {
        //     filteredNodeIds = removeUnwantedIds(
        //         groupsHierarchy?.data,
        //         payload?.filters?.groupsAndDevices?.groups || [],
        //         payload?.filters?.groupsAndDevices?.devices || []
        //     );
        // }
        if (reportFilter?.filters?.groupsAndDevices?.groupIds?.length) {
            documentPayload.filters['groupIds'] = reportFilter?.filters?.groupsAndDevices.groupIds ?? [];
        }
        if (reportFilter?.filters?.groupsAndDevices?.deviceIds?.length) {
            documentPayload.filters['deviceIds'] = reportFilter?.filters?.groupsAndDevices.deviceIds ?? [];
        }

        delete documentPayload?.filters?.['date'];
        delete documentPayload?.filters?.['groupsAndDevices'];

        const cleanedPayload = cleanPayload(documentPayload?.filters);
        setPayload({ ...payload, filters: { ...cleanedPayload } });
    };
    return (
        <Box sx={{ padding: '16px' }}>
            <Button
                id="apply-filter"
                variant="contained"
                sx={{
                    width: '100%',
                    backgroundColor: theme?.palette?.primary?.main,
                    '&:hover': {
                        backgroundColor: theme?.palette?.primary?.main,
                    },
                }}
                onClick={submitHandler}
            >
                <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.APPLY_FILTERS'} />
            </Button>
        </Box>
    );
};

const getTransformPayloadData = (payload: any, id: string, data?: any, checked?: boolean): any => {
    const payloadClone = JSON.parse(JSON.stringify(payload?.filters ?? {}));
    if (id !== 'documentTypes') {
        payloadClone[id] = data;
    } else {
        if (!payloadClone?.documentTypes) {
            payloadClone['documentTypes'] = [];
        }
        if (checked) {
            payloadClone?.documentTypes?.push(data);
        } else {
            const deleteIndex = payloadClone?.documentTypes?.indexOf(data);
            payloadClone?.documentTypes?.splice(deleteIndex, 1);
        }
    }
    return payloadClone;
};

export const ReportsFilter = ({
    handleOpenFilter,
    payload,
    setPayload,
    customDate,
    setCustomDate,
}: any): JSX.Element => {
    const [reportFilters, setReportsFilters] = React.useState<any>({});
    const [cleanPayload] = usePayloadActions();
    const { deviceId } = useParams();
    const currentOrg = useTypedSelector(selectedOrg);
    const [isFilterApplied, setIsFilterApplied] = React.useState<boolean>(false);
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [groupsData, setGroupsData] = React.useState();
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState<{ [key: string]: any }>({});
    const [openSelectBox, setOpenSelectBox] = React.useState(false);
    const closeHandler = (): void => {
        if (!isFilterApplied) {
            setPayload({});
        }
        handleOpenFilter(false);
    };
    const resetFilters = (key: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(payload?.filters));
        delete payloadClone[key];
        if (key === 'date') {
            setCustomDate([null, null]);
        }
        setPayload({ filters: payloadClone });
    };

    const [getHeirarchyData, { data: groupHierarchy }] = useGetAllGroupsMutation({
        fixedCacheKey: 'cachedAllGroupData',
    });
    React.useEffect(() => {
        if (!currentOrg?.defaultGroupAccess && currentOrg?.id) {
            void getHeirarchyData(currentOrg?.id);
        }
    }, [currentOrg]);
    React.useEffect(() => {
        if (!groupsData) {
            setGroupsData(groupHierarchy?.data?.records);
        }
    }, [groupHierarchy]);

    const handleTreeSelection = (parentNode: any): void => {
        if (parentNode?.type === 'DEVICE') {
            const deviceClone = JSON.parse(JSON.stringify(reportFilters?.filters?.groupsAndDevices?.deviceIds ?? []));
            const existingId = deviceClone?.findIndex((id: any) => id === parentNode?.id);
            if (existingId !== -1) {
                deviceClone?.splice(existingId, 1);
            } else {
                deviceClone.push(parentNode?.id);
            }

            setReportsFilters((prev: any) => ({
                ...prev,
                filters: {
                    ...prev.filters,
                    groupsAndDevices: cleanPayload({
                        ...prev?.filters?.groupsAndDevices,
                        deviceIds: deviceClone,
                    }),
                },
            }));
        } else if (parentNode?.type === 'GROUP' || parentNode?.id === currentOrg?.id) {
            const groupClone = JSON.parse(JSON.stringify(payload?.filters?.groupsAndDevices?.groupIds ?? []));
            const existingId = groupClone?.findIndex((id: any) => id === parentNode?.id);
            if (existingId !== -1) {
                groupClone?.splice(existingId, 1);
            } else {
                groupClone.push(parentNode?.id);
            }
            setReportsFilters((prev: any) => ({
                ...prev,
                filters: {
                    ...prev.filters,
                    groupsAndDevices: cleanPayload({
                        ...prev?.filters?.groupsAndDevices,
                        groupIds: groupClone,
                    }),
                },
            }));
        }
    };

    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => {
            if (!prev === false) {
                setHierarchyMappingData({});
            }
            return !prev;
        });
    };

    const handleCleanHierarchyNode = (parentId: string): void => {
        setHierarchyMappingData((prevState) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };
    const payloadHandler = (id: string, data?: any, checked?: boolean): void => {
        const payloadClone = getTransformPayloadData(payload, id, data, checked);
        setPayload({ filters: payloadClone });
    };
    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);

        const { data: childHierarchyData }: any = await getHeirarchyData({ parent: parentData?.id });

        if (childHierarchyData) {
            setHierarchyMappingData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
        return;
    };
    return (
        <Paper className="documents-filter">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton id="close" onClick={closeHandler}>
                        <Close />
                    </IconButton>
                    <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: '600' }}>
                        Reports Filter
                    </Typography>
                </Box>
                <IconButton>
                    <FilterListOff />
                </IconButton>
            </Box>
            <Divider />
            {reportFilters?.filters && Boolean(Object?.keys(reportFilters?.filters)?.length) && (
                <Stack sx={{ padding: '16px' }} flexDirection={'row'} alignItems={'center'} flexWrap={'wrap'}>
                    {Object?.keys(cleanPayload(reportFilters?.filters))?.map(
                        (key: string) =>
                            key !== 'deviceIds' &&
                            key !== 'groupIds' && (
                                <Chip
                                    key={key}
                                    disabled={Boolean(deviceId && key === 'groupsAndDevices')}
                                    label={toFirstLetterUpperCase(key)}
                                    onDelete={(): void => resetFilters(key)}
                                    variant="outlined"
                                    className="custom-filter-search-btn"
                                />
                            )
                    )}
                </Stack>
            )}
            <Box>
                <Box sx={{ padding: '16px' }}>
                    <Typography variant="h6" color="primary" fontSize={'16px'} fontWeight={'600'} pb={'20px'}>
                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.FILE_NAME'} />
                    </Typography>

                    <Box sx={{ marginBottom: '20px' }}>
                        <FormControl sx={{ width: '100%' }}>
                            <Box sx={{ position: 'relative' }}>
                                <TextField
                                    id="fileName"
                                    value={reportFilters?.filters?.fileName ?? ''}
                                    // onChange={(e: any): void => {
                                    //     setPayload((prev: any) => ({ ...prev, name: e.target.value }));
                                    // }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                        payloadHandler('fileName', e.target.value)
                                    }
                                    variant="outlined"
                                    placeholder={
                                        (
                                            <CustomTransComponent
                                                translationKey={'DOCUMENTS:DOCUMENT_FILTER.FILE_NAME'}
                                            />
                                        ) as any
                                    }
                                    sx={{ width: '100%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <Search
                                                style={{
                                                    color: '#777',
                                                }}
                                                id="search"
                                            />
                                        ),
                                    }}
                                />
                            </Box>
                        </FormControl>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ padding: '16px' }}>
                    <Typography
                        variant="h6"
                        color="primary"
                        sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            paddingBottom: '20px',
                        }}
                    >
                        <CustomTransComponent translationKey={'COMMON:DATE'} />
                    </Typography>
                    <Box
                        sx={{
                            '& .MuiDateRangeCalendar-root': { alignSelf: 'center' },
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StaticDateRangePicker
                                value={customDate ?? [null, null]}
                                onChange={(data: any): void => {
                                    payloadHandler('date', data);
                                    setCustomDate(data);
                                }}
                                slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>

                <Divider />
                <Box sx={{ padding: '16px' }}>
                    <Typography
                        variant="h6"
                        color="primary"
                        sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            paddingBottom: '20px',
                        }}
                    >
                        <CustomTransComponent translationKey={'DOCUMENTS:DOCUMENT_FILTER.GROUPS_DEVICES'} />
                    </Typography>
                    <Box>
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                            <InputLabel id="demo-simple-select-filled-label">
                                <CustomTransComponent translationKey={'GROUP_MANAGEMENT:LABELS.GROUPS'} />
                            </InputLabel>
                            <Select
                                labelId="groupsList-label"
                                disabled={window.location.pathname.includes('details')}
                                id="groupsList"
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                }}
                                open={openSelectBox}
                                onClose={(): void => {
                                    handleToggleSelectBox();
                                }}
                                onOpen={(): void => {
                                    handleToggleSelectBox();
                                }}
                            >
                                <Hierarchy
                                    data={
                                        currentOrg?.defaultGroupAccess
                                            ? [
                                                  {
                                                      id: currentOrg?.id,
                                                      name: `${currentOrg?.name} (Organization Root)`,
                                                      icon: { web: { family: 'material-ui', name: 'CorporateFare' } },
                                                      groupCount: currentOrg?.groupCount,
                                                      deviceCount: currentOrg?.deviceCount,
                                                  },
                                              ]
                                            : groupsData
                                    }
                                    loadMore={handleFetchHierarchy}
                                    cleanHiearchyNode={handleCleanHierarchyNode}
                                    hierarchyMappingData={hierarchyMappingData}
                                    loadingNode={loadingHierarchyNode}
                                    handleTreeNodeSelection={(parentNode): void => {
                                        handleTreeSelection(parentNode);
                                    }}
                                    selectionType={'checkbox'} // radio, checkbox, node
                                    selectedNodes={
                                        [
                                            ...(reportFilters?.filters?.groupsAndDevices?.groupIds ?? []),
                                            ...(reportFilters?.filters?.groupsAndDevices?.deviceIds ?? []),
                                        ] ?? []
                                    }
                                    hasChild={(treeItem): boolean => treeItem?.groupCount || treeItem?.deviceCount}
                                    getAvatarChild={renderGroupHierarchyIcons}
                                    labelKey="name"
                                    autoChildSelection
                                    loadingSkeleton={<GroupHierarchySkeleton row={1} />}
                                />
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <Divider />
            <ApplyFilterBox
                reportFilters={reportFilters}
                setReportsFilters={setReportsFilters}
                payload={payload}
                customDate={customDate}
                // groupsHierarchy={groupsHierarchy}
                setIsFilterApplied={setIsFilterApplied}
            />
        </Paper>
    );
};
