import { Skeleton } from '@mui/material';
import React from 'react';

const tabsStyle = { display: 'flex', flexDirection: 'row', marginBottom: '0' };
export const CustomTabsSkeleton = (): any => [
    {
        id: 'summary',
        sx: tabsStyle,
        renderTitle: (
            <Skeleton
                animation="wave"
                variant="rounded"
                width={150}
                height={15}
                sx={{ marginBottom: '10px', marginTop: '10px' }}
            />
        ),
        renderContent: <></>,
    },
    {
        id: 'devices',
        sx: tabsStyle,
        renderContent: <></>,
        renderTitle: (
            <Skeleton
                animation="wave"
                variant="rounded"
                width={150}
                height={15}
                sx={{ marginBottom: '10px', marginTop: '10px' }}
            />
        ),
    },
    {
        id: 'timeline',
        sx: tabsStyle,
        renderTitle: (
            <Skeleton
                animation="wave"
                variant="rounded"
                width={150}
                height={15}
                sx={{ marginBottom: '10px', marginTop: '10px' }}
            />
        ),
        renderContent: <></>,
    },

    {
        id: 'new',
        sx: tabsStyle,
        renderTitle: (
            <Skeleton
                animation="wave"
                variant="rounded"
                width={150}
                height={15}
                sx={{ marginBottom: '10px', marginTop: '10px' }}
            />
        ),
        renderContent: <></>,
    },
];
