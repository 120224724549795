import React, { ReactNode } from 'react';
import { CustomModal, ModalActions } from './CustomModal';
import { DialogContentText, FormHelperText, TextField, Typography } from '@mui/material';
import * as Colors from '@brightlayer-ui/colors';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { setDeleteConfirmationKey } from '@fiji/common/src/features/common/commonSlice';
import { useDispatch } from 'react-redux';
import { CustomTransComponent } from './CustomTransComponent';

/**
 * The above type represents the props for a delete modal component in a TypeScript React application.
 * @property {string} deleteButtonText - The `deleteButtonText` property is an optional string that
 * specifies the text to be displayed on the delete button in the delete modal. If this property is not
 * provided, a default text can be used.
 * @property confirmClick - A function that will be called when the user confirms the deletion action.
 * @property {boolean} isVisible - The `isVisible` property is a boolean that determines whether the
 * delete modal is visible or not. If `isVisible` is `true`, the delete modal is shown. If `isVisible`
 * is `false`, the delete modal is hidden.
 * @property {boolean} isLoading - isLoading is a boolean value that indicates whether the delete
 * operation is currently in progress or not.
 * @property {React.ReactNode | string} description - The `description` property is used to provide a
 * description for the delete modal. It can be either a React node or a string.
 * @property onCancel - The `onCancel` property is a function that will be called when the user cancels
 * or closes the delete modal.
 * @property {string} header - The `header` property is a string that represents the title or heading
 * of the delete modal.
 */
type DeleteModalProps = {
    deleteButtonText?: string;
    noDividers?: boolean;
    actionsDivider?: boolean;
    confirmClick: () => void;
    isVisible: boolean;
    isLoading?: boolean;
    description: React.ReactNode | string;
    onCancel: () => void;
    header: ReactNode;
    confirmDescription?: React.ReactNode | string;
};

/**
 * The `DeleteModal` component is a TypeScript React component that renders a modal dialog for
 * confirming a deletion action, including a description, a delete key input field, and cancel and
 * confirm buttons.
 * @param {DeleteModalProps} props - - `isVisible` (boolean): Determines whether the delete modal is
 * visible or not.
 * @returns The DeleteModal component is being returned.
 */
export const DeleteModal = (props: DeleteModalProps): JSX.Element => {
    const modalRef = React.useRef<any>(null);
    const dispatch = useDispatch();
    const [deleteKey, setDeleteKey] = React.useState<string>('');
    const deleteConfirmationKey = useTypedSelector((state) => state.common?.deleteConfirmationKey);
    const actions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                props.onCancel();
                setDeleteKey('');
            },
        },
        {
            testId: 'delete',
            key: 'delete',
            label: props?.deleteButtonText ? (
                <CustomTransComponent translationKey={props?.deleteButtonText} />
            ) : (
                <CustomTransComponent translationKey={'COMMON:DELETE'} />
            ),
            variant: 'contained',
            className:
                deleteKey.toLocaleLowerCase() !== (deleteConfirmationKey || 'delete')
                    ? 'error-btn-disabled'
                    : 'error-btn',
            disabled: deleteKey.toLocaleLowerCase() !== (deleteConfirmationKey || 'delete'),
            color: 'error',
            handleClick: (): void => {
                setDeleteKey('');
                props.confirmClick();
            },
        },
    ];

    React.useEffect(() => {
        if (!props?.isVisible) dispatch(setDeleteConfirmationKey(''));
    }, [props?.isVisible]);

    return (
        <CustomModal
            noDividers
            actionsDivider
            isOpen={props.isVisible}
            type="primary"
            isLoading={props.isLoading ?? false}
            ref={modalRef}
            key="deleteModal@13121"
            actions={actions}
            header={props?.header}
        >
            <DialogContentText id="alert-dialog-description" fontSize={'16px'} sx={{ color: Colors.black[500] }}>
                {props.description}
            </DialogContentText>
            <Typography py={2} fontSize={'16px'} sx={{ color: Colors.black[500] }}>
                <CustomTransComponent
                    translationKey={
                        deleteConfirmationKey
                            ? 'COMPONENTS:REMOVE_MODAL_DESCRIPTION'
                            : 'COMPONENTS:DELETE_MODAL_DESCRIPTION'
                    }
                    replace={{
                        text: CustomTransComponent({
                            translationKey: props.deleteButtonText ?? 'COMMON:DELETE',
                            customT: true,
                        }),
                    }}
                    components={{ b: <b /> }}
                />
            </Typography>

            <TextField
                id="deleteKey"
                value={deleteKey}
                inputProps={{ maxLength: 6 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setDeleteKey(e.target.value)}
                label={
                    deleteConfirmationKey ? (
                        <CustomTransComponent translationKey={'COMMON:REMOVE_TEXT_FIELD_LABEL'} />
                    ) : (
                        <CustomTransComponent translationKey={'COMMON:DELETE_TEXT_FIELD_LABEL'} />
                    )
                }
                variant="filled"
                fullWidth
                className="margin-top-8"
            />
            <FormHelperText className="text-align-end padding-x-16">{deleteKey.length}/6</FormHelperText>
        </CustomModal>
    );
};
