import { Dayjs } from 'dayjs';

const getRequiredLabel = (index: any): string => {
    switch (index) {
        case 15:
            return '3';
        case 18:
            return '6';
        case 21:
            return '9';
        case 24:
            return '0';

        default:
            return String(index);
    }
};

const isPresentInSameWeek = (dayA: Dayjs, dayB: Dayjs | null | undefined): any => {
    if (dayB === null) {
        return false;
    }

    return dayA.isSame(dayB, 'week');
};

export { getRequiredLabel, isPresentInSameWeek };
