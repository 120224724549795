import React from 'react';

import { Paper, Grid } from '@mui/material';
import { ChannelList, RearrangeChannels } from './';
import { useGetChannelValues, useWidgetConfigHandlers } from '../hooks';
import { CommonConfigurationHeader } from 'pages/WidgetManagement';
import { setDisplayPreferences } from '@fiji/common/src/features/widgetManagement/detailsWidgetSlice';
import { useAppDispatch } from '@fiji/common';

export const Configuration = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const {
        details: { widgetDetails },
    } = useWidgetConfigHandlers();

    useGetChannelValues(
        widgetDetails?.widgetType?.id === 'settings' || widgetDetails?.widgetType?.id === 'properties'
            ? 'details'
            : widgetDetails?.widgetType?.id
    );

    React.useEffect(() => {
        if (widgetDetails?.widgetType?.id !== 'details') {
            dispatch(setDisplayPreferences({ key: 'countMode', value: 'custom' }));
        }
    }, [widgetDetails?.widgetType?.id]);

    return (
        <Paper>
            <CommonConfigurationHeader type="source" />
            <Grid container p={2} justifyContent={'space-between'}>
                <ChannelList details={widgetDetails} />
                <RearrangeChannels details={widgetDetails} />
            </Grid>
        </Paper>
    );
};
