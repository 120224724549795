import React from 'react';

import dayjs from 'dayjs';
import { useTypedSelector } from '@fiji/common';
import { Box, Grid, Stack, Typography } from '@mui/material';

export const WeekViewHeader = (): JSX.Element => {
    const currentTime = useTypedSelector((state: any) => state['schedule']['currentTime']);
    const selectedWeekDays = useTypedSelector((state) => state['schedule']['selectedWeekDays']);

    return (
        <Grid container>
            {selectedWeekDays?.map((day: any, index: number) => (
                <Grid item xs={12 / 7} key={index}>
                    <Stack
                        style={{
                            height: 40,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: 0,
                            ...(day?.dayOfMonth === parseInt(dayjs(currentTime)?.format('D')) && {
                                color: '#007BC1',
                                fontWeight: 'bold',
                            }),
                        }}
                        className="border-right-1 border-bottom-1"
                    >
                        <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{ mt: '-40px' }}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Typography variant="body2">{day?.dayName}</Typography>
                                <Box className="width-4 height-4 border-radius-50 bg-content" />
                            </Stack>
                            <Typography variant="body2" fontStyle={'14px'}>
                                {day?.dayOfMonth}
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
};
