import dayjs from 'dayjs';

function setTotalDaysOfWeek(selectedDate: any): any {
    const days: any = [];
    for (let i = 0; i < 7; i++) {
        const day = dayjs(selectedDate)?.add(i, 'day');
        days?.push({ dayName: day.format('dddd'), dayOfMonth: day.date() });
    }
    return days;
}

export { setTotalDaysOfWeek };
