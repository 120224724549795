import React from 'react';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { RootState, useTypedSelector } from '@fiji/common/src/app/store';

/* The code defines a React functional component called `BackdropLoader`. It takes an optional prop
`isOpen` of type boolean. */
export const BackdropLoader = ({
    isOpen,
    showDefault = true,
}: {
    isOpen?: boolean;
    showDefault?: boolean;
}): JSX.Element => {
    const action = useTypedSelector((state: RootState) => state.common);
    const [isLoading, setIsLoading] = React.useState<boolean>(action?.isLoader);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, it is used to update the `isLoading` state variable based on the values of
    `action?.isLoader` and `isOpen`. */
    React.useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            return;
        }
        setIsLoading(action?.isLoader);
    }, [action?.isLoader, isOpen]);

    if (showDefault === false) {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                }}
            >
                <CircularProgress thickness={5} size={50} color="inherit" />
            </Box>
        );
    }
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme): number => theme.zIndex.drawer + 99999 }} open={isLoading}>
            <CircularProgress thickness={5} size={50} color="inherit" />
        </Backdrop>
    );
};
