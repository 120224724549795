import dayjs from 'dayjs';

import { RootState } from '../../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setTotalDaysOfWeek } from './helpers';

const initialState = {
    mode: 'day',
    schedules: [],
    isLoading: false,
    hoveredDay: null,
    currentTime: new Date()?.toISOString(),
    selectedDate: dayjs().startOf('date').toISOString(),
    selectedWeekDays: [],
    isGroupingEnabled: false,
    selectedDeviceType: null,
};

const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: initialState,
    reducers: {
        resetSchedule: () => {
            return initialState;
        },
        setPredefinedScheduleConfiguration: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'filters') {
                    state[key] = action.payload[key];
                }
            });
        },
        toggleScheduleViewMode: (state: RootState, action: PayloadAction<any>) => {
            if (action.payload === 'week') {
                state.selectedDate = dayjs().startOf('week').toISOString() as any;
                state['selectedWeekDays'] = setTotalDaysOfWeek(dayjs().startOf('week').toISOString());
            } else {
                state.selectedDate = dayjs().startOf('date').toISOString() as any;
            }
            state.mode = action.payload;
        },
        setSelectedScheduleFilterDate: (state: RootState, action: PayloadAction<any>) => {
            state['selectedDate'] = action.payload;
            let days: any = [];
            if (state.mode === 'week') {
                days = setTotalDaysOfWeek(action.payload);
            }
            state['selectedWeekDays'] = days;
        },
        setHoveredScheduleFilterDate: (state: RootState, action: PayloadAction<any>) => {
            state.hoveredDay = action.payload;
        },
        toggleScheduleGrouping: (state: RootState) => {
            state.isGroupingEnabled = !state.isGroupingEnabled;
        },
        toggleScheduleComponentLoader: (state: RootState, action: PayloadAction<any>) => {
            state.isLoading = action.payload;
        },
        setSchedulesForComponent: (state: RootState, action: PayloadAction<any>) => {
            state['schedules'] = action.payload;
        },
        setCurrentTime: (state: RootState, action: PayloadAction<string>) => {
            state['currentTime'] = action.payload;
        },
        setComponentSelectedDeviceType: (state: RootState, action: PayloadAction<any>) => {
            state['selectedDeviceType'] = action.payload;
        },
    },
});

export const {
    resetSchedule,
    setCurrentTime,
    toggleScheduleViewMode,
    setPredefinedScheduleConfiguration,
    toggleScheduleGrouping,
    setSchedulesForComponent,
    setHoveredScheduleFilterDate,
    setSelectedScheduleFilterDate,
    toggleScheduleComponentLoader,
    setComponentSelectedDeviceType,
} = scheduleSlice.actions;

export default scheduleSlice;
