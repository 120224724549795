import React from 'react';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTypedSelector } from '@fiji/common';
import { Box, Grid, Stack, Typography } from '@mui/material';

export const WeekEventGrid = ({ event, gridWidth }: any): JSX.Element => {
    const selectedWeekDays = useTypedSelector((state) => state['schedule']['selectedWeekDays']);
    const startDateTime = new Date(event?.actionStartTime);
    const startHour = startDateTime.getHours();
    const startMinutes = startDateTime.getMinutes();
    const currentTime = useTypedSelector((state) => state.schedule.currentTime);
    const topPosition = ((startHour * 60 + startMinutes) / 1440) * 100;
    return event?.schedules?.map((schedule: any) => {
        const eventDayIndex = selectedWeekDays.findIndex((day: any) => day?.dayName === dayjs(schedule).format('dddd'));

        const leftPosition = (eventDayIndex * gridWidth) / 7;

        return event?.actionEndTime ? (
            <Grid
                item
                xs={12 / 7}
                style={{
                    position: 'absolute',
                    left: `${leftPosition}px`,
                    top: `${topPosition}%`,
                    height: `${
                        (((event?.actionEndTime / 1000 - event?.actionStartTime / 1000) / 60 / 60) * 100) / 24
                    }%`,

                    zIndex: 1,
                }}
                key={schedule}
                className={clsx({
                    'ongoing-event':
                        schedule <= new Date(currentTime)?.getTime() &&
                        new Date(event?.actionEndTime).getHours() >= new Date(currentTime)?.getHours() &&
                        dayjs(schedule)?.format('dd') === dayjs(currentTime)?.format('dd'),
                    'future-event': new Date(currentTime)?.getTime() < schedule,
                    'past-event':
                        (new Date(currentTime)?.getHours() > new Date(event?.actionEndTime)?.getHours() &&
                            new Date(currentTime)?.getTime() > schedule) ||
                        parseInt(dayjs(schedule)?.format('D') ?? 0) < parseInt(dayjs(currentTime)?.format('D') ?? 0),
                })}
            >
                <Typography variant="body2" className="text-truncate">
                    {event?.name}
                </Typography>
            </Grid>
        ) : (
            <Stack
                sx={{
                    position: 'absolute',
                    left: `${leftPosition}px`,
                    top: `${topPosition}%`,
                    zIndex: 1,
                }}
                key={schedule}
                direction={'row'}
                spacing={1}
                alignItems={'center'}
                className={clsx({
                    'past-text-event': new Date(currentTime)?.getTime() > event?.actionStartTime,
                    'future-text-event': new Date(currentTime)?.getTime() < event?.actionStartTime,
                    'ongoing-text-event': new Date(currentTime)?.getTime() === event?.actionStartTime,
                })}
            >
                <Box className="dot" />
                <Typography>{event?.name}</Typography>
            </Stack>
        );
    });
};
