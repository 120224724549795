import { api } from '../../app/api/baseApi';
export const mqttApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMQTTCredentials: builder.query<void, void>({
            query: () => ({
                url: `/iam/v1/users/me/mqtt/credentials`,
            }),
        }),
    }),
});

export const { useGetMQTTCredentialsQuery } = mqttApi;
