import React from 'react';

import { Grid } from '@mui/material';
import { ListSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import { useTypedSelector } from '@fiji/common';
import { useHandleSchedule } from 'components/Schedule';
import { DevicesSubGrid } from './DevicesSubGrid';

export const DevicesGrid = React.memo(({ deviceTypeId }: { deviceTypeId?: any }): JSX.Element => {
    const { groupingEnabled } = useHandleSchedule();

    const isLoading = useTypedSelector((state) => state['scheduler']['isLoading']);
    const dailySchedules = useTypedSelector((state) => state['scheduler']['dailyScheduledEvents']);

    const getDevices = (): any => {
        if (deviceTypeId) {
            return dailySchedules
                ?.find((schedule: any) => schedule?.deviceType?.id === deviceTypeId)
                ?.events?.map((event: any) => ({ id: event?.device?.id, name: event?.device?.name }));
        }
        return dailySchedules?.map((device: any) => ({
            id: device?.device?.id,
            name: device?.device?.name,
        }));
    };

    return (
        <Grid container direction="column" className={groupingEnabled ? 'bg-light-blue' : ''}>
            {isLoading ? (
                <ListSkeleton total={5} customSize={20} />
            ) : (
                <DevicesSubGrid devices={getDevices()} isGrouped={groupingEnabled} />
            )}
        </Grid>
    );
});
