import React from 'react';

import { ScheduleCalendar } from './ScheduleCalendar';
// import { Schedule } from 'components';

export const SchedulerLayout = ({ searchKey }: any): JSX.Element => (
    <ScheduleCalendar searchKey={searchKey} />

    // <>
    //     <Paper className="padding-16 margin-top-24">
    //         <Stack direction={'row'} justifyContent={'space-between'}>
    //             <Stack direction={'row'} spacing={3} alignItems={'center'}>
    //                 <ToggleButtonGroup
    //                     color="primary"
    //                     value={props?.durationView}
    //                     exclusive
    //                     onChange={handleView}
    //                     aria-label="text alignment"
    //                 >
    //                     <ToggleButton color="primary" value="day" aria-label="centered">
    //                         Day
    //                     </ToggleButton>
    //                     <ToggleButton color="primary" value="week" aria-label="centered">
    //                         Week
    //                     </ToggleButton>
    //                 </ToggleButtonGroup>
    //                 <LocalizationProvider dateAdapter={AdapterDayjs}>
    //                     <DatePicker
    //                         value={props?.selectedDate}
    //                         format="LL"
    //                         onChange={(newValue: any) =>
    //                             props?.durationView === 'week'
    //                                 ? props?.setSelectedDate(dayjs(newValue).startOf('week'))
    //                                 : props?.setSelectedDate(dayjs(newValue))
    //                         }
    //                         showDaysOutsideCurrentMonth
    //                         {...(props?.durationView === 'week' && { slots: { day: customDay } })}
    //                         {...(props?.durationView === 'week' && {
    //                             slotProps: {
    //                                 day: (ownerState: any) =>
    //                                     ({
    //                                         selectedDay: props?.selectedDate,
    //                                         hoveredDay,
    //                                         onPointerEnter: () => setHoveredDay(ownerState?.day),
    //                                         onPointerLeave: () => setHoveredDay(null),
    //                                     } as any),
    //                             },
    //                         })}
    //                     ></DatePicker>
    //                     {/* )} */}
    //                 </LocalizationProvider>
    //             </Stack>
    //             {props?.durationView === 'day' ? (
    //                 <Stack alignItems={'center'} direction={'row'} spacing={1}>
    //                     <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'}>
    //                         Group by Asset Type
    //                     </Typography>
    //                     <Switch checked={props?.enableGroupByAssetType} onChange={() => handleGroupToggle()} />
    //                 </Stack>
    //             ) : (
    //                 <Stack direction={'row'} spacing={2}>
    //                     <FormControl variant="filled" sx={{ minWidth: 160 }}>
    //                         <InputLabel id="demo-simple-select-filled-label">All Asset Types</InputLabel>

    //                         <Select
    //                             labelId="demo-simple-select-filled-label"
    //                             id="demo-simple-select-filled"
    //                             value={props?.selectedAssetType}
    //                             onChange={handleAssetTypeChange}
    //                         >
    //                             <MenuItem key={'all'} value={'all'}>
    //                                 All Asset Types
    //                             </MenuItem>
    //                             {props?.assetTypesDropdown?.map((assetType: any) => (
    //                                 <MenuItem key={assetType?.assetTypeId} value={assetType?.assetTypeId}>
    //                                     {assetType?.assetTypeName}
    //                                 </MenuItem>
    //                             ))}
    //                         </Select>
    //                     </FormControl>
    //                     <FormControl variant="filled" sx={{ minWidth: 160 }}>
    //                         <InputLabel id="demo-simple-select-filled-label">Action Type</InputLabel>
    //                         <Select
    //                             labelId="demo-simple-select-filled-label"
    //                             id="demo-simple-select-filled"
    //                             value={props?.actions}
    //                             onChange={handleActionTypeChange}
    //                         >
    //                             <MenuItem key={'all'} value={'all'}>
    //                                 All Action Types
    //                             </MenuItem>
    //                             {props?.actions?.map((action: any) => (
    //                                 <MenuItem key={action?.actionId} value={action?.actionId}>
    //                                     {action?.actionName}
    //                                 </MenuItem>
    //                             ))}
    //                         </Select>
    //                     </FormControl>
    //                 </Stack>
    //             )}
    //         </Stack>
    //         <Stack>
    //             {props?.durationView === 'day' ? (
    //                 <Day
    //                     enableGroupByAssetType={props?.enableGroupByAssetType}
    //                     selectedDate={props?.selectedDate}
    //                     assetTypes={props?.assetTypes}
    //                     assets={props?.assets}
    //                     selectedAssetType={props?.selectedAssetType}
    //                     setSelectedAssetType={props?.setSelectedAssetType}
    //                     isAssetScheduleFetching={props?.isAssetScheduleFetching}
    //                     isAssetTypeScheduleFetching={props?.isAssetTypeScheduleFetching}
    //                     selectedSchedule={props?.selectedSchedule}
    //                     setSelectedSchedule={props?.setSelectedSchedule}
    //                     setOpenScheduleDetails={props?.setOpenScheduleDetails}
    //                 />
    //             ) : (
    //                 <Week
    //                     schedules={props?.schedules}
    //                     selectedDate={props?.selectedDate}
    //                     selectedSchedule={props?.selectedSchedule}
    //                     setSelectedSchedule={props?.setSelectedSchedule}
    //                     setOpenScheduleDetails={props?.setOpenScheduleDetails}
    //                 />
    //             )}
    //         </Stack>
    //     </Paper>
    //     {
    //         <ScheduleDetails
    //             openScheduleDetails={props?.openScheduleDetails}
    //             setSelectedSchedule={props?.setSelectedSchedule}
    //         />
    //     }
    // </>
);
