/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { Stack } from '@mui/material';
import { ChannelValue } from '@brightlayer-ui/react-components';

function customGauge(H: any): void {
    H.seriesType('lineargauge', 'column', null, {
        setVisible: function (): void {
            (H.seriesTypes.column.prototype.setVisible as any).apply(this as any);
            if (this.markLine) {
                (this?.markLine?.[this.visible ? 'show' : 'hide'] as any)();
            }
        },
        drawPoints: function (): void {
            // Draw the Column like always
            (H.seriesTypes.column.prototype.drawPoints as any).apply(this as any);

            // Add a Marker
            const series: any = this,
                chart = this.chart,
                inverted = chart.inverted,
                xAxis = this.xAxis,
                yAxis = this.yAxis,
                point = this.points[0], // we know there is only 1 point
                markLine = this.markLine,
                ani = markLine ? 'animate' : 'attr';

            // Hide column
            point?.graphic?.hide();

            if (!this.markLine) {
                const path: any = inverted
                    ? ['M', 0, 0, 'L', 0, 0, 'L', 0, 0, 'L', 0, 0, 'L', 0, 0 + xAxis.len]
                    : ['M', 0, 0, 'L', 0, 0, 'L', 0, 0, 'L', 0, 0, 'L', xAxis.len, 0];
                this.markLine = chart?.renderer
                    ?.path(path)
                    ?.attr({
                        fill: series.color,
                        stroke: series.color,
                        'stroke-width': 3,
                    })
                    .add();
            }
            this.markLine[ani]({
                translateX: inverted ? xAxis.left + yAxis.translate(point.y) : xAxis.left,
                translateY: inverted ? xAxis.top : yAxis.top + yAxis.len - yAxis.translate(point.y),
            });
        },
    });
}

customGauge(Highcharts);
HighchartsMore(Highcharts);

export const HorizontalGauge = ({
    gaugeWidget,
    pointConversion,
}: {
    gaugeWidget: any;
    pointConversion: any;
}): JSX.Element => {
    const ref: any = useRef(null);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            inverted: true,
            height: 80,
        },
        title: {
            text: '',
        },
        xAxis: {
            lineColor: '#C0C0C0',
            labels: {
                enabled: false,
            },
            tickLength: 0,
        },
        yAxis: {
            min: 0,
            max: 100,
            title: null,
            plotBands: [],
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                type: 'lineargauge',
                data: [40],
                color: '#000000',
            },
        ],
    });

    useEffect(() => {
        if (ref?.current && gaugeWidget?.data) {
            const isThreshold =
                Boolean(Object?.keys(gaugeWidget?.threshold ?? {}).length) &&
                Object?.values(gaugeWidget?.threshold ?? {}).some((item: any) => Boolean(item));
            const cloneOption = JSON.parse(JSON.stringify(chartOptions));
            cloneOption['chart'] = {
                inverted: true,
                height: gaugeWidget?.viewScale ? 80 : 50,
            };
            cloneOption['series'] = [
                {
                    type: 'lineargauge',
                    name: gaugeWidget.data?.displayName,
                    data: [gaugeWidget?.data?.value ?? 0],
                    tooltip: {
                        valueSuffix: gaugeWidget?.data?.unit,
                    },
                },
            ];
            cloneOption['yAxis'] = {
                ...(chartOptions?.yAxis ?? {}),
                labels: {
                    // ...chartOptions?.yAxis?.labels,
                    enabled: gaugeWidget?.viewScale ?? false,
                },
                min: gaugeWidget?.min ?? 0,
                max: gaugeWidget?.max ?? gaugeWidget?.data?.value,
                plotBands: [
                    {
                        from: 0,
                        to: gaugeWidget?.threshold?.lowCritical ?? 0,
                        color: gaugeWidget?.viewThreshold && isThreshold ? '#DF5353' : '#55BF3B',
                        thickness: 70,
                    },
                    {
                        from: gaugeWidget?.threshold?.lowCritical ?? 0,
                        to: gaugeWidget?.threshold?.lowWarning ?? 0,
                        color: gaugeWidget?.viewThreshold && isThreshold ? '#DDDF0D' : '#55BF3B',
                        thickness: 70,
                    },
                    {
                        from: gaugeWidget?.threshold?.lowWarning ?? 0,
                        to: gaugeWidget?.threshold?.highWarning ?? gaugeWidget?.data?.value ?? 0,
                        color: '#55BF3B',
                        thickness: 70,
                    },
                    {
                        from: gaugeWidget?.threshold?.highWarning ?? gaugeWidget?.data?.value ?? 0,
                        to: gaugeWidget?.threshold?.highCritical ?? gaugeWidget?.data?.value ?? 0,
                        color: gaugeWidget?.viewThreshold && isThreshold ? '#DDDF0D' : '#55BF3B',
                        thickness: 70,
                    },
                    {
                        from: gaugeWidget?.threshold?.highCritical ?? gaugeWidget?.data?.value ?? 0,
                        to: (gaugeWidget?.max || gaugeWidget?.data?.value) ?? 0,
                        color: gaugeWidget?.viewThreshold && isThreshold ? '#DF5353' : '#55BF3B',
                        thickness: 70,
                    },
                ],
            };
            setChartOptions(cloneOption);
        }
    }, [gaugeWidget]);

    return (
        <Stack display="flex" flexDirection="row">
            <ChannelValue
                value={pointConversion?.(gaugeWidget?.data?.value) ?? 0}
                units={gaugeWidget?.data?.unit ?? '%'}
                unitSpace="hide"
                sx={{
                    alignSelf: 'center',
                    paddingTop: gaugeWidget?.viewScale ? '8px' : 0,
                    fontSize: '24px',
                    alignItems: 'baseline',
                    '& .BluiChannelValue-units': {
                        fontSize: '18px',
                    },
                }}
            />
            <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={ref} />
        </Stack>
    );
};
