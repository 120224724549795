import { RootState } from '../../app/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { channelsHandler, handleChannelRemove, handleGroupDevicesFilter, handleReallocateChannels } from './helpers';

const timelineInitialState = {
    secondary: [],
    maxCount: 6,
    preferences: [],
    deviceId: undefined,
    filters: {},
    statusCount: {
        alarms: 0,
        offline: 0,
        warnings: 0,
        information: 0,
    },
    showSeverityCount: true,
    selectedNodes: undefined,
};
const timelineWidgetSlice = createSlice({
    name: 'timeline',
    initialState: timelineInitialState,
    reducers: {
        resetTimelineConfiguration: () => {
            return timelineInitialState;
        },
        setPredefinedTimelineConfiguration: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'filters') {
                    state[key] = action.payload[key];
                }
            });
        },
        setTimelineSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleGroupDevicesFilter(state, action);
        },
        setTimelineFilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        setTimelinePreferences: (state: RootState, action: PayloadAction<any>) => {
            const currentClone = current(state);
            if (action.payload?.key === 'maxCount' || action.payload.key === 'showSeverityCount') {
                state[action.payload.key] = action.payload?.value;
                return;
            }

            const selectedSeverityIndex = currentClone['preferences']?.findIndex(
                (item: any) => item === action.payload.value
            );

            if (selectedSeverityIndex && selectedSeverityIndex === -1) {
                state['preferences']?.push(action.payload?.value);
                return;
            }
            state['preferences']?.splice(selectedSeverityIndex, 1);
        },
        setTimelineData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload;
        },
        setCount: (state: RootState, action: PayloadAction<any>) => {
            state['statusCount'] = action.payload;
        },
        setTimeline: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },
        reallocateTimelineFilters: (state: any, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },

        removeTimelineFilters: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state: any) => {
            state.secondary = [];
            state.selectedNodes = [];
            state.filters = {};
            state.statusCount = timelineInitialState.statusCount;
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (!action.payload.reset) {
                state.secondary = [];
            }
            state['deviceId'] = action.payload?.data?.id;
        });
    },
});

export const {
    setCount,
    setTimeline,
    removeTimelineFilters,
    setTimelineData,
    setTimelineSelectedNodes,
    setTimelineFilters,
    setPredefinedTimelineConfiguration,
    setTimelinePreferences,
    reallocateTimelineFilters,

    resetTimelineConfiguration,
} = timelineWidgetSlice.actions;

export default timelineWidgetSlice;
