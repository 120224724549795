import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { handleChannelRemove, handleGroupDevicesFilter, handleReallocateChannels } from './helpers';

const ScheduleInitialState = {
    secondary: [],
    filters: {},
    selectedNodes: undefined,
    maxCount: 4,
    isTotalCountVisible: false,
    viewAllButton: false,
    viewTitleBar: false,
    sortPayload: {
        key: 'name',
        sortType: 'ASC',
    },
};
const scheduleWidgetSlice = createSlice({
    name: 'schedule_widget',
    initialState: ScheduleInitialState,
    reducers: {
        resetScheduleConfiguration: () => {
            return ScheduleInitialState;
        },
        setPredefinedScheduleConfiguration: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'filters') {
                    state[key] = action.payload[key];
                }
            });
        },

        setScheduleFilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        setScheduleSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleGroupDevicesFilter(state, action);
        },
        setScheduleData: (state: RootState, action: PayloadAction<any>) => {
            state.secondary = action.payload;
        },
        setScheduleSortPayloadOrder: (state: RootState, action: PayloadAction<any>) => {
            state['sortPayload']['sortType'] = action.payload;
        },
        setScheduleSortPayloadKey: (state: RootState, action: PayloadAction<any>) => {
            state['sortPayload']['key'] = action.payload;
        },
        setGroupHierarchyFilter: (state: RootState, action: PayloadAction<string>) => {
            state['filters'] = action.payload;
        },
        reallocateSchedule: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        removeSchedule: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
        setSchedulePreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state: RootState) => {
            state.secondary = [];
            state.selectedNodes = [];
            state.filters = {};
        });

        builder.addCase('widgetConfiguration/setWidgetDevice', (state: RootState, action: any) => {
            if (!action.payload.reset) {
                state.secondary = [];
            }
            state['deviceId'] = action.payload?.data?.id;
        });
    },
});

export const {
    reallocateSchedule,
    removeSchedule,
    setSchedulePreferences,
    setGroupHierarchyFilter,
    setScheduleFilters,
    setScheduleData,
    setScheduleSelectedNodes,
    setPredefinedScheduleConfiguration,
    setScheduleSortPayloadKey,
    setScheduleSortPayloadOrder,
    resetScheduleConfiguration,
} = scheduleWidgetSlice.actions;

export default scheduleWidgetSlice;
