import { api } from '../../app/api/baseApi';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';

const reportsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllMonthlyReports: builder.query<object, any>({
            query: (body) => ({
                url: `api/v1/reports/monthlyreport`,
                body: body,
                method: 'POST',
            }),
        }),

        getMobileReports: builder.query<any, any>({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: 'api/v1/reports/monthlyreport',
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
        }),
    }),
});

export const { useGetAllMonthlyReportsQuery, useGetMobileReportsQuery } = reportsApi;
