import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Profile } from '../../types/Profile';
import { RootState } from '../../app/store';

const initialProfileState: Profile = {
    id: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    country: '',
    mfa: false,
    orgAdmin: false,
    roles: [],
    email: '',
    areaCode: '',
    image: '',
};

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        profile: initialProfileState,
        globalPermissions: undefined,
        permissions: undefined,
        routePermissions: undefined,
        currentRoleId: '',
    } as any,
    reducers: {
        setProfile: (state, action: PayloadAction<Profile>) => {
            const profile: Profile = action.payload;
            state.profile = profile;
        },
        setResetPermissions: (state) => {
            if (state.permissions?.length && state.globalPermissions?.length) {
                state.permissions = state.globalPermissions;
                state.globalPermissions = [];
                return;
            }
        },

        setNewGlobalPermission: (state) => {
            if (state.globalPermissions?.length && state.permissions?.length) {
                state.globalPermissions = state.permissions;
            }
        },

        setPermissions: (state, action: PayloadAction<any>) => {
            if (state.permissions?.length && !state.globalPermissions?.length) {
                state.globalPermissions = state.permissions;
            }
            const permissions: any = action.payload.permissions;
            state.permissions = permissions;
            if (action.payload.isRoute) {
                state.routePermissions = permissions;
            }
        },
    },
});

export const { setProfile, setPermissions, setResetPermissions, setNewGlobalPermission } = profileSlice.actions;
export default profileSlice;

export const selectCurrentProfile = (state: RootState): Profile => state.profile.profile;
export const selectCurrentPermission = (state: RootState): any => state.profile.permissions;
export const selectGlobalPermissions = (state: RootState): any => state.profile.globalPermissions;
