import { useGetScoreListQuery } from '@fiji/common/src/features/prediction/predictionApi';
import { Stack, Avatar, Typography, List, ListItem, Box } from '@mui/material';
import { CustomModal } from 'components';
import { ModalActions } from 'components/CustomModal';
import React from 'react';

export const ScoreModal = React.forwardRef((props: any, ref): JSX.Element => {
    const [openPulseScore, setOpenPulseScore] = React.useState(false);
    const { data: scoreListData, isLoading }: any = useGetScoreListQuery(undefined, { skip: !openPulseScore });
    const pulseScoreActions: ModalActions[] = [
        {
            key: 'ok',
            label: 'Ok',
            close: true,
            variant: 'contained',
            handleClick: (): void => {
                setOpenPulseScore(false);
            },
        },
    ];

    React.useImperativeHandle(ref, () => ({
        onClick: (): void => setOpenPulseScore(true),
    }));

    return (
        <CustomModal
            isOpen={openPulseScore}
            isLoading={isLoading}
            noDividers
            hidePadding
            type="medium"
            actions={pulseScoreActions}
            header="PulseScore"
        >
            <>
                <Stack px={3}>
                    <Stack direction={'row'} spacing={1} py={1}>
                        {scoreListData?.data?.grades?.map(
                            (item: any): JSX.Element => (
                                <Avatar
                                    key={item?.gradeName + item?.color}
                                    sx={{ backgroundColor: item?.color ?? '#2CA618' }}
                                >
                                    <Typography
                                        className="text-white"
                                        fontWeight={'700'}
                                        fontSize={'20px'}
                                        textAlign={'center'}
                                    >
                                        {item?.gradeName}
                                    </Typography>
                                </Avatar>
                            )
                        )}
                    </Stack>
                    <Typography variant="body1" className="black-500" mt={2}>
                        <span className="fw-700 f-16">PulseScore™</span> uses a weighted point scoring method (0-100
                        points where 100 is an A+ and 65 or below is an F).
                    </Typography>
                </Stack>
                <List disablePadding className="margin-y-24">
                    <ListItem className="flex-center-between padding-x-24">
                        <Typography variant="body1" className="black-500">
                            Device Mode
                        </Typography>
                        <Box className="border-1 border-radius-4 padding-4">
                            <Typography variant="body1">30</Typography>
                        </Box>
                    </ListItem>
                    <ListItem className="flex-center-between bg-header padding-x-24">
                        <Typography variant="body1" className="black-500">
                            Events and Alarms by Severity
                        </Typography>
                        <Box className="border-1 border-radius-4 padding-4">
                            <Typography variant="body1">25</Typography>
                        </Box>
                    </ListItem>
                    <ListItem className="flex-center-between padding-x-24">
                        <Typography variant="body1" className="black-500">
                            Device Health Data
                        </Typography>
                        <Box className="border-1 border-radius-4 padding-4">
                            <Typography variant="body1">20</Typography>
                        </Box>
                    </ListItem>
                    <ListItem className="flex-center-between bg-header padding-x-24">
                        <Typography variant="body1" className="black-500">
                            Historical Data
                        </Typography>
                        <Box className="border-1 border-radius-4 padding-4">
                            <Typography variant="body1">15</Typography>
                        </Box>
                    </ListItem>
                    <ListItem className="flex-center-between padding-x-24">
                        <Typography variant="body1" className="black-500">
                            Configuration, Age, Best Practices
                        </Typography>
                        <Box className="border-1 border-radius-4 padding-4">
                            <Typography variant="body1">10</Typography>
                        </Box>
                    </ListItem>
                </List>
                <Stack spacing={1} px={3}>
                    <Typography variant="body1" className="black-500">
                        Scoring is refreshed with the latest device data received.
                    </Typography>
                    <Typography variant="body1" className="black-500">
                        Devices in Normal mode (Online, Healthy), with no alarms and optimal environments will achieve
                        higher PulseScores.
                    </Typography>
                    <Typography variant="body1" className="black-500">
                        Not all devices or device types are supported at this time.
                    </Typography>
                </Stack>
            </>
        </CustomModal>
    );
});
