export const metadata = {
    constraints: {
        general: {},
    },
    validation: {
        general: {
            TextInput: {
                minLength: 0,
                maxLength: 100,
                type: 'string',
            },
            email: {
                minLength: 0,
                maxLength: 254,
                pattern: /^[a-zA-Z0-9+]+(?:\.[a-zA-Z0-9+]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/,
                patternErrMsg: 'Please, enter a valid email address.',
            },
            countryCode: {
                min: 1,
                max: 999,
                countryCodeErrMsg: 'Please, enter a valid area code',
            },
            phoneNumberUS: {
                minLength: 10,
                maxLength: 10,
                pattern: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                patternErrMsg: 'This phone number is not valid',
                phoneNumberUSErrMsg: 'Please, enter a 10-digit phone number',
            },
            longitude: {
                min: -90,
                max: 90,
                pattern: /^[-+]?[0-9]*\.?[0-9]+$/,
                patternErrMsg: 'Needs to be a number from -90 to 90',
            },
            latitude: {
                min: -90,
                max: 90,
                pattern: /^[-+]?[0-9]*\.?[0-9]+$/,
                patternErrMsg: 'Needs to be a number from -90 to 90',
            },
        },
        addDeviceForm: {
            Guid: {
                maxLength: 36,
                minLength: 36,
                default: '',
                type: 'string',
                pattern: '^(?:[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})?$',
            },
            Group: {
                maxLength: 1,
                minLength: 1,
                default: '',
            },
            DeviceType: {
                default: '',
                type: 'string',
            },
            DeviceName: {
                maxLength: 32,
                minLength: 1,
                //change default value later -- dom -- before release
                pattern: '^(?!.*--).*[a-zA-Z0-9][a-zA-Z0-9\\ |_|-]{0,32}?$',
                default: '',
                type: 'string',
            },
            ProductID: {
                maxLength: 6,
                minLength: 1,
                default: '',
                type: 'string',
                pattern: '^[a-zA-Z0-9-]*$',
            },
            SerialNumber: {
                maxLength: 18,
                minLength: 18,
                default: '',
                type: 'string',
                pattern: '^[a-zA-Z0-9-]*$',
                keyboardType: 'numeric',
            },
            Tags: {
                default: '',
                maxTagsListLength: 6,
                tagsMaxLength: 16,
                pattern: '^[a-zA-Z0-9][a-zA-Z0-9\\ |_|-]{0,16}?$',
            },
            Description: {
                maxLength: 256,
                minLength: 0,
                default: '',
                type: 'string',
            },
        },
        addConfigure: {
            ipv4Address: {
                type: 'string',
                pattern: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
            },
            ipv4Subnet: {
                type: 'string',
                pattern: '(255|254|252|248|240|224|192|128|0+)',
            },
            ipv4Gateway: {
                type: 'string',
                pattern: /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
            },
            port: {
                type: 'string',
                pattern: /^(0|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
            },
            portAddress: {
                type: 'string',
                pattern: /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/i,
            },
        },
        editOrganizationDetails: {
            firstName: {
                type: 'string',
                maxLength: 50,
                minLength: 0,
                pattern: /^[a-zA-Z ]*$/,
                patternErrMsg: 'Please, enter non-numeric characters',
                maxLengthErrMsg: 'First Name cannot be more than 50 characters',
            },
            lastName: {
                type: 'string',
                maxLength: 50,
                minLength: 0,
                pattern: /^[a-zA-Z ]*$/,
                patternErrMsg: 'Please, enter non-numeric characters',
                maxLengthErrMsg: 'Last Name cannot be more than 50 characters',
            },
            location: {
                type: 'string',
                maxLength: 256,
                minLength: 0,
                //pattern: /^[a-zA-Z ]*$/,
                //patternErrMsg: 'Please, enter non-numeric characters',
                maxLengthErrMsg: 'Location cannot be more than 254 characters',
            },
        },
    },
};
