import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
// import { useDebounce } from 'hooks';

type CustomSliderProps = {
    entityName: string;
    payload: any;
    setPayload: (payload: any) => void;
    min: number;
    max: number;
    unit: string;
    thresholdData: any;
    isVisible: boolean;
    isDisabled: boolean;
    [key: string]: any;
};

const thumbColors: any = {
    lowCritical: '#ca3c3d', // red
    lowWarning: '#f0cb2f', // yellow
    highWarning: '#f0cb2f', // yellow
    highCritical: '#ca3c3d', // red
};
const thumbDisableColors: any = {
    lowCritical: '#ebb5b5', // red
    lowWarning: '#FAECB0', // yellow
    highWarning: '#FAECB0', // yellow
    highCritical: '#ebb5b5', // red
};

export const CustomSlider = (props: CustomSliderProps): JSX.Element => {
    const { payload, setPayload, min, max, unit, isVisible, thresholdData } = props;

    const [value, setValue] = useState<number[]>([]);

    const [perc, setPerc] = useState<number[]>([0, 0, 0, 0]);

    const onChange = (e: Event, tValues: any): void => {
        const valuesArray = Array.isArray(tValues) ? tValues : [tValues];
        const [minVal, maxVal]: number[] = valuesArray;
        if (maxVal >= minVal || valuesArray?.length === 1) {
            const newData: any = value?.map((item: any, idx: number) => ({ ...item, value: valuesArray[idx] }));

            setValue(newData);
            const newPayload = payload.map((item: any) => ({
                ...item,
                value: newData?.find((ele: any) => ele?.id === item?.id)?.value ?? item?.value,
            }));
            setPerc(newPayload?.map((ele: any) => (ele?.isEnabled ? ((ele?.value ?? 0.1) / (max ?? 10)) * 100 : null)));
            setPayload(newPayload);
        }
        props?.setIsChangedByInput?.(false);
    };

    const sliderValuesHandler = (newPayload: any): void => {
        setValue(newPayload?.filter((ele: any) => ele?.isEnabled));
        setPerc(newPayload?.map((ele: any) => (ele?.isEnabled ? ((ele?.value ?? 0.1) / (max ?? 10)) * 100 : null)));
    };

    React.useEffect(() => {
        const newPayload: any = JSON.parse(JSON.stringify(payload ?? {}));
        if (thresholdData?.data?.threshold?.length && isVisible) {
            thresholdData?.data?.threshold?.forEach((element: any, index: number) => {
                newPayload[element?.name] = parseFloat(element?.value) || index;
            });
        }
        sliderValuesHandler(newPayload);
        if (!isVisible) {
            setValue([]);
            setPerc([]);
        }
    }, [isVisible, thresholdData?.data?.threshold]);

    React.useEffect(() => {
        if (props?.isChangedByInput || !value?.length) {
            const newPayload: any = JSON.parse(JSON.stringify(payload ?? {}));
            sliderValuesHandler(newPayload);
        }
    }, [isVisible, props?.isChangedByInput, payload]);

    const getColor = (newValue: any): object => {
        const style: any = {};
        newValue?.forEach((ele: any, idx: any) => {
            const color = props?.isDisabled ? thumbDisableColors[ele?.id] : thumbColors[ele?.id];
            style[`& .MuiSlider-thumb[data-index="${idx}"]`] = {
                background: color,
                '& span': {
                    background: color,
                },
            };
        });
        return style;
    };

    return (
        <Slider
            disabled={props?.isDisabled}
            size="medium"
            sx={{
                '& .MuiSlider-markLabel': {
                    color: '#424E54',
                },
                '& .MuiSlider-track': {
                    background: 'none',
                    height: 14,
                    borderColor: 'white',
                    visibility: 'hidden',
                },
                '& .MuiSlider-valueLabelCircle::before': {
                    backgroundColor: '#000000 !important',
                    color: '#FFFFFF',
                },
                '& .MuiSlider-thumb span': {
                    backgroundColor: '#000000 !important',
                    color: '#FFFFFF',
                    borderRadius: '4px',
                },
                '& .MuiSlider-thumb.Mui-disabled': {
                    boxShadow: 'none',
                    borderRadius: '40%',
                    height: '14px',
                },
                '& .MuiSlider-thumb.Mui-disabled::before': {
                    boxShadow: 'none',
                },
                ...getColor(value),
                '& .MuiSlider-mark': {
                    background: 'none',
                },
                '& .MuiSlider-rail': {
                    height: 14,
                    background: `linear-gradient(to right, #ca3c3d 0% ${perc[0] ?? 0}%, #f0cb2f ${perc[0] ?? 0}% ${
                        perc[1] ?? 0
                    }%, gray ${perc[1] ?? 0}% ${perc[2] ?? perc[3] ?? '100'}%, #f0cb2f ${perc[2] ?? 0}% ${
                        perc[3] ?? '100'
                    }%, #ca3c3d ${perc[3] ?? 0}% 100%)`,
                },
                '& .MuiSlider-valueLabel': {},
            }}
            value={value?.map((item: any) => item?.value) ?? []}
            step={0.01}
            min={min ?? 0}
            max={max ?? 10}
            valueLabelFormat={(val: number): string => `${val} ${unit ?? ''}`}
            marks={[
                { value: min ?? 0, label: `${min ?? 0}${unit ?? ''}` },
                { value: max ?? 10, label: `${max ?? 10}${unit ?? ''}` },
            ]}
            onChange={onChange}
            valueLabelDisplay="auto"
        />
    );
};
