import React, { ReactNode } from 'react';

import {
    setSchedulesForComponent,
    setComponentSelectedDeviceType,
} from '@fiji/common/src/features/component/schedule/scheduleSlice';

import { DayView } from './DayView';
import { WeekView } from './WeekView';
import { Paper, Stack } from '@mui/material';
import { ScheduleHeader } from './common';
import { useAppDispatch, useTypedSelector } from '@fiji/common';

type ScheduleProps = {
    schedules: any;
    leftGrid?: JSX.Element;
    gridLabel: ReactNode;
    rightComponents?: Array<{ [key: string]: unknown }>;
    groupingEnabled?: boolean;
    selectedDeviceType?: any;
} & (ScheduleHeaderLinkedProp | GroupingProps);

type ScheduleHeaderLinkedProp = {
    groupingEnabled?: false;
};

type GroupingProps = {
    groupingEnabled: true;
    groupingLabel: ReactNode;
};

export const Schedule = (props: ScheduleProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const mode = useTypedSelector((state: any) => state['schedule']['mode']);

    React.useEffect(() => {
        dispatch(setComponentSelectedDeviceType(props?.selectedDeviceType));
    }, [props?.selectedDeviceType]);

    React.useEffect(() => {
        if (props?.schedules) {
            dispatch(setSchedulesForComponent(props.schedules));
        }
    }, [props?.schedules]);

    return (
        <Paper className="padding-16 margin-top-24">
            <ScheduleHeader
                rightComponents={props?.rightComponents}
                groupingEnabled={props?.groupingEnabled}
                groupingLabel={props?.groupingEnabled && props?.groupingLabel}
            />
            <Stack>
                {mode === 'day' ? <DayView gridLabel={props?.gridLabel} leftGrid={props?.leftGrid} /> : <WeekView />}
            </Stack>
        </Paper>
    );
};
