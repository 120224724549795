import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
    addWidgetModal: {
        isOpen: false,
    },
    cloneWidgetModal: { payload: { isSystemWidget: true } },
    renameWidgetModal: { payload: {} },
    payload: {
        isSystemWidget: true,
    },
    dropdownOptions: [],
    deviceTypes: [],
    category: '',
    searchKey: undefined,
    deviceTypeSearchKey: undefined,
};

const widgetSlice = createSlice({
    name: 'widget',
    initialState: initialState,
    reducers: {
        resetCloneWidgetModal: (state: RootState) => {
            state['cloneWidgetModal'] = {
                payload: {
                    isSystemWidget: true,
                },
            };
        },
        resetRenameWidgetModal: (state: RootState) => {
            state['renameWidgetModal'] = { payload: {} };
        },
        setCloneWidgetPayload: (state: RootState, action: PayloadAction<any>) => {
            state['cloneWidgetModal']['payload'][action.payload.key] = action.payload.value;
        },
        setRenameWidgetPayload: (state: RootState, action: PayloadAction<any>) => {
            state['renameWidgetModal']['payload'][action.payload.key] = action.payload.value;
        },
        setAddWidgetModal: (state: RootState, action: PayloadAction<any>) => {
            state.addWidgetModal = action.payload;
        },
        setPayload: (state: RootState, action: PayloadAction<any>) => {
            state.payload = { ...state.payload, [action?.payload?.key]: action?.payload?.value };
            if (action.payload.key === 'config' && action.payload?.value?.multiDevice) {
                state['category'] = '';
                delete state.payload['sourceId'];
                delete state.payload['sourceName'];
            }
        },
        setDeviceModelsSearch: (state: RootState, action: PayloadAction<any>) => {
            state['deviceTypeSearchKey'] = action.payload;
        },
        resetWidget: () => {
            return initialState;
        },
        setWidgetDeviceTypes: (state: RootState, action: PayloadAction<any>) => {
            state.deviceTypes = action.payload;
        },
        setDropdownOptions: (state: RootState, action: PayloadAction<any>) => {
            state.dropdownOptions = action.payload;
        },
        setCategory: (state: RootState, action: PayloadAction<any>) => {
            state.category = action.payload;
        },
        setSearchKey: (state: RootState, action: PayloadAction<any>) => {
            state['searchKey'] = action.payload;
        },
    },
});

export const {
    setPayload,
    setCategory,
    resetWidget,
    setSearchKey,
    setAddWidgetModal,
    setDropdownOptions,
    resetRenameWidgetModal,
    setRenameWidgetPayload,
    setWidgetDeviceTypes,
    setCloneWidgetPayload,
    setDeviceModelsSearch,
    resetCloneWidgetModal,
} = widgetSlice.actions;

export const addWidgetModalData = (state: RootState): any => state.widget.addWidgetModal;
export const cloneWidgetModalData = (state: RootState): any => state.widget.cloneWidgetModal;
export const renameWidgetModalData = (state: RootState): any => state.widget.renameWidgetModal;

export default widgetSlice;
