import React from 'react';

import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import {
    resetVirtualLoadPayload,
    setVirtualLoadPayload,
} from '@fiji/common/src/features/deviceManagement/virtualLoadSlice';
import { useCreateVirtualLoadMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { configureSummaryValues, widgetList } from '@fiji/common/src/features/dashboardManagement/commonDashboardSlice';
import { useGetWidgetDetails } from '@fiji/common/src/hooks';
import { useTranslation } from 'react-i18next';

type ReturnProps = {
    handlePayloadChange: (
        args0:
            | SelectChangeEvent<string | number | string[]>
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        args1?: number
    ) => void;
    handleSubmit: (args: any) => Promise<void>;
    isSuccess: boolean;
};

export const useHandleVirtualLoads = (args?: any): ReturnProps => {
    const { deviceId } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const payload = useTypedSelector((state) => state.virtualLoad.payload);
    const widgets = useTypedSelector(widgetList);

    const { payload: widgetPayload } = useGetWidgetDetails({
        selectorType: 'commonDashboard',
        dataGetter: { deviceId },
    });

    const [createVirtualLoad, { isSuccess }] = useCreateVirtualLoadMutation({
        fixedCacheKey: 'createVirtualLoad',
    });

    const virtualLoadCachedData = useTypedSelector(
        (state) => state.api.queries[`getAllVirtualLoads(${JSON.stringify(widgetPayload['virtual_loads']())})`]?.data
    );

    React.useEffect(() => {
        if (isSuccess && virtualLoadCachedData?.data?.records?.length) {
            const selectedWidgetConfig = JSON.parse(
                JSON.stringify(widgets?.find((item: any) => item.id === args?.widgetId) ?? {})
            );
            if (selectedWidgetConfig?.config) {
                selectedWidgetConfig['config']['secondary'] = virtualLoadCachedData?.data?.records;
            }
            dispatch(configureSummaryValues({ config: selectedWidgetConfig?.config, widgetId: args?.widgetId }));
        }
    }, [isSuccess, virtualLoadCachedData]);

    const handlePayloadChange = (
        e: SelectChangeEvent<string | number | string[]> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        limit?: number
    ): void => {
        const value: any = e.target?.value;

        if (limit && value?.length > limit) {
            return;
        }
        dispatch(
            setVirtualLoadPayload({
                key: e.target.name,
                value: value.trimStart(),
            })
        );
    };

    const handleSubmit = async (): Promise<void> => {
        const { error }: any = await createVirtualLoad({ ...payload, deviceId: deviceId });
        if (!error) {
            dispatch(setMessageContent({ isOpen: true, message: t('WIDGETS:LOADS.VIRTUAL_LOAD_SUCCESS_CREATION') }));
            dispatch(resetVirtualLoadPayload());
        }
    };

    return {
        handlePayloadChange,
        handleSubmit,

        isSuccess,
    };
};
