import React from 'react';

import { useTheme } from '@mui/material/styles';
import { setEventSearch, setExpandedEventCategories } from '@fiji/common/src/features/Timeline/timelineSlice';
import { Search, UnfoldMore } from '@mui/icons-material';
import { Button, Stack, TextField } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '@fiji/common';

export const EventsHeader = (): JSX.Element => {
    const theme: any = useTheme();
    const dispatch = useAppDispatch();
    const expandedCategories = useTypedSelector((state: any) => state['alarmtimeline']['expandedEventCategories']);

    const searchKey = useTypedSelector((state: any) => state['alarmtimeline']['eventSearch']);

    return (
        <Stack mb={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                <TextField
                    hiddenLabel
                    id="searchKey"
                    value={searchKey ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        dispatch(setEventSearch(e.target.value));
                    }}
                    size="small"
                    InputProps={{
                        startAdornment: <Search id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                    }}
                />
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Button
                    id="toggleExpand"
                    sx={{
                        border: `1px solid ${theme?.palette?.primary?.main}`,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.[50],
                        },
                    }}
                    variant="outlined"
                    onClick={(): void => {
                        dispatch(setExpandedEventCategories());
                    }}
                    startIcon={<UnfoldMore />}
                >
                    {Object?.values(expandedCategories)?.every((item: any) => item === true)
                        ? 'Collapse All'
                        : '  Expand All'}
                </Button>
            </Stack>
        </Stack>
    );
};
