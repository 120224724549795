export const holidayCodes: any = {
    Afghanistan: 'en.af%23holiday@group.v.calendar.google.com',
    Albania: 'en.al%23holiday@group.v.calendar.google.com',
    Algeria: 'en.dz%23holiday@group.v.calendar.google.com',
    'American Samoa': 'en.as%23holiday@group.v.calendar.google.com',
    Andorra: 'en.ad%23holiday@group.v.calendar.google.com',
    Angola: 'en.ao%23holiday@group.v.calendar.google.com',
    Anguilla: 'en.ai%23holiday@group.v.calendar.google.com',
    'Antigua and Barbuda': 'en.ag%23holiday@group.v.calendar.google.com',
    Argentina: 'en.ar%23holiday@group.v.calendar.google.com',
    Armenia: 'en.am%23holiday@group.v.calendar.google.com',
    Aruba: 'en.aw%23holiday@group.v.calendar.google.com',
    Australia: 'en.australian%23holiday@group.v.calendar.google.com',
    Austria: 'en.austrian%23holiday@group.v.calendar.google.com',
    Azerbaijan: 'en.az%23holiday@group.v.calendar.google.com',
    Bahamas: 'en.bs%23holiday@group.v.calendar.google.com',
    Bahrain: 'en.bh%23holiday@group.v.calendar.google.com',
    Bangladesh: 'en.bd%23holiday@group.v.calendar.google.com',
    Barbados: 'en.bb%23holiday@group.v.calendar.google.com',
    Belarus: 'en.by%23holiday@group.v.calendar.google.com',
    Belgium: 'en.be%23holiday@group.v.calendar.google.com',
    Belize: 'en.bz%23holiday@group.v.calendar.google.com',
    Benin: 'en.bj%23holiday@group.v.calendar.google.com',
    Bermuda: 'en.bm%23holiday@group.v.calendar.google.com',
    Bhutan: 'en.bt%23holiday@group.v.calendar.google.com',
    Bolivia: 'en.bo%23holiday@group.v.calendar.google.com',
    'Bosnia and Herzegovina': 'en.ba%23holiday@group.v.calendar.google.com',
    Botswana: 'en.bw%23holiday@group.v.calendar.google.com',
    Brazil: 'en.brazilian%23holiday@group.v.calendar.google.com',
    'British Virgin Islands': 'en.vg%23holiday@group.v.calendar.google.com',
    'Brunei Darussalam': 'en.bn%23holiday@group.v.calendar.google.com',
    Bulgaria: 'en.bulgarian%23holiday@group.v.calendar.google.com',
    'Burkina Faso': 'en.bf%23holiday@group.v.calendar.google.com',
    Burundi: 'en.bi%23holiday@group.v.calendar.google.com',
    Cambodia: 'en.kh%23holiday@group.v.calendar.google.com',
    Cameroon: 'en.cm%23holiday@group.v.calendar.google.com',
    Canada: 'en.canadian%23holiday@group.v.calendar.google.com',
    'Cape Verde': 'en.cv%23holiday@group.v.calendar.google.com',
    'Cayman Islands': 'en.ky%23holiday@group.v.calendar.google.com',
    'Central African Republic': 'en.cf%23holiday@group.v.calendar.google.com',
    Chad: 'en.td%23holiday@group.v.calendar.google.com',
    Chile: 'en.cl%23holiday@group.v.calendar.google.com',
    China: 'en.china%23holiday@group.v.calendar.google.com',
    Colombia: 'en.co%23holiday@group.v.calendar.google.com',
    Comoros: 'en.km%23holiday@group.v.calendar.google.com',
    Congo: 'en.cg%23holiday@group.v.calendar.google.com',
    'Cook Islands': 'en.ck%23holiday@group.v.calendar.google.com',
    'Costa Rica': 'en.cr%23holiday@group.v.calendar.google.com',
    "Côte d'Ivoire": 'en.ci%23holiday@group.v.calendar.google.com',
    Croatia: 'en.croatian%23holiday@group.v.calendar.google.com',
    Cuba: 'en.cu%23holiday@group.v.calendar.google.com',
    Curaçao: 'en.cw%23holiday@group.v.calendar.google.com',
    Cyprus: 'en.cy%23holiday@group.v.calendar.google.com',
    Czechia: 'en.czech%23holiday@group.v.calendar.google.com',
    "Democratic People's Republic of Korea": 'en.kp%23holiday@group.v.calendar.google.com',
    Denmark: 'en.danish%23holiday@group.v.calendar.google.com',
    Djibouti: 'en.dj%23holiday@group.v.calendar.google.com',
    Dominica: 'en.dm%23holiday@group.v.calendar.google.com',
    'Dominican Republic': 'en.do%23holiday@group.v.calendar.google.com',
    Ecuador: 'en.ec%23holiday@group.v.calendar.google.com',
    Egypt: 'en.eg%23holiday@group.v.calendar.google.com',
    'El Salvador': 'en.sv%23holiday@group.v.calendar.google.com',
    'Equatorial Guinea': 'en.gq%23holiday@group.v.calendar.google.com',
    Eritrea: 'en.er%23holiday@group.v.calendar.google.com',
    Estonia: 'en.ee%23holiday@group.v.calendar.google.com',
    Ethiopia: 'en.et%23holiday@group.v.calendar.google.com',
    'Falkland Islands (Malvinas)': 'en.fk%23holiday@group.v.calendar.google.com',
    'Faroe Islands': 'en.fo%23holiday@group.v.calendar.google.com',
    'Federated States of Micronesia': 'en.fm%23holiday@group.v.calendar.google.com',
    Fiji: 'en.fj%23holiday@group.v.calendar.google.com',
    Finland: 'en.finnish%23holiday@group.v.calendar.google.com',
    France: 'en.french%23holiday@group.v.calendar.google.com',
    'French Guiana': 'en.gf%23holiday@group.v.calendar.google.com',
    'French Polynesia': 'en.pf%23holiday@group.v.calendar.google.com',
    Gabon: 'en.ga%23holiday@group.v.calendar.google.com',
    Gambia: 'en.gm%23holiday@group.v.calendar.google.com',
    Georgia: 'en.ge%23holiday@group.v.calendar.google.com',
    Germany: 'en.german%23holiday@group.v.calendar.google.com',
    Ghana: 'en.gh%23holiday@group.v.calendar.google.com',
    Gibraltar: 'en.gi%23holiday@group.v.calendar.google.com',
    Greece: 'en.greek%23holiday@group.v.calendar.google.com',
    Greenland: 'en.gl%23holiday@group.v.calendar.google.com',
    Grenada: 'en.gd%23holiday@group.v.calendar.google.com',
    Guam: 'en.gu%23holiday@group.v.calendar.google.com',
    Guatemala: 'en.gt%23holiday@group.v.calendar.google.com',
    Guernsey: 'en.gg%23holiday@group.v.calendar.google.com',
    Guinea: 'en.gn%23holiday@group.v.calendar.google.com',
    'Guinea-Bissau': 'en.gw%23holiday@group.v.calendar.google.com',
    Guyana: 'en.gy%23holiday@group.v.calendar.google.com',
    Haiti: 'en.ht%23holiday@group.v.calendar.google.com',
    'Holy See (Vatican City State)': 'en.va%23holiday@group.v.calendar.google.com',
    Honduras: 'en.hn%23holiday@group.v.calendar.google.com',
    'Hong Kong': 'en.hong_kong%23holiday@group.v.calendar.google.com',
    Hungary: 'en.hungarian%23holiday@group.v.calendar.google.com',
    Iceland: 'en.is%23holiday@group.v.calendar.google.com',
    India: 'en.indian%23holiday@group.v.calendar.google.com',
    Indonesia: 'en.indonesian%23holiday@group.v.calendar.google.com',
    Iraq: 'en.iq%23holiday@group.v.calendar.google.com',
    Ireland: 'en.irish%23holiday@group.v.calendar.google.com',
    'Islamic Republic of Iran': 'en.ir%23holiday@group.v.calendar.google.com',
    'Isle of Man': 'en.im%23holiday@group.v.calendar.google.com',
    Israel: 'en.jewish%23holiday@group.v.calendar.google.com',
    Italy: 'en.italian%23holiday@group.v.calendar.google.com',
    Jamaica: 'en.jm%23holiday@group.v.calendar.google.com',
    Japan: 'en.japanese%23holiday@group.v.calendar.google.com',
    Jersey: 'en.je%23holiday@group.v.calendar.google.com',
    Jordan: 'en.jo%23holiday@group.v.calendar.google.com',
    Kazakhstan: 'en.kz%23holiday@group.v.calendar.google.com',
    Kenya: 'en.ke%23holiday@group.v.calendar.google.com',
    Kiribati: 'en.ki%23holiday@group.v.calendar.google.com',
    Kuwait: 'en.kw%23holiday@group.v.calendar.google.com',
    Kyrgyzstan: 'en.kg%23holiday@group.v.calendar.google.com',
    "Lao People's Democratic Republic": 'en.la%23holiday@group.v.calendar.google.com',
    Latvia: 'en.latvian%23holiday@group.v.calendar.google.com',
    Lebanon: 'en.lb%23holiday@group.v.calendar.google.com',
    Lesotho: 'en.ls%23holiday@group.v.calendar.google.com',
    Liberia: 'en.lr%23holiday@group.v.calendar.google.com',
    Libya: 'en.ly%23holiday@group.v.calendar.google.com',
    Liechtenstein: 'en.li%23holiday@group.v.calendar.google.com',
    Lithuania: 'en.lithuanian%23holiday@group.v.calendar.google.com',
    Luxembourg: 'en.lu%23holiday@group.v.calendar.google.com',
    Macao: 'en.mo%23holiday@group.v.calendar.google.com',
    Madagascar: 'en.mg%23holiday@group.v.calendar.google.com',
    Malawi: 'en.mw%23holiday@group.v.calendar.google.com',
    Malaysia: 'en.malaysia%23holiday@group.v.calendar.google.com',
    Maldives: 'en.mv%23holiday@group.v.calendar.google.com',
    Mali: 'en.ml%23holiday@group.v.calendar.google.com',
    Malta: 'en.mt%23holiday@group.v.calendar.google.com',
    'Marshall Islands': 'en.mh%23holiday@group.v.calendar.google.com',
    Martinique: 'en.mq%23holiday@group.v.calendar.google.com',
    Mauritania: 'en.mr%23holiday@group.v.calendar.google.com',
    Mauritius: 'en.mu%23holiday@group.v.calendar.google.com',
    Mayotte: 'en.yt%23holiday@group.v.calendar.google.com',
    Mexico: 'en.mexican%23holiday@group.v.calendar.google.com',
    Moldova: 'en.md%23holiday@group.v.calendar.google.com',
    Monaco: 'en.mc%23holiday@group.v.calendar.google.com',
    Mongolia: 'en.mn%23holiday@group.v.calendar.google.com',
    Montenegro: 'en.me%23holiday@group.v.calendar.google.com',
    Montserrat: 'en.ms%23holiday@group.v.calendar.google.com',
    Morocco: 'en.ma%23holiday@group.v.calendar.google.com',
    Mozambique: 'en.mz%23holiday@group.v.calendar.google.com',
    Myanmar: 'en.mm%23holiday@group.v.calendar.google.com',
    Namibia: 'en.na%23holiday@group.v.calendar.google.com',
    Nauru: 'en.nr%23holiday@group.v.calendar.google.com',
    Nepal: 'en.np%23holiday@group.v.calendar.google.com',
    Netherlands: 'en.dutch%23holiday@group.v.calendar.google.com',
    'New Caledonia': 'en.nc%23holiday@group.v.calendar.google.com',
    'New Zealand': 'en.new_zealand%23holiday@group.v.calendar.google.com',
    Nicaragua: 'en.ni%23holiday@group.v.calendar.google.com',
    Niger: 'en.ne%23holiday@group.v.calendar.google.com',
    Nigeria: 'en.ng%23holiday@group.v.calendar.google.com',
    'Northern Mariana Islands': 'en.mp%23holiday@group.v.calendar.google.com',
    Norway: 'en.norwegian%23holiday@group.v.calendar.google.com',
    Oman: 'en.om%23holiday@group.v.calendar.google.com',
    Pakistan: 'en.pk%23holiday@group.v.calendar.google.com',
    Palau: 'en.pw%23holiday@group.v.calendar.google.com',
    Panama: 'en.pa%23holiday@group.v.calendar.google.com',
    'Papua New Guinea': 'en.pg%23holiday@group.v.calendar.google.com',
    Paraguay: 'en.py%23holiday@group.v.calendar.google.com',
    Peru: 'en.pe%23holiday@group.v.calendar.google.com',
    Philippines: 'en.philippines%23holiday@group.v.calendar.google.com',
    Poland: 'en.polish%23holiday@group.v.calendar.google.com',
    Portugal: 'en.portuguese%23holiday@group.v.calendar.google.com',
    'Puerto Rico': 'en.pr%23holiday@group.v.calendar.google.com',
    Qatar: 'en.qa%23holiday@group.v.calendar.google.com',
    'Republic of Korea': 'en.south_korea%23holiday@group.v.calendar.google.com',
    Réunion: 'en.re%23holiday@group.v.calendar.google.com',
    Romania: 'en.romanian%23holiday@group.v.calendar.google.com',
    'Russian Federation': 'en.russian%23holiday@group.v.calendar.google.com',
    Rwanda: 'en.rw%23holiday@group.v.calendar.google.com',
    'Saint Barthélemy': 'en.bl%23holiday@group.v.calendar.google.com',
    'Saint Helena': 'en.sh%23holiday@group.v.calendar.google.com',
    'Saint Kitts and Nevis': 'en.kn%23holiday@group.v.calendar.google.com',
    'Saint Lucia': 'en.lc%23holiday@group.v.calendar.google.com',
    'Saint Martin (French part)': 'en.mf%23holiday@group.v.calendar.google.com',
    'Saint Pierre and Miquelon': 'en.pm%23holiday@group.v.calendar.google.com',
    'Saint Vincent and the Grenadines': 'en.vc%23holiday@group.v.calendar.google.com',
    Samoa: 'en.ws%23holiday@group.v.calendar.google.com',
    'San Marino': 'en.sm%23holiday@group.v.calendar.google.com',
    'Sao Tome and Principe': 'en.st%23holiday@group.v.calendar.google.com',
    'Saudi Arabia': 'en.saudiarabian%23holiday@group.v.calendar.google.com',
    Senegal: 'en.sn%23holiday@group.v.calendar.google.com',
    Serbia: 'en.rs%23holiday@group.v.calendar.google.com',
    Seychelles: 'en.sc%23holiday@group.v.calendar.google.com',
    'Sierra Leone': 'en.sl%23holiday@group.v.calendar.google.com',
    Singapore: 'en.singapore%23holiday@group.v.calendar.google.com',
    'Sint Maarten (Dutch part)': 'en.sx%23holiday@group.v.calendar.google.com',
    Slovakia: 'en.slovak%23holiday@group.v.calendar.google.com',
    Slovenia: 'en.slovenian%23holiday@group.v.calendar.google.com',
    'Solomon Islands': 'en.sb%23holiday@group.v.calendar.google.com',
    Somalia: 'en.so%23holiday@group.v.calendar.google.com',
    'South Africa': 'en.sa%23holiday@group.v.calendar.google.com',
    'South Sudan': 'en.ss%23holiday@group.v.calendar.google.com',
    Spain: 'en.spain%23holiday@group.v.calendar.google.com',
    'Sri Lanka': 'en.lk%23holiday@group.v.calendar.google.com',
    Sudan: 'en.sd%23holiday@group.v.calendar.google.com',
    Suriname: 'en.sr%23holiday@group.v.calendar.google.com',
    Swaziland: 'en.sz%23holiday@group.v.calendar.google.com',
    Sweden: 'en.swedish%23holiday@group.v.calendar.google.com',
    Switzerland: 'en.ch%23holiday@group.v.calendar.google.com',
    'Syrian Arab Republic': 'en.sy%23holiday@group.v.calendar.google.com',
    Taiwan: 'en.taiwan%23holiday@group.v.calendar.google.com',
    Tajikistan: 'en.tj%23holiday@group.v.calendar.google.com',
    Thailand: 'en.th%23holiday@group.v.calendar.google.com',
    'The Democratic Republic of the Congo': 'en.cd%23holiday@group.v.calendar.google.com',
    'The Former Yugoslav Republic of Macedonia': 'en.mk%23holiday@group.v.calendar.google.com',
    'Timor-Leste': 'en.tl%23holiday@group.v.calendar.google.com',
    Togo: 'en.tg%23holiday@group.v.calendar.google.com',
    Tonga: 'en.to%23holiday@group.v.calendar.google.com',
    'Trinidad and Tobago': 'en.tt%23holiday@group.v.calendar.google.com',
    Tunisia: 'en.tn%23holiday@group.v.calendar.google.com',
    Turkey: 'en.turkish%23holiday@group.v.calendar.google.com',
    Turkmenistan: 'en.tm%23holiday@group.v.calendar.google.com',
    'Turks and Caicos Islands': 'en.tc%23holiday@group.v.calendar.google.com',
    Tuvalu: 'en.tv%23holiday@group.v.calendar.google.com',
    'U.S. Virgin Islands': 'en.vi%23holiday@group.v.calendar.google.com',
    Uganda: 'en.ug%23holiday@group.v.calendar.google.com',
    Ukraine: 'en.ukrainian%23holiday@group.v.calendar.google.com',
    'United Arab Emirates': 'en.ae%23holiday@group.v.calendar.google.com',
    'United Kingdom': 'en.uk%23holiday@group.v.calendar.google.com',
    'United Republic of Tanzania': 'en.tz%23holiday@group.v.calendar.google.com',
    US: 'en.usa%23holiday@group.v.calendar.google.com',
    Uruguay: 'en.uy%23holiday@group.v.calendar.google.com',
    Uzbekistan: 'en.uz%23holiday@group.v.calendar.google.com',
    Vanuatu: 'en.vu%23holiday@group.v.calendar.google.com',
    Venezuela: 'en.ve%23holiday@group.v.calendar.google.com',
    Vietnam: 'en.vietnamese%23holiday@group.v.calendar.google.com',
    'Wallis and Futuna': 'en.wf%23holiday@group.v.calendar.google.com',
    Yemen: 'en.ye%23holiday@group.v.calendar.google.com',
    Zambia: 'en.zm%23holiday@group.v.calendar.google.com',
    Zimbabwe: 'en.zw%23holiday@group.v.calendar.google.com',
};
