import { createSlice } from '@reduxjs/toolkit';

const scheduleEventInitialState: any = {
    assetTypeId: '',
    deviceIds: [],
    isGroupSelected: false,
    actionId: '',
    loadingHierarchyNode: [],
    hierarchyMappingData: {},
};

const scheduleEventSlice = createSlice({
    name: 'scheduleEvent',
    initialState: scheduleEventInitialState,
    reducers: {
        resetScheduleEventState: () => {
            return scheduleEventInitialState;
        },
        setScheduleEventPayload: (state, action) => {
            const { key, value } = action.payload;
            if (
                value &&
                value !== '' &&
                value !== null &&
                JSON.stringify(value) !== '{}' &&
                JSON.stringify(value) !== '[]'
            ) {
                state[key] = value;
            } else {
                delete state[key];
            }
        },
    },
});

export const { setScheduleEventPayload, resetScheduleEventState } = scheduleEventSlice.actions;
export default scheduleEventSlice;
