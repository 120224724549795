import { RootState } from '../../app/store';
import { PayloadAction, current } from '@reduxjs/toolkit';
import { isNullOrUndefined } from '../../utils/helpers';

const transformHelper = (transformedData: any, originalData: any): void => {
    originalData?.forEach((event: any) => {
        event?.deviceIds?.forEach((deviceId: any) => {
            const isDeviceAlreadyPresent = transformedData?.find(
                (transformedSchedule: any) => transformedSchedule?.device?.id === deviceId?.id
            );
            if (!isDeviceAlreadyPresent) {
                transformedData.push({
                    device: {
                        id: deviceId?.id,
                        name: deviceId?.name,
                        deviceTypeId: event?.deviceTypeId?.id,
                        deviceTypeName: event?.deviceTypeId?.name,
                    },
                    schedules: originalData
                        ?.filter((schedule: any) =>
                            schedule?.deviceIds?.some((device: any) => device?.id === deviceId?.id)
                        )
                        ?.map((schedule: any) => {
                            return {
                                id: schedule?.id,
                                name: schedule?.name,
                                ...(schedule?.schedules?.length && {
                                    schedules: schedule?.schedules,
                                }),
                                ...(schedule?.actionEndTime && {
                                    actionEndTime: schedule?.actionEndTime,
                                }),
                                actionStartTime: schedule?.actionStartTime,
                                lastRun: schedule?.lastRun,
                            };
                        }),
                });
            }
        });
    });
};

const transformDailyScheduleData = (state: RootState, action: PayloadAction<any>): void => {
    const transformedData: Array<{ [key: string]: unknown }> = [];
    const originalData = action.payload.value;

    transformHelper(transformedData, originalData);
    state['dailyScheduledEvents'] = transformedData;
};

const transformSchedulesForDeviceTypes = (state: RootState, action: PayloadAction<any>): void => {
    const schedulesClone = action.payload?.value;
    const transformedData: any = [];

    schedulesClone?.forEach((schedule: any) => {
        const deviceTypeAlreadyPresentIndex = transformedData?.findIndex(
            (transformedSchedule: any) => transformedSchedule?.deviceType?.id === schedule?.deviceTypeId?.id
        );

        if (deviceTypeAlreadyPresentIndex === -1) {
            const transformedDeviceEvents: any = [];
            transformHelper(
                transformedDeviceEvents,
                schedulesClone?.filter(
                    (subSchedule: any) => subSchedule?.deviceTypeId?.id === schedule?.deviceTypeId?.id
                )
            );

            transformedData.push({
                deviceType: schedule?.deviceTypeId,
                events: transformedDeviceEvents,
            });
        } else {
            const alreadyExistedDeviceIndex = transformedData[deviceTypeAlreadyPresentIndex]?.events?.findIndex(
                (event: any) =>
                    schedule?.deviceIds?.find((device: any) => device?.id === event?.device?.id) === event?.device?.id
            );
            // const alreadyExistedEventIndex = transformedData[deviceTypeAlreadyPresentIndex]?.events?.findIndex(
            //     (event: any) => event?.schedules?.find((item: any) => item?.id === schedule?.id) === schedule?.id
            // );

            // if (alreadyExistedDeviceIndex !== -1 && alreadyExistedEventIndex === -1) {
            //     transformedData[deviceTypeAlreadyPresentIndex]?.['events']?.[alreadyExistedDeviceIndex]?.[
            //         'schedules'
            //     ]?.push({
            //         id: schedule?.id,
            //         name: schedule?.name,
            //         ...(schedule?.schedules?.length && {
            //             schedules: schedule?.schedules,
            //         }),
            //         ...(schedule?.actionEndTime && {
            //             actionEndTime: schedule?.actionEndTime,
            //         }),
            //         actionStartTime: schedule?.actionStartTime,
            //         lastRun: schedule?.lastRun,
            //     });
            // }

            if (alreadyExistedDeviceIndex === -1) {
                schedule?.deviceIds?.forEach((item: any) => {
                    if (
                        transformedData[deviceTypeAlreadyPresentIndex]?.events?.findIndex(
                            (subItem: any) => subItem?.device?.id === item?.id
                        ) === -1
                    ) {
                        transformedData[deviceTypeAlreadyPresentIndex]?.events?.push({
                            device: {
                                id: item?.id,
                                name: item?.name,
                                deviceTypeId: schedule?.deviceTypeId?.id,
                                deviceTypeName: schedule?.deviceTypeId?.name,
                            },
                            schedules: [
                                {
                                    id: schedule?.id,
                                    name: schedule?.name,
                                    ...(schedule?.schedules?.length && {
                                        schedules: schedule?.schedules,
                                    }),
                                    ...(schedule?.actionEndTime && {
                                        actionEndTime: schedule?.actionEndTime,
                                    }),
                                    actionStartTime: schedule?.actionStartTime,
                                    lastRun: schedule?.lastRun,
                                },
                            ],
                        });
                    }
                });
            }
        }
    });

    state['dailyScheduledEvents'] = transformedData;
};

const handleActionSelection = (state: RootState, action: PayloadAction<any>): void => {
    const currentClone = current(state);
    if (Array.isArray(action.payload)) {
        state['actions'] = action.payload;
    } else {
        const selectedActionIndex = currentClone?.actions?.findIndex(
            (actionEvent: any) => actionEvent?.key === action?.payload?.key
        );

        if (selectedActionIndex !== -1) {
            state?.actions?.splice(selectedActionIndex, 1);
        } else {
            state?.actions?.push(action?.payload);
        }
    }
};

const holidaysHandler = (state: any, action: PayloadAction<any>, key?: string): void => {
    const stateClone = current(state);
    if (Array.isArray(action.payload)) {
        state[key as any] = action.payload;
    } else {
        const selectedIndex = stateClone?.[key as any]?.findIndex(
            (holiday: any) =>
                holiday?.date === action.payload?.date ||
                (!isNullOrUndefined(holiday?.id) && holiday?.id === action.payload?.id)
        );
        if (selectedIndex === -1) {
            state[key as any].push(action.payload);
        } else if (!action.payload?.type) {
            state[key as any].splice(selectedIndex, 1);
        }
    }
};

export { holidaysHandler, handleActionSelection, transformDailyScheduleData, transformSchedulesForDeviceTypes };
