import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * The `PageNotFound` component redirects the user to the home page if the current path is not the root
 * path.
 * @returns The `PageNotFound` component is returning an empty JSX element (`<></>`).
 */
export const PageNotFound = (): JSX.Element => {
    const navigate = useNavigate();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const rolePermissions = useTypedSelector(selectCurrentPermission);
    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
    is used to redirect the user to the home page if the current path is not the root path ("/"). */
    useEffect(() => {
        if (location.pathname !== `/${currentRealmName}` && rolePermissions?.length) {
            navigate(`/${currentRealmName}`);
        }
    }, [location.pathname, rolePermissions]);

    return <></>;
};
