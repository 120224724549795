import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { transformDailyScheduleData, transformSchedulesForDeviceTypes } from './helpers';

const initialState = {
    listPayload: {
        page: 0,
        size: 10,
    },
    devices: [],
    isLoading: false,
    deviceTypes: [],
    selectedDeviceType: null,
    dailyScheduledEvents: [],
};

const schedulerSlice = createSlice({
    name: 'scheduler',
    initialState: initialState,
    reducers: {
        resetScheduler: () => {
            return initialState;
        },
        setListPayload: (state: RootState, action: PayloadAction<any>) => {
            state.listPayload[action.payload.key] = action.payload.value;
        },
        setScheduledEvents: (state: RootState, action: PayloadAction<any>) => {
            if (!action.payload.isGrouped) {
                transformDailyScheduleData(state, action);
            } else {
                transformSchedulesForDeviceTypes(state, action);
            }
            state.isLoading = false;
        },
        toggleScheduleLoader: (state: RootState, action: PayloadAction<any>) => {
            state.isLoading = action.payload;
        },
        setScheduleSelectedDeviceType: (state: RootState, action: PayloadAction<any>) => {
            state.selectedDeviceType = action.payload;
        },
        setScheduleDeviceTypes: (state: RootState, action: PayloadAction<any>) => {
            state.deviceTypes = action.payload;
        },
        setScheduleDevices: (state: RootState, action: PayloadAction<any>) => {
            state.devices = action.payload;
        },
    },
});

export const {
    resetScheduler,
    setListPayload,
    setScheduledEvents,
    setScheduleDevices,
    toggleScheduleLoader,
    setScheduleDeviceTypes,
    setScheduleSelectedDeviceType,
} = schedulerSlice.actions;

export default schedulerSlice;
