import React from 'react';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { Hierarchy, renderGroupHierarchyIcons } from '../../../components';
import { setChannels } from '@fiji/common/src/features/widgetManagement/detailsWidgetSlice';
import { GroupHierarchySkeleton } from '../../GroupHierarchy/GroupHierarchySkeleton';
import { setChartChannels } from '@fiji/common/src/features/widgetManagement/chartWidgetSlice';
import { setTrendsChannels } from '@fiji/common/src/features/widgetManagement/trendsWidgetSlice';
import { setGaugeChannels } from '@fiji/common/src/features/widgetManagement/gaugeWidgetSlice';
import { useChannelHierarchyHandlers } from '../common';
import { setCommandChannels } from '@fiji/common/src/features/widgetManagement/commandBarWidgetSlice';
import { COMMAND_PALETTE } from '../common/Configuration/commandPalette';
import { useSearchDeviceOrGroup } from '@fiji/common/src/hooks';

export const HierachyChannels = (props: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const widgetDetails = useTypedSelector((state) => state.widgetConfiguration);

    const {
        groupsData,
        handleCleanHierarchyNode,
        handleFetchHierarchy,
        hasChildNode,
        hierarchyMappingData,
        loadingHierarchyNode,
    } = useChannelHierarchyHandlers({
        sourceId: widgetDetails?.source?.id,
        widgetType: props?.details?.widgetType?.id,
    });

    const selectedNodes = useTypedSelector(
        (state) =>
            state[
                props?.details?.widgetType?.id === 'settings' || props?.details?.widgetType?.id === 'properties'
                    ? 'details'
                    : props?.details?.widgetType?.id
            ]
    );

    const { currentData: filteredRecords, isLoading: searchLoader } = useSearchDeviceOrGroup({
        searchKey: widgetDetails?.searchPayload,
    });

    const [controlledExpandedNodes, setControlledExpandedNodes] = React.useState<string[]>([]);

    const handleWidgetSelection = (nodePayload: any): any => {
        switch (props?.details?.widgetType?.id) {
            case 'details':
                dispatch(
                    setChannels({
                        key: 'primary',
                        channelPayload: nodePayload,
                        multiDevice: widgetDetails?.data?.multiDevice,
                    })
                );
                break;
            case 'properties':
            case 'settings':
                dispatch(
                    setChannels({
                        key: 'secondary',
                        channelPayload: nodePayload,
                        multiDevice: widgetDetails?.data?.multiDevice,
                    })
                );
                break;
            case 'charts':
                dispatch(
                    setChartChannels({
                        key: 'secondary',
                        channelPayload: nodePayload,
                        multiDevice: widgetDetails?.data?.multiDevice,
                    })
                );
                break;
            case 'trends':
                dispatch(
                    setTrendsChannels({
                        key: 'secondary',
                        channelPayload: nodePayload,
                        multiDevice: widgetDetails?.data?.multiDevice,
                    })
                );
                break;
            case 'gauge':
                dispatch(setGaugeChannels(nodePayload));
                break;

            case 'command_bar':
                dispatch(
                    setCommandChannels({
                        key: 'secondary',
                        channelPayload: nodePayload,
                        multiDevice: widgetDetails?.data?.multiDevice,
                    })
                );
                break;
            default:
                break;
        }
    };

    const handleNodeClick = (selectedNode: any, parent: any): void => {
        const nodePayload = {
            id: `${selectedNode?.channelId}${selectedNode?.id}`?.replaceAll('-', '_'),
            channelId: selectedNode.channelId,
            channelName: selectedNode.channelName,
            displayName: selectedNode.displayName,
            icon: selectedNode.icon,
            unit: selectedNode.unit,
            devicePath: parent.groupPath,
            value: selectedNode.value,
            deviceId: parent?.id,
            deviceName: parent?.name,
            ...(props?.details?.widgetType?.id === 'command_bar' && {
                paletteConfig: COMMAND_PALETTE[0],
            }),
        };

        handleWidgetSelection(nodePayload);
    };

    return (
        <>
            <Hierarchy
                labelKey={'name'}
                {...(filteredRecords &&
                    Boolean(filteredRecords.length) && {
                        data: filteredRecords,
                    })}
                {...(!filteredRecords && {
                    data: groupsData,
                })}
                {...(!filteredRecords &&
                    widgetDetails?.searchPayload?.length && {
                        data: [],
                    })}
                loadMore={handleFetchHierarchy}
                cleanHiearchyNode={handleCleanHierarchyNode}
                hierarchyMappingData={hierarchyMappingData}
                loadingNode={loadingHierarchyNode}
                handleTreeNodeSelection={handleNodeClick}
                controlledExpandedNodes={controlledExpandedNodes}
                setControlledExpandedNodes={setControlledExpandedNodes}
                selectionType={props?.details?.widgetType?.id === 'gauge' ? 'radio' : 'checkbox'} // radio, checkbox, node
                selectedNodes={
                    props?.details?.widgetType?.id === 'gauge'
                        ? `${selectedNodes?.data?.channelId}${selectedNodes?.data?.deviceId}`
                        : [...(selectedNodes?.primary ?? []), ...(selectedNodes?.secondary ?? [])]?.map(
                              (item) => `${item?.channelId}${item?.deviceId}`
                          )
                }
                isLoading={!groupsData || searchLoader}
                loadingSkeleton={<GroupHierarchySkeleton row={1} />}
                hasChild={hasChildNode}
                getAvatarChild={renderGroupHierarchyIcons}
                filters={[
                    {
                        key: 'type',
                        operator: '===',
                        value: 'channel',
                        action: 'disableCheck',
                    },
                ]}
            />
        </>
    );
};
