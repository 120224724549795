import { api } from '../../app/api/baseApi';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';

const documentsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllDocuments: builder.query<object, any>({
            query: (body) => ({
                url: `/dm/v1/device-documents/filter`,
                body: body,
                method: 'POST',
            }),
        }),
        getDocumentsByFilter: builder.mutation<object, any>({
            query: (body) => ({
                url: `/dm/v1/device-documents/filter`,
                body: body,
                method: 'POST',
            }),
        }),

        getMobileDocuments: builder.query<any, any>({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: '/dm/v1/device-documents/filter',
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
        }),
        getDocumentTypes: builder.query({
            query: (body) => ({
                url: '/dm/v1/device-documents/types',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetAllDocumentsQuery,
    useGetDocumentsByFilterMutation,
    useGetMobileDocumentsQuery,
    useGetDocumentTypesQuery,
} = documentsApi;
