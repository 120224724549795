import { Grid, Stack } from '@mui/material';
import React from 'react';
import { createNumArray } from 'utils/helpers';
import { EventGrid } from './EventGrid';
import { useTypedSelector } from '@fiji/common';

export const DeviceRowGrid = React.memo(({ data }: { data: any }): JSX.Element => {
    const isGroupingEnabled = useTypedSelector((state) => state['schedule']['isGroupingEnabled']);
    return (
        Boolean(data?.length) &&
        data?.map(({ device, schedules: events }: any) => (
            <Grid
                container
                key={device?.id}
                sx={{ position: 'relative' }}
                className={isGroupingEnabled ? 'bg-light-blue' : ''}
            >
                {createNumArray(24)?.map((_, index: any) => (
                    <Grid item xs={0.5} key={index}>
                        <Stack
                            style={{
                                height: 40,
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 0,
                                position: 'relative',
                            }}
                            className={`border-right-1 ${isGroupingEnabled ? '' : 'border-bottom-1'}`}
                        />
                    </Grid>
                ))}
                {events?.length && events?.map((schedule: any) => <EventGrid key={schedule?.id} event={schedule} />)}
            </Grid>
        ))
    );
});
