import React from 'react';

import { setPayload } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';

export const DataSourceList = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const payload = useTypedSelector((state) => state.widget.payload);
    const deviceTypes = useTypedSelector((state) => state.widget.deviceTypes);

    return (
        <Stack className="height-266 overflow-y-scroll">
            <RadioGroup
                id="dataSourceRadio"
                value={payload?.sourceId ?? ''}
                onChange={(e: any): any => {
                    dispatch(setPayload({ key: 'sourceId', value: e.target.value }));
                }}
            >
                {Boolean(deviceTypes?.length) &&
                    deviceTypes?.map((source: any) => (
                        <FormControlLabel
                            key={source?.id}
                            sx={{ padding: '10px' }}
                            className="margin-0"
                            disabled={payload?.config?.multiDevice}
                            value={source?.id}
                            control={
                                <Radio
                                    onChange={(): void => {
                                        dispatch(setPayload({ key: 'sourceName', value: source.name }));
                                    }}
                                />
                            }
                            label={source?.name}
                        />
                    ))}
            </RadioGroup>
        </Stack>
    );
};
