import React from 'react';

import clsx from 'clsx';
import { Dayjs } from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers-pro';
import { useTypedSelector } from '@fiji/common';
import { isPresentInSameWeek } from '../common/utils/helpers';

type CustomPickerProps = Omit<PickersDayProps<Dayjs>, 'selectedDay' | 'hoveredDay'> & {
    selectedDay: Dayjs | null;
};

export const CustomDayPicker = ({ selectedDay, day, today, ...props }: CustomPickerProps): JSX.Element => {
    const theme: any = useTheme();
    const hoveredDay = useTypedSelector((state: any) => state['schedule']['hoveredDay']);

    return (
        <PickersDay
            {...props}
            today={today}
            className={clsx({})}
            day={day}
            sx={{
                px: 2.5,
                borderRadius: 0,
                ...(isPresentInSameWeek(day, selectedDay) && {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover, &:focus': {
                        backgroundColor: theme.palette.primary.main,
                    },
                }),
                ...(isPresentInSameWeek(day, hoveredDay) && {
                    backgroundColor: theme.palette.primary[theme.palette.mode],
                    '&:hover, &:focus': {
                        backgroundColor: theme.palette.primary[theme.palette.mode],
                    },
                }),

                ...(today &&
                    !isPresentInSameWeek(day, hoveredDay) &&
                    !isPresentInSameWeek(day, selectedDay) && {
                        borderRadius: '50%',
                        height: '38px',
                    }),
                ...(day.day() === 0 &&
                    (isPresentInSameWeek(day, hoveredDay) || isPresentInSameWeek(day, selectedDay)) && {
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                    }),
                ...(day.day() === 6 &&
                    (isPresentInSameWeek(day, hoveredDay) || isPresentInSameWeek(day, selectedDay)) && {
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '50%',
                    }),
            }}
            disableMargin
            selected={false}
        />
    );
};
