import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupHierarchy } from '../../types/GroupHierarchy';
import { AddGroupPayload, GroupModal } from '../../types';
import { RootState } from '../../app/store';

const initialGroupsState = <any>[];
const initialGroupModalState: GroupModal = {
    isOpen: false,
};

type GroupDeviceModalType = {
    isOpen?: boolean;
    groupIds?: any;
    deviceIds?: any;
    destinationGroupId?: string;
    deviceOnly?: boolean;
};

const initialGroupsDevicesModalState: GroupDeviceModalType = {
    isOpen: false,
    groupIds: [],
    deviceIds: [],
    destinationGroupId: '',
    deviceOnly: false,
};

const initialAddGroupPayload: AddGroupPayload = {
    groupId: undefined,
    groupName: undefined,
    groupIcon: undefined,
};

const groupSlice = createSlice({
    name: 'group',
    initialState: {
        addGroupModal: initialGroupModalState,
        deleteGroupModal: initialGroupModalState,
        groupHierarchy: initialGroupsState,
        moveGroupsDevicesModal: initialGroupsDevicesModalState,
        addGroupPayload: initialAddGroupPayload,
        serviceContactIds: [],
        groupCoordinates: { lat: null, lng: null },
        refetchGroupHierarchy: {},
    },
    reducers: {
        addGroupModal: (state, { payload }: PayloadAction<GroupModal>) => {
            state.addGroupModal = payload;
        },
        deleteGroupModal: (state, { payload }: PayloadAction<GroupModal>) => {
            state.deleteGroupModal = payload;
        },
        setGroupHierarchy(state, action: PayloadAction<GroupHierarchy>) {
            const groupHierarchy = action.payload;
            state.groupHierarchy = groupHierarchy;
        },
        moveGroupsDevicesModal: (state, { payload }: PayloadAction<GroupDeviceModalType>) => {
            state.moveGroupsDevicesModal = payload;
        },
        setAddGroupPayload: (state, { payload }: PayloadAction<AddGroupPayload>) => {
            state.addGroupPayload = payload;
        },
        setServiceContactIds: (state, { payload }: PayloadAction<any>) => {
            state.serviceContactIds = payload;
        },
        setGroupCoordinates: (state, { payload }: PayloadAction<any>) => {
            state.groupCoordinates = payload;
        },
        setRefetchGroupHierarchy: (state, { payload }: PayloadAction<any>) => {
            state.refetchGroupHierarchy = payload;
        },
    },
});

export const {
    setGroupHierarchy,
    addGroupModal,
    deleteGroupModal,
    moveGroupsDevicesModal,
    setAddGroupPayload,
    setServiceContactIds,
    setGroupCoordinates,
    setRefetchGroupHierarchy,
} = groupSlice.actions;
export const selectAddGroupModal = (state: RootState): GroupModal => state.group.addGroupModal;
export const selectDeleteGroupModal = (state: RootState): GroupModal => state.group.deleteGroupModal;
export const selectCurrentGroupHierarchy = (state: any): any => state.group.groupHierarchy;
export const selectAddGroupPayload = (state: any): any => state.group.addGroupPayload;
export const selectServiceContactIds = (state: any): any => state.group.serviceContactIds;
export const selectMoveGroupsDevicesModal = (state: RootState): GroupDeviceModalType =>
    state.group.moveGroupsDevicesModal;
export const selectRefetchGroupHierarchy = (state: any): any => state.group.refetchGroupHierarchy;
export default groupSlice;
