import { api } from '../../app/api/baseApi';

export const widgetApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllWidgetTypes: builder.query<any, any>({
            query: ({ headers }: any) => ({
                url: `ds/v1/widgets/types`,
                method: 'POST',
                body: {
                    page: 0,
                    size: 100,
                },
                ...(headers && { headers }),
            }),
        }),
        getAllCategories: builder.query<object, void>({
            query: () => ({
                url: `ds/v1/widgets/categories`,
            }),
            transformResponse: (response: any) => response?.data?.map((item: any) => ({ id: item, label: item })),
        }),
        getAllWidgets: builder.query<object, any>({
            query: ({ body, headers }: { body: any; headers?: any }) => ({
                url: 'ds/v1/widgets/filter',
                method: 'POST',
                body,
                ...(headers && {
                    headers,
                }),
            }),
            providesTags: ['Widgets'],
        }),
        createWidget: builder.mutation({
            query: (body) => ({
                url: 'ds/v1/widgets',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Widgets'] : []),
        }),
        updateWidget: builder.mutation({
            query: ({ body, widgetId }: any) => ({
                url: `ds/v1/widgets/${widgetId}`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Widgets', 'WidgetById'] : []),
        }),
        getWidgetById: builder.query<object, any>({
            query: (widgetId) => ({
                url: `ds/v1/widgets/${widgetId}`,
            }),
            keepUnusedDataFor: 0,
            providesTags: ['WidgetById'],
        }),
        getDashboardWidgetById: builder.query<object, any>({
            query: (widgetId) => ({
                url: `ds/v1/widgets/${widgetId}`,
            }),
        }),
        deleteWidget: builder.mutation({
            query: (body) => ({
                url: `ds/v1/widgets/${body}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Widgets'] : []),
        }),
        getScore: builder.query<any, any>({
            query: (params) => ({
                url: `ds/v1/demo`,
                params,
            }),
        }),
    }),
    overrideExisting: true,
});

export const {
    useCreateWidgetMutation,
    useGetAllWidgetsQuery,
    useGetAllWidgetTypesQuery,
    useGetAllCategoriesQuery,
    useUpdateWidgetMutation,
    useLazyGetDashboardWidgetByIdQuery,
    useGetWidgetByIdQuery,
    useDeleteWidgetMutation,
} = widgetApi;
