import React, { memo } from 'react';
import {
    MenuItem,
    TableFooter,
    TablePagination,
    IconButton,
    Typography,
    Paper,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableCell,
    Checkbox,
    InputAdornment,
    FilledInput,
    FormControl,
    TableSortLabel,
    Stack,
    TableBody,
    Button,
    Box,
    Radio,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StatusStripe } from './StatusStripe';
import { Header } from '@fiji/common/src/types';
import { TableSkeleton } from './TableSkeleton';
import Add from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { useIsMount, usePagination, useSelectedIds } from '../hooks';
import { TablePaginationActions } from './TablePaginationActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from '@fiji/common/src/utils/helpers';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CustomTransComponent } from './CustomTransComponent';
import { CustomEmptyState } from './CustomEmptyState';

/**
 * The above type defines the props for a custom table component in TypeScript React, including options
 * for success/error states, pagination, loading state, data and headers, and various customization
 * options.
 * @property {string} wrapperClass - The `wrapperClass` property is a string that represents the CSS
 * class name(s) to be applied to the wrapper element of the custom table component.
 * @property {string} containerClass - A string representing the CSS class name for the container
 * element of the table.
 * @property {boolean} isPagination - A boolean value indicating whether pagination should be enabled
 * for the table.
 * @property {any[]} data - An array of data that will be displayed in the table.
 * @property {any[]} headers - An array of objects representing the headers of the table. Each object
 * should have properties like "label" (string) for the header label and "key" (string) for the
 * corresponding key in the data array.
 * @property {string} keyToTraverse - The `keyToTraverse` property is a string that represents the key
 * in the `data` array that should be used to render the table rows. This key is used to access the
 * corresponding value in each object within the `data` array.
 * @property {boolean} isLoading - A boolean value indicating whether the data is currently being
 * loaded or not.
 * @property noDataFoundIcon - The `noDataFoundIcon` property is an optional JSX element that
 * represents the icon to be displayed when there is no data found in the table.
 * @property {string} noDataFoundTitle - The `noDataFoundTitle` property is a string that represents
 * the title or heading to be displayed when there is no data available for the table.
 * @property {string} noDataFoundButtonText - The `noDataFoundButtonText` property is an optional
 * string that represents the text to be displayed on the button when no data is found in the table.
 * @property noDataFoundButtonAction - The `noDataFoundButtonAction` property is a function that will
 * be called when the button in the "no data found" section is clicked. It is an optional property and
 * can be used to define a custom action to be performed when the button is clicked.
 * @property handleCheckboxSelect - A function that is called when checkboxes in the table are
 * @property  handleRadioSelect - A function that is called when radio in the table are
 * selected. It takes an array of strings as an argument, which represents the selected checkboxes.
 * @property handleFilterChange - A function that is called when a filter is changed in the table. It
 * takes four arguments: arg0 (the filter value), arg1 (the filter type), arg3 (the filter column), and
 * arg4 (the filter operator).
 * @property {number} total - The `total` property is a number that represents the total number of
 * items in the data set.
 * @property {string | JSX.Element} noDataFoundDescription - The `noDataFoundDescription` property is a
 * string or JSX element that represents the description or additional information to be displayed when
 * there is no data found in the table. It can be used to provide context or instructions to the user
 * when there are no records available to display.
 * @property {boolean} isWhiteRows - A boolean value indicating whether table row should be white.
 */

type CustomTableProps = {
    controlledPageSize?: any;
    wrapperClass?: string;
    tableHead?: boolean;
    containerClass?: string;
    isPagination?: boolean;
    data: any[];
    disableRow?: any;
    headers: any[];
    keyToTraverse: string;
    isLoading: boolean;
    noDataFoundIcon?: JSX.Element;
    noDataFoundTitle?: any;
    noDataFoundButtonText?: any;
    noDataFoundButtonAction?: () => void;
    handleRadioSelect?: (arg0: string) => void;
    handleCheckboxSelect?: (arg0: string[], arg1?: any[], arg2?: any) => void;
    handleFilterChange?: (arg0: any, arg1: any) => void;
    handlePageChange?: (arg0: number, arg1: number) => void;
    total: number;
    noDataFoundDescription?: string | JSX.Element;
    isWhiteRows?: boolean;
    isMoreMenu?: boolean;
    pageHeight?: number;
    containerHeight?: number;
    translationloader?: boolean;
    checkedOnes?: string[];
    disableCheckbox?: boolean;
    getSelectedRow?: any;
};

export const CustomTable = memo(
    React.forwardRef((props: CustomTableProps, ref) => {
        const theme: any = useTheme();
        const [filteredValues, setFilteredValues] = React.useState<any>({});
        const [sortedData, setSortedData] = React.useState<any>({});
        const [openFilterDropDown, setOpenFilterDropDown] = React.useState<any>({});
        const [pageSize, setPageSize] = React.useState<number>(10);
        const [selectedValue, setSelectedValue] = React.useState('');
        /* The below code is using the `usePagination` hook to manage pagination functionality in a
    TypeScript React component. */
        const {
            currentPage,
            goToLastPage,
            goToNextPage,
            goToFirstPage,
            goToPrevPage,
            setPage,
            totalPages,
            setCurrentPage,
        } = usePagination({
            pageSize: pageSize,
            totalItems: props?.total,
        });

        const [selectedIds, setSelectedIds, selectionHandler, selectedData, setSelectedData] = useSelectedIds();
        const isMount = useIsMount();
        const { t } = useTranslation();

        /* The below code is using the `useImperativeHandle` hook from React to expose certain functions to
    the parent component through a ref. */
        React.useImperativeHandle(ref, () => ({
            resetCheckboxes: (): void => setSelectedIds([]),
            setDefaultIds: (ids: any): void => setSelectedIds(ids),
            resetFilters: (key: string, type?: any): void => resetFilters(key, type),
            setSelectedData: (data: any): void => setSelectedData(data),
            dropDownHandler: (key: string, type?: any): void => dropDownHandler(key, type),
        }));

        /* The below code is a React useEffect hook that is triggered when the component mounts. It checks
    if the `props.headers` array exists and has a length greater than 0. If it does, it iterates
    over each `header` object in the array. If the `header` object has a property `isFilterable`
    that is truthy, it sets the state of `openFilterDropDown` using the `setOpenFilterDropDown`
    function. The state is updated by spreading the previous state (`prev`) and setting the value of
    the `header.accessor` property to `false`. */
        React.useEffect(() => {
            if (props?.headers?.length) {
                props.headers.forEach((header: Header) => {
                    if (header?.isFilterable) {
                        setOpenFilterDropDown((prev: any) =>
                            header.accessor ? { ...prev, [header?.accessor]: false } : prev
                        );
                    }
                });
            }
        }, []);

        const handleChange = React.useCallback(
            (id: string) =>
                (e: React.ChangeEvent<HTMLInputElement>): void => {
                    const selectedIdsClone = JSON.parse(JSON.stringify({ ids: selectedIds }));
                    const uncheckedIndex = selectedIdsClone.ids.indexOf(id);

                    if (e.target.checked) {
                        selectedIdsClone.ids.push(id);
                    }
                    if (!e.target.checked) {
                        selectedIdsClone.ids.splice(uncheckedIndex, 1);
                    }
                    setSelectedValue(selectedIdsClone.ids);
                },
            [selectedIds]
        );
        /* The below code is using the `useEffect` hook from React to execute a function whenever the
    `selectedIds` variable changes. The function being executed is
    `props.handleCheckboxSelect(selectedIds)`, which is passed as a prop to the component. This code
    is likely used to handle the selection of checkboxes and update the selectedIds state in the
    parent component. */
        React.useEffect(() => {
            if (props.handleCheckboxSelect) props.handleCheckboxSelect(selectedIds, selectedData, selectionHandler);
        }, [selectedIds]);
        React.useEffect(() => {
            if (props.handleRadioSelect) props.handleRadioSelect(selectedValue);
        }, [selectedValue]);
        React.useEffect(() => {
            if (props?.checkedOnes?.length) setSelectedIds(props?.checkedOnes);
        }, [props?.checkedOnes]);
        /* The below code is using the `useEffect` hook from React to execute a function whenever the
    dependencies (`filteredValues`, `currentPage`, `pageSize`, `sortedData`) change. */
        React.useEffect(() => {
            if ((filteredValues || sortedData) && props.handleFilterChange) {
                props.handleFilterChange(filteredValues, sortedData);
            }
        }, [filteredValues, sortedData]);

        React.useEffect(() => {
            if (!isMount && (currentPage || pageSize) && props.handlePageChange) {
                props.handlePageChange(currentPage, pageSize);
            }
        }, [currentPage, pageSize]);

        React.useEffect(() => {
            if (!isMount && props.controlledPageSize !== currentPage) {
                setCurrentPage(props.controlledPageSize);
            }
        }, [props.controlledPageSize]);

        /**
         * The handleClickAway function sets all values in the openFilterDropDown state to false.
         */
        const handleClickAway = (): void => {
            const newFilterDropDown: any = JSON.parse(JSON.stringify(openFilterDropDown));
            for (const key in newFilterDropDown) {
                newFilterDropDown[key] = false;
            }
            setOpenFilterDropDown(newFilterDropDown);
        };

        const containerRef: any = React.useRef(null);

        /* The below code is implementing a click outside functionality in a React component using the
        useEffect hook. It adds an event listener to the document that listens for click events. When a
        click event occurs, it checks if the clicked element is outside the containerRef.current
        element. If it is outside, it calls the handleClickAway function. The event listener is removed
        when the component is unmounted. */
        React.useEffect(() => {
            const handleClick = (event: any): void => {
                if (containerRef.current && !containerRef.current.contains(event.target)) {
                    handleClickAway();
                }
            };
            document.addEventListener('click', handleClick);
            return (): void => {
                document.removeEventListener('click', handleClick);
            };
        }, []);

        /**
         * The function `filterPayloadHandler` handles filtering of values based on an ID and value, and
         * updates the filtered values accordingly.
         * @param {any} id - The `id` parameter is used to identify the specific filter that is being
         * applied. It could be a unique identifier or a key that represents a specific filter option.
         * @param {any} value - The `value` parameter is the value that is being filtered. It is used to
         * determine whether to reset the filters or set the filtered values.
         */
        const filterPayloadHandler = (id: any, value: any): void => {
            if (id !== '') {
                if (value === '') {
                    resetFilters(id);
                } else {
                    setFilteredValues((prev: any) => ({ ...prev, [id]: value }));
                }
            }
        };

        /**
         * The function `dropDownHandler` toggles the state of a dropdown menu based on a given key and
         * type.
         * @param {string | undefined} key - The `key` parameter is a string or undefined. It is used to
         * identify the specific dropdown that needs to be toggled or opened.
         * @param {string} [type] - The `type` parameter is an optional string that specifies the action to
         * be performed on the dropdown. If the `type` is set to `'remove'`, it will call the
         * `resetFilters` function with the `key` as an argument.
         */
        const dropDownHandler = (key: string | undefined, type?: string): void => {
            if (key) {
                if (Object.keys(openFilterDropDown).includes(key)) {
                    if (type && type === 'remove') {
                        resetFilters(key);
                    }
                    if (type === 'close') {
                        setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: false }));
                        return;
                    }
                    setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: !prev[key] }));
                } else setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: true }));
            }
        };

        /**
         * The function `handleSelectAll` is used to handle the selection of all items in a list.
         * @param {any} e - The parameter `e` is an event object that is passed to the `handleSelectAll`
         * function. It is typically an event object that is triggered when a checkbox is checked or
         * unchecked.
         */
        const handleSelectAll = (e: any): void => {
            let IdsArr: string[] = [];
            if (e.target.checked) {
                if (props?.getSelectedRow) props?.getSelectedRow(props?.data, true);
                props?.data?.forEach((subData: any) => {
                    IdsArr.push(subData?.[props?.keyToTraverse]);
                });
                setSelectedData(props?.data);
            } else {
                IdsArr = [];
                props?.data?.forEach((subData: any) => {
                    if (props?.getSelectedRow) props?.getSelectedRow(subData, false);
                });
                setSelectedData([]);
            }
            setSelectedIds(IdsArr);
        };

        /**
         * The function `handleSorting` is used to toggle between ascending and descending sorting based on
         * a given key.
         * @param {any} key - The `key` parameter is used to specify the property or key by which the data
         * should be sorted.
         */
        const handleSorting = (key: any): void => {
            let str = 'ASC';
            if (key === sortedData?.key) {
                str = 'DESC';
                if (sortedData?.sortType === 'DESC') {
                    str = 'ASC';
                }
            }

            setSortedData((prev: any) => ({ ...prev, key: key, sortType: str }));
        };

        /**
         * The function `handleSetFilteredValues` updates the `filteredValues` state by removing a specific
         * value from a specific filter, and also removes the filter if it becomes empty or if the value is
         * 'all'.
         * @param {string} id - The `id` parameter is a string that represents the identifier of a filter.
         * @param {any} value - The `value` parameter is the value that needs to be removed from the
         * `filteredValues` object.
         */
        const handleSetFilteredValues = (id: string, value: any): void => {
            const filtersClone = JSON.parse(JSON.stringify(filteredValues));
            if (filtersClone?.[id]) {
                const removableIndex = filtersClone[id]?.indexOf(value);
                filtersClone[id].splice(removableIndex, 1);
            }
            if ((filtersClone && !filtersClone[id].length) || value === 'all') {
                delete filtersClone[id];
            }
            setFilteredValues(filtersClone);
        };

        /**
         * The function handles the change event of a checkbox and updates the filtered values based on the
         * checkbox's state.
         * @param e - The `e` parameter is of type `React.ChangeEvent<HTMLInputElement>`, which represents
         * the event object generated when the checkbox is changed. It contains information about the
         * checkbox element and its new state.
         * @param {any} id - The `id` parameter is used to identify the checkbox element. It is typically a
         * unique identifier or key associated with the checkbox.
         * @param {any} value - The `value` parameter represents the value of the checkbox that was
         * clicked.
         * @param {any} [allValues] - The `allValues` parameter is an optional parameter that represents an
         * array of all possible values for the checkbox group.
         */
        const checkboxPayloadHandler = (
            e: React.ChangeEvent<HTMLInputElement>,
            id: any,
            value: any,
            allValues?: any
        ): void => {
            if (!e.target.checked) {
                handleSetFilteredValues(id, value);
            } else if (value === 'all') {
                setFilteredValues((prev: any) => ({ ...prev, [id]: allValues?.filter((val: any) => val !== 'all') }));
            } else {
                setFilteredValues((prev: any) => ({
                    ...prev,
                    [id]: prev[id] ? prev[id]?.concat([value]) : [value],
                }));
            }
        };

        /**
         * The function checks if a specific value is already checked in a filtered list.
         * @param {string} id - The `id` parameter is a string that represents the identifier of an item.
         * @param {any} accessor - The `accessor` parameter is a variable that represents the property or
         * key of an object. It is used to access a specific value within the object.
         * @param {any} value - The `value` parameter is the total number of items that should be checked.
         * @returns a boolean value. It returns `true` if the conditions inside the if statements are met,
         * and `false` otherwise.
         */
        const alreadyCheckedHandler = (id: string, accessor: any, value: any): any => {
            if (filteredValues && accessor) {
                if (id === 'all') {
                    if (filteredValues[accessor]?.length === value - 1) return true;
                }
                if (filteredValues?.[accessor]?.includes(id)) return true;
                return false;
            }
        };

        /**
         * The function `resetFilters` is used to remove filters from a set of filtered values in a React
         * application.
         * @param {string} key - The key parameter is a string that represents the key of the filter to be
         * reset.
         * @param {any} [type] - The `type` parameter is an optional parameter of type `any`.
         */
        const resetFilters = (key: string, type?: any): void => {
            const filtersClone = JSON.parse(JSON.stringify(filteredValues));
            if (type) {
                setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: false }));
            }
            if (key === 'globalSearch') {
                type.forEach((filter: any) => {
                    delete filtersClone[filter];
                    setOpenFilterDropDown((prev: any) => ({ ...prev, [filter]: false }));
                });
            } else {
                delete filtersClone[key];
            }
            setFilteredValues(filtersClone);
        };

        /**
         * The function handleChangeRowsPerPage updates the page size based on the value selected by the
         * user.
         * @param {any} e - The parameter "e" is an event object that is passed to the function when the
         * event occurs. In this case, it is likely an event object that is triggered when the user changes
         * the value of a dropdown or input field.
         */
        const handleChangeRowsPerPage = (e: any): void => {
            setPageSize(parseInt(e.target.value));
            setCurrentPage(0);
        };
        // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setSelectedValue(event.target.value);
        // };

        /* The below code is defining a function called `getTableBodyCellContent` that takes two
    parameters: `header` and `dataObj`. */
        const getTableBodyCellContent = (header: any, dataObj: any, rowIndex: any): JSX.Element => {
            if (typeof header?.isSelectable !== 'boolean' && !isNullOrUndefined(header?.isSelectable)) {
                header?.isSelectable(dataObj);
                if (header?.isSelectable(dataObj) === true) {
                    return (
                        <Checkbox
                            id="check"
                            color="primary"
                            checked={selectedIds.includes(dataObj?.[props?.keyToTraverse])}
                            onChange={selectionHandler(dataObj?.[props?.keyToTraverse])}
                        />
                    );
                }
                return <></>;
            } else if (typeof header?.isSelectable === 'boolean' && header?.isSelectable) {
                return (
                    <Checkbox
                        id="check"
                        disabled={
                            props?.disableRow
                                ? props?.disableCheckbox || props?.disableRow(dataObj)
                                : props?.disableCheckbox
                        }
                        color="primary"
                        checked={selectedIds?.includes(dataObj?.[props?.keyToTraverse])}
                        onChange={(e) => {
                            selectionHandler(dataObj?.[props?.keyToTraverse], dataObj)(e);
                            if (props?.getSelectedRow) props?.getSelectedRow(dataObj);
                        }}
                    />
                );
            } else if (typeof header?.isRadio !== 'boolean' && !isNullOrUndefined(header?.isRadio)) {
                header?.isRadio(dataObj);
                if (header?.isRadio(dataObj) === true) {
                    return (
                        <Radio
                            name="function"
                            color="primary"
                            disabled={dataObj?.remainingQuantity === 0}
                            checked={selectedIds.includes(dataObj?.[props?.keyToTraverse])}
                            onChange={selectionHandler(dataObj?.[props?.keyToTraverse])}
                        />
                    );
                }
                return <></>;
            } else if (typeof header?.isRadio === 'boolean' && header?.isRadio) {
                return (
                    <Radio
                        name="radio-buttons"
                        color="primary"
                        disabled={dataObj?.remainingQuantity === 0}
                        checked={selectedValue.includes(dataObj?.[props?.keyToTraverse])}
                        onChange={handleChange(dataObj?.[props?.keyToTraverse])}
                    />
                );
            } else if (header?.cell) {
                return header?.cell(dataObj, rowIndex);
            }
            return (
                <Typography variant={header?.typeVariant ?? 'body1'}>
                    {dataObj?.[header?.accessor || ''] ?? 'N/A'}
                </Typography>
            );
        };

        /**
         * The function `getTableBodyCellClass` returns the class name for a table body cell based on the
         * provided header and data.
         * @param {Header} header - The `header` parameter is an object that represents the header of a
         * table column. It contains information about the column, such as its title and cell class.
         * @param {any} data - The `data` parameter is of type `any`, which means it can accept any data
         * type. It is used as an argument in the `header.cellClass` function, if it exists.
         * @returns The function `getTableBodyCellClass` returns a string.
         */
        const getTableBodyCellClass = (header: Header, data: any): string => {
            if (header?.cellClass) {
                if (typeof header.cellClass === 'string') {
                    return header.cellClass;
                }
                return header?.cellClass(data);
            }
            return 'tableBodyCell';
        };

        const getTablePaginationAction = (): JSX.Element => (
            <TablePaginationActions
                totalPages={totalPages}
                page={currentPage}
                goToLastPage={goToLastPage}
                goToNextPage={goToNextPage}
                goToFirstPage={goToFirstPage}
                goToPrevPage={goToPrevPage}
            />
        );

        /* The below code is a TypeScript React function called `getHeaderFilterBody`. It takes a `header`
    parameter of type `any` and returns a JSX element. */
        const getHeaderFilterBody = (header: any): JSX.Element => {
            if (header?.filterOptions && header?.filterOptions?.length) {
                return header?.filterOptions?.map((option: any) => (
                    <Box key={option?.id} className="w-100">
                        {option?.id === 'all' ? (
                            <>
                                <Box className="padding-right-16 border-bottom-1 w-100">
                                    <MenuItem value={option?.id} className="w-100">
                                        <Checkbox
                                            className="margin-right-16"
                                            id="check"
                                            checked={alreadyCheckedHandler(
                                                option?.id,
                                                header?.accessor,
                                                header?.filterOptions?.length
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                checkboxPayloadHandler(
                                                    e,
                                                    header?.accessor,
                                                    option?.id,
                                                    header?.filterOptions?.map((opt: any) => opt?.id)
                                                )
                                            }
                                        />
                                        {option?.label}
                                    </MenuItem>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box className="padding-right-16">
                                    <MenuItem value={option?.id} key={option?.id}>
                                        <Checkbox
                                            className="margin-right-16"
                                            id="check"
                                            checked={alreadyCheckedHandler(
                                                option?.id,
                                                header?.accessor,
                                                header?.filterOptions?.length
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                checkboxPayloadHandler(
                                                    e,
                                                    header?.accessor,
                                                    option?.id,
                                                    header?.filterOptions?.map((opt: any) => opt.id)
                                                )
                                            }
                                        />
                                        {option?.label}
                                    </MenuItem>
                                </Box>
                            </>
                        )}
                    </Box>
                ));
            } else if (header?.headerOptions) {
                return header?.headerOptions();
            }
            return (
                <Paper className="padding-16">
                    <FilledInput
                        size="small"
                        placeholder="Search"
                        className="bg-transparent border-1 border-bottom-none"
                        id={header?.accessor}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            filterPayloadHandler(e.target.id, e.target.value)
                        }
                        value={filteredValues[header?.accessor] ?? ''}
                        fullWidth
                        hiddenLabel={true}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={(): void => dropDownHandler(header?.accessor, 'remove')}
                                >
                                    {filteredValues[header?.accessor] ? <HighlightOffIcon /> : <SearchIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Paper>
            );
        };

        return (
            <>
                <TableContainer
                    className={` ${props.wrapperClass ?? ''}`}
                    sx={{
                        height: props.pageHeight ?? `calc(100vh - ${props.containerHeight ?? 224}px)`,
                    }}
                >
                    <Table stickyHeader aria-label="sticky table" className={props.containerClass ?? ''}>
                        {props.tableHead !== false && (
                            <TableHead>
                                {}
                                <TableRow key="head#row" ref={containerRef}>
                                    {props.headers?.map((header: Header, index: number) => (
                                        <TableCell
                                            key={header?.header}
                                            className="tableHeadCell padding-y-16"
                                            width={header?.width}
                                        >
                                            {header?.isSelectable ? (
                                                <Checkbox
                                                    id="check"
                                                    color="primary"
                                                    inputProps={{
                                                        'aria-label': 'select all desserts',
                                                    }}
                                                    checked={
                                                        Boolean(selectedIds?.length) &&
                                                        selectedIds?.length === props?.data?.length
                                                    }
                                                    indeterminate={
                                                        Boolean(selectedIds?.length) &&
                                                        selectedIds.length < props?.data?.length
                                                    }
                                                    onChange={handleSelectAll}
                                                />
                                            ) : (
                                                <Stack
                                                    direction="row"
                                                    {...(header?.align && { justifyContent: header?.align })}
                                                >
                                                    {header?.header}
                                                    {header?.extraOptions && header?.extraOptions()}
                                                    {header?.isSortable && (
                                                        <TableSortLabel
                                                            direction={
                                                                sortedData?.key === header?.accessor ||
                                                                sortedData?.key === header?.sortKey
                                                                    ? sortedData?.sortType?.toLowerCase()
                                                                    : 'asc'
                                                            }
                                                            active={
                                                                sortedData?.key === header?.accessor ||
                                                                Boolean(
                                                                    header?.sortKey &&
                                                                        sortedData?.key === header?.sortKey
                                                                )
                                                            }
                                                            onClick={(): void =>
                                                                handleSorting(header?.sortKey ?? header?.accessor)
                                                            }
                                                        >
                                                            <Box component="span"></Box>
                                                        </TableSortLabel>
                                                    )}

                                                    {header?.isFilterable && (
                                                        <>
                                                            <ArrowDropDown
                                                                onClick={(): void => dropDownHandler(header?.accessor)}
                                                            />
                                                            {header?.accessor &&
                                                                openFilterDropDown?.[header?.accessor] && (
                                                                    <Paper
                                                                        className="table-search-bar min-w-40 max-height-360 overflow-y-scroll"
                                                                        sx={{
                                                                            width: '100%',
                                                                            boxShadow:
                                                                                '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                                                                        }}
                                                                    >
                                                                        <FormControl
                                                                            variant="filled"
                                                                            className={header?.wrapperClass}
                                                                            size="small"
                                                                            fullWidth
                                                                            defaultValue="Small"
                                                                            {...((header?.filterOptions ||
                                                                                header?.headerOptions) && {
                                                                                sx: {
                                                                                    ...(header?.headerSx &&
                                                                                        header?.headerSx()),
                                                                                    padding: '0 !important',
                                                                                },
                                                                            })}
                                                                        >
                                                                            {getHeaderFilterBody(header)}
                                                                        </FormControl>
                                                                    </Paper>
                                                                )}
                                                        </>
                                                    )}
                                                    {props.isMoreMenu === true &&
                                                        index === props.headers.length - 1 && (
                                                            <Box
                                                                style={{
                                                                    flexGrow: 1,
                                                                    display: 'flex',
                                                                    justifyContent: 'flex-end',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <MoreVertIcon style={{ color: '#7B8387' }} />
                                                            </Box>
                                                        )}
                                                </Stack>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                        )}

                        {props.isLoading && <TableSkeleton headers={props.headers} />}

                        {!props?.isLoading && Boolean(props?.data?.length) && (
                            <TableBody>
                                {props?.data?.map((dataObj: any, rowIndex: any) => (
                                    <TableRow
                                        className="position-relative"
                                        key={dataObj?.[props?.keyToTraverse]}
                                        style={{ backgroundColor: props.isWhiteRows ? '#FFFFFF' : '' }}
                                    >
                                        {props.headers?.map(
                                            (header: Header): JSX.Element => (
                                                <>
                                                    <TableCell
                                                        {...(header?.sx && {
                                                            sx: { ':before': header?.sx(dataObj) },
                                                        })}
                                                        key={`unique${dataObj?.[props?.keyToTraverse]}`}
                                                        className={getTableBodyCellClass(header, dataObj)}
                                                        width={header?.width}
                                                    >
                                                        {dataObj?.['statusColor'] && (
                                                            <StatusStripe statusColor={dataObj?.['statusColor']} />
                                                        )}
                                                        {getTableBodyCellContent(header, dataObj, rowIndex)}
                                                    </TableCell>
                                                </>
                                            )
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                        {(!props?.data?.length || !props?.data) && !props.isLoading && (
                            <TableBody>
                                <TableRow key="bodyRowq131" sx={{ '&.MuiTableRow-root': { borderBottom: 'none' } }}>
                                    <TableCell colSpan={10} sx={{ '&.MuiTableCell-root': { borderBottom: 'none' } }}>
                                        <CustomEmptyState
                                            sx={{ height: 'calc(100vh - 426px) !important' }}
                                            icon={props?.noDataFoundIcon}
                                            title={props?.noDataFoundTitle}
                                            {...(props?.noDataFoundDescription && {
                                                description: props.noDataFoundDescription,
                                            })}
                                            actions={
                                                props?.noDataFoundButtonAction && (
                                                    <Button
                                                        id="add"
                                                        sx={{
                                                            backgroundColor: theme?.palette?.primary?.main,
                                                            '&:hover': {
                                                                backgroundColor: theme?.palette?.primary?.main,
                                                            },
                                                        }}
                                                        variant={'contained'}
                                                        startIcon={<Add />}
                                                        onClick={props?.noDataFoundButtonAction}
                                                    >
                                                        {props?.noDataFoundButtonText ?? ''}
                                                    </Button>
                                                )
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {props?.isPagination && (
                    <TableFooter className="w-100 justify-content-end">
                        <TableRow className="w-100 justify-content-end">
                            <TablePagination
                                className="border-top-2 padding-y-16 w-100"
                                rowsPerPageOptions={[5, 10, 25]}
                                style={{ backgroundColor: props.isWhiteRows ? '#FFFFFF' : '' }}
                                // colSpan={3}
                                count={props?.total ?? 0}
                                rowsPerPage={pageSize}
                                page={currentPage}
                                labelRowsPerPage={
                                    <CustomTransComponent translationKey={'COMPONENTS:TABLE.PAGINATION_LABEL'} />
                                }
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': t('COMPONENTS:TABLE.PAGINATION_LABEL'),
                                    },
                                    native: true,
                                }}
                                onPageChange={setPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={getTablePaginationAction}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </>
        );
    })
);
