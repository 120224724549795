import React from 'react';

import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import AccountTree from '@mui/icons-material/AccountTree';
import AddBox from '@mui/icons-material/AddBox';
import CreateNewFolder from '@mui/icons-material/CreateNewFolder';
import DeleteForever from '@mui/icons-material/DeleteForever';
import FolderDelete from '@mui/icons-material/FolderDelete';
import MoveDown from '@mui/icons-material/MoveDown';
import Tag from '@mui/icons-material/Tag';
import { useNavigate } from 'react-router-dom';
import { setDeviceSideBar } from '@fiji/common/src/features/common/commonSlice';
import { DeviceConfiguration } from '@brightlayer-ui/icons-mui';
import { addGroupModal, moveGroupsDevicesModal } from '@fiji/common/src/features/group/groupSlice';
import { deleteDeviceModal } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import { CustomMenu } from 'components/CustomMenu';
import { CustomTransComponent } from 'components';

export const DeviceMenu = (): JSX.Element => {
    const navigate = useNavigate();
    const sideBar = useTypedSelector((state) => state.common.deviceSideBar);
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const dispatch = useAppDispatch();
    const permission = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permission);
    const canCreateDevice = hasPermission('create-devices');
    const canDeleteDevice = hasPermission('delete-devices');
    const canCreateGroup = hasPermission('create-groups');
    const canDeleteGroup = hasPermission('delete-groups');
    const canViewGroup = hasPermission('view-group-management');
    const canUpdateGroup = hasPermission('edit-groups');
    const canUpdateDevice = hasPermission('edit-devices');
    const manageTags = hasPermission('manage-device-tags');

    const handleOpenDeleteDeviceModal = (): void => {
        dispatch(deleteDeviceModal({ isOpen: true }));
    };

    const getActionsBtn = (): any => {
        const menuGroups = [];
        if (canCreateDevice) {
            menuGroups.push({
                icon: <AddBox className="color-content" />,
                title: <CustomTransComponent translationKey={'COMMON:ADD_DEVICE'} />,
                className: `${sideBar.selectedDeviceIds && sideBar.selectedDeviceIds.length ? 'disabled' : ''}`,
                onClick: (): void => navigate(`/${currentRealmName}/add-device`),
            });
        }
        if (canDeleteDevice) {
            menuGroups.push({
                icon: <DeleteForever id="delete-devices" className="color-content" />,
                title: <CustomTransComponent translationKey={'DEVICE_MANAGEMENT:DELETE_DEVICE_BTN'} />,
                className: `${sideBar?.isEnable ? '' : 'disabled'}`,
                onClick: (): void => {
                    handleOpenDeleteDeviceModal();
                },
            });
        }
        if (canUpdateDevice) {
            if (manageTags) {
                menuGroups.push({
                    icon: <Tag fontSize="small" />,
                    title: <CustomTransComponent translationKey={'DEVICES:OPTIONS.ADD_EDIT_TAGS'} />,
                    className: `${sideBar?.isEnable ? '' : 'disabled'}`,
                    onClick: (): void => {
                        dispatch(setDeviceSideBar({ sideBarOpen: true }));
                    },
                });
            }
            menuGroups.push({
                title: <CustomTransComponent translationKey={'DEVICES:OPTIONS.DEVICE_CONFIGURATION'} />,
                icon: <DeviceConfiguration className="color-content" fontSize="small" />,
                className: `${
                    sideBar?.isEnable && sideBar.selectedDeviceIds && sideBar.selectedDeviceIds.length === 1
                        ? ''
                        : 'disabled'
                }`,
                onClick: (): void => navigate(`/${currentRealmName}/edit-device/${sideBar.selectedDeviceIds[0]}`),
            });
        }

        if (canCreateGroup) {
            menuGroups.push({
                icon: <CreateNewFolder className="color-content" fontSize="small" />,
                title: <CustomTransComponent translationKey={'COMMON:ADD_GROUP'} />,
                onClick: (): void => dispatch(addGroupModal({ isOpen: true })),
            });
        }
        if (canDeleteGroup) {
            menuGroups.push({
                icon: <FolderDelete className="color-content" fontSize="small" />,
                title: <CustomTransComponent translationKey={'COMMON:DELETE_GROUP'} />,
                className: 'disabled',
                onClick: () => {
                    dispatch(setDeviceSideBar({ sideBarOpen: true }));
                },
            });
        }
        if (canUpdateGroup) {
            menuGroups.push({
                title: <CustomTransComponent translationKey={'COMMON:MOVE_GROUPS_AND_DEVICES'} />,
                icon: <MoveDown className="color-content" fontSize="small" />,
                onClick: (): void => {
                    dispatch(
                        moveGroupsDevicesModal({
                            isOpen: true,
                        })
                    );
                },
            });
        }
        if (canViewGroup) {
            menuGroups.push({
                icon: <AccountTree />,
                title: <CustomTransComponent translationKey={'COMMON:GROUP_MANAGEMENT'} />,
                onClick: (): void => navigate(`/${currentRealmName}/groups`),
            });
        }
        return {
            id: 'profile-menu',
            menuGroups: menuGroups,
            menuTitle: '',
            menuSubtitle: '',
        };
    };
    return <CustomMenu menuList={getActionsBtn()} />;
};
