import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {
    IconButton,
    List,
    Stack,
    TextField,
    Typography,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    Chip,
    Avatar,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import FilterList from '@mui/icons-material/FilterList';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { CustomTransComponent, Hierarchy, renderGroupHierarchyIcons } from 'components';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';
import { GroupHierarchySkeleton } from 'pages/GroupHierarchy/GroupHierarchySkeleton';
import { useDebounce, useIsMount } from '@fiji/common/src/hooks';
import { AutoCompleteTags } from 'pages/AddDevice';
import { useGetTagsQuery } from '@fiji/common/src/features/TagManagement/tagManagementApi';
import { TAGS_LIMIT } from '@fiji/common/src/constants';
import { useTheme } from '@mui/material/styles';
import {
    deviceFilters,
    resetDeviceChips,
    resetDeviceFilters,
    setDeviceChips,
    setDeviceFilters,
} from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { useGetAllDeviceStatusQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import CustomIcon from 'components/CustomIcon';
import { useParams } from 'react-router-dom';
import { useGetAllDeviceCategoriesMutation } from '@fiji/common/src/features/deviceManagement/ontologyApi';

type Anchor = 'right';

export const Filter = React.forwardRef((_props: any, ref: any): JSX.Element => {
    const theme: any = useTheme();
    const isMount = useIsMount();
    const dispatch = useAppDispatch();
    const { groupId } = useParams();
    const [deviceName, setDeviceName] = React.useState(undefined);
    const [, debouncedValue] = useDebounce(undefined, undefined, deviceName);
    const [groupData, setGroupData] = React.useState<any>();
    const filters = useTypedSelector(deviceFilters);
    const chips = useTypedSelector((state: any) => state.device.chips);
    const [expandType, setExpandType] = React.useState(false);
    const [expandTag, setExpandTag] = React.useState(false);
    const [openSelectBox, setOpenSelectBox] = React.useState(false);
    const [expandUpcoming, setExpandUpcoming] = React.useState(false);
    const [expandGroup, setExpandGroup] = React.useState(false);
    const currentOrg = useTypedSelector(selectedOrg);
    const selectedNode = useTypedSelector((state) => state.common.selectedNode);
    const {
        data: deviceTagsList,
        isLoading: isDeviceTagLoading,
        isFetching: isDeviceTagFetching,
    } = useGetTagsQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
    }>(
        {
            page: 0,
            size: 100,
        },
        { refetchOnMountOrArgChange: true }
    );
    const [sidebar, setSidebar] = React.useState({
        right: false,
    });

    const [getDeviceModels, { data: deviceTypes }] = useGetAllDeviceCategoriesMutation();
    const { data: deviceStatusList }: any = useGetAllDeviceStatusQuery(undefined);
    const handleKeyDown = (e: any): void => {
        if (e.code === 'Enter' && filters?.tags && filters?.tags?.length < TAGS_LIMIT) {
            const tagValue = e.target.value;

            const devicePayloadClone = JSON.parse(JSON.stringify(filters?.tags));
            devicePayloadClone.tags.push(tagValue);

            dispatch(setDeviceFilters({ key: 'tags', value: devicePayloadClone }));
        }
    };
    const handleTagDelete = (data: any, index: number): void => {
        const devicePayloadClone = JSON.parse(JSON.stringify(filters?.tags));
        devicePayloadClone.splice(index, 1);

        dispatch(setDeviceFilters({ key: 'tags', value: devicePayloadClone }));
    };
    const selectedTagsHandler = (e: any, values: any): void => {
        if (values?.length > TAGS_LIMIT) {
            return;
        }

        dispatch(setDeviceFilters({ key: 'tags', value: values?.map((item: any) => item.id) }));
    };
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState<{ [key: string]: any }>({});
    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setSidebar({ ...sidebar, [anchor]: open });
    };

    React.useImperativeHandle(
        ref,
        () => ({
            toggleFilterDrawer: (arg0: any, arg1: any) => toggleDrawer(arg0, arg1),
        }),
        []
    );

    React.useEffect(() => {
        void getDeviceModels({ category: 'CATEGORY' });
    }, []);
    const [getHeirarchyData, { data: hierarchyData, isLoading: hierarchyDataLoader }] = useGetAllGroupsMutation({
        fixedCacheKey: 'cachedAllGroupData',
    });

    React.useEffect(() => {
        if (!groupData) {
            setGroupData(hierarchyData?.data?.records);
        }
    }, [hierarchyData]);
    React.useEffect(() => {
        if (groupId && selectedNode) setGroupData([selectedNode ?? {}]);
        else if (currentOrg?.defaultGroupAccess && !selectedNode)
            setGroupData([
                {
                    id: currentOrg?.id,
                    name: `${currentOrg?.name} (Organization Root)`,
                    icon: {
                        web: {
                            family: 'material-ui',
                            name: 'CorporateFare',
                        },
                    },
                    groupCount: currentOrg?.groupCount,
                    deviceCount: currentOrg?.deviceCount,
                },
            ]);
    }, [selectedNode, currentOrg]);
    const handleToggleSelectBox = (): void => {
        setOpenSelectBox((prev) => {
            if (!prev === false) {
                setHierarchyMappingData({});
            }
            return !prev;
        });
    };
    const handleCleanHierarchyNode = (parentId: string): void => {
        setHierarchyMappingData((prevState) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };

    React.useEffect(() => {
        if (!isMount) {
            dispatch(setDeviceFilters({ key: 'deviceName', value: debouncedValue }));
        }
    }, [debouncedValue]);

    const handleTreeSelection = (parentNode: any): void => {
        const selectedIdsClone: any[] = JSON.parse(JSON.stringify(filters?.groups ?? []));
        const existingId = selectedIdsClone?.findIndex((id: any) => id === parentNode?.id);
        if (existingId !== -1) {
            selectedIdsClone?.splice(existingId, 1);
        } else {
            selectedIdsClone.push(parentNode?.id);
        }
        dispatch(setDeviceFilters({ key: 'groups', value: selectedIdsClone }));
    };

    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);

        const { data: childHierarchyData }: any = await getHeirarchyData({ parent: parentData?.id });

        if (childHierarchyData) {
            setHierarchyMappingData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
        return;
    };

    return (
        <React.Fragment key={'right'}>
            <Button
                id="device-filter"
                sx={{
                    border: `1px solid ${theme?.palette?.primary?.main}`,
                    '&:hover': {
                        backgroundColor: theme?.palette?.primary?.[50],
                    },
                }}
                variant="outlined"
                onClick={toggleDrawer('right', true)}
                startIcon={<FilterList />}
            >
                <CustomTransComponent translationKey={'DEVICES:FILTER'} />
            </Button>

            <Drawer
                anchor={'right'}
                open={sidebar['right']}
                onClose={toggleDrawer('right', false)}
                className="custom-drawer"
            >
                <Stack className="width-368 h-100">
                    <Stack className="border-bottom-1" p={2}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'} color="primary">
                                Filters
                            </Typography>
                            <IconButton id="close" onClick={toggleDrawer('right', false)}>
                                <Close className="black-500" />
                            </IconButton>
                        </Stack>
                        <Stack direction={'row'} gap={1} mt={2} flexWrap={'wrap'} pr={2}>
                            {chips.map((label: string) => (
                                <Chip
                                    key={label}
                                    label={label}
                                    onDelete={(): void => dispatch(setDeviceChips(label))}
                                />
                            ))}
                        </Stack>
                    </Stack>
                    <Stack className="h-100 overflow-y-scroll">
                        <Stack p={2} pb={4} className="border-bottom-1">
                            <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'} color="primary" pb={2}>
                                <CustomTransComponent translationKey={'DEVICES:DEVICE_NAME'} />
                            </Typography>
                            <TextField
                                value={deviceName ?? ''}
                                onChange={(e: any): void => setDeviceName(e.target.value)}
                                id="name"
                                name="name"
                                label="Name"
                                variant="filled"
                            />
                        </Stack>
                        <Stack className="border-bottom-1">
                            <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'} color="primary" p={2}>
                                <CustomTransComponent translationKey={'COMMON:STATUS'} />
                                {` (${filters?.deviceStatus?.length ?? 0})`}
                            </Typography>
                            <List className="padding-8">
                                {Boolean(deviceStatusList?.data?.length) &&
                                    deviceStatusList?.data?.map((item: any) => (
                                        <Stack
                                            key={item?.id}
                                            direction={'row'}
                                            alignItems={'center'}
                                            spacing={3}
                                            sx={{ marginBottom: '10px' }}
                                        >
                                            <Checkbox
                                                id="status"
                                                checked={filters?.deviceStatus?.includes(item?.id) ?? false}
                                                onChange={(): void =>
                                                    dispatch(setDeviceFilters({ key: 'deviceStatus', value: item?.id }))
                                                }
                                            />

                                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                                <Avatar sx={{ background: item?.icon?.web?.style?.backgroundColor }}>
                                                    <CustomIcon
                                                        family={item?.icon?.web?.family}
                                                        iconName={item?.icon?.web?.name}
                                                        iconProps={{
                                                            sx: {
                                                                color: item?.icon?.web?.style?.color,
                                                            },
                                                        }}
                                                    />
                                                </Avatar>

                                                <Typography variant="body1">{item?.name ?? '-'} </Typography>
                                            </Stack>
                                        </Stack>
                                    ))}
                            </List>
                        </Stack>
                        <Stack className="border-bottom-1">
                            <List disablePadding>
                                <InfoListItem
                                    hidePadding
                                    title={
                                        <Typography variant="subtitle2" fontSize={'14px'} color="primary">
                                            <CustomTransComponent translationKey={'COMMON:TYPE'} />
                                        </Typography>
                                    }
                                    rightComponent={
                                        <IconButton id="expand-type" onClick={(): void => setExpandType(!expandType)}>
                                            <KeyboardArrowDown
                                                className={`${expandType ? 'rotated' : 'not-rotated'}`}
                                                color="primary"
                                            />
                                        </IconButton>
                                    }
                                />
                            </List>

                            {expandType && (
                                <List className="padding-x-8">
                                    {deviceTypes?.data?.map((deviceType: any) => (
                                        <Stack key={deviceType?.id} direction={'row'} alignItems={'center'} spacing={3}>
                                            <Checkbox
                                                id="type"
                                                checked={filters?.modelCategory?.includes(deviceType?.id) ?? false}
                                                onChange={(): void => {
                                                    dispatch(
                                                        setDeviceFilters({
                                                            key: 'modelCategory',
                                                            value: deviceType?.id,
                                                        })
                                                    );
                                                }}
                                            />

                                            <Typography variant="body1">{deviceType?.name ?? '--'}</Typography>
                                        </Stack>
                                    ))}
                                </List>
                            )}
                        </Stack>

                        <Stack className="border-bottom-1">
                            <List disablePadding>
                                <InfoListItem
                                    hidePadding
                                    title={
                                        <Typography variant="subtitle2" fontSize={'14px'} color="primary">
                                            <CustomTransComponent translationKey={'COMMON:GROUP'} />
                                        </Typography>
                                    }
                                    rightComponent={
                                        <IconButton
                                            id="expand-group"
                                            onClick={(): void => setExpandGroup(!expandGroup)}
                                        >
                                            <KeyboardArrowDown
                                                className={`${expandGroup ? 'rotated' : 'not-rotated'}`}
                                                color="primary"
                                            />
                                        </IconButton>
                                    }
                                />
                            </List>

                            {expandGroup && (
                                <Stack p={2} pb={4}>
                                    <FormControl variant="standard" className="min-width-140">
                                        <InputLabel id="demo-simple-select-standard-label">Group</InputLabel>
                                        <Select
                                            disabled={hierarchyDataLoader}
                                            displayEmpty
                                            labelId="demo-customized-select-label"
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                            }}
                                            open={openSelectBox}
                                            onClose={(): void => {
                                                handleToggleSelectBox();
                                            }}
                                            onOpen={(): void => {
                                                handleToggleSelectBox();
                                            }}
                                        >
                                            <Hierarchy
                                                data={groupData}
                                                loadMore={handleFetchHierarchy}
                                                cleanHiearchyNode={handleCleanHierarchyNode}
                                                hierarchyMappingData={hierarchyMappingData}
                                                loadingNode={loadingHierarchyNode}
                                                handleTreeNodeSelection={(parentNode): void => {
                                                    handleTreeSelection(parentNode);
                                                }}
                                                filters={[
                                                    {
                                                        key: 'type',
                                                        operator: '!==',
                                                        value: 'DEVICE',
                                                        action: 'hidden',
                                                    },
                                                    {
                                                        key: 'type',
                                                        operator: '!==',
                                                        value: 'GATEWAY',
                                                        action: 'hidden',
                                                    },
                                                ]}
                                                selectionType={'checkbox'} // radio, checkbox, node
                                                selectedNodes={filters.groups ?? []}
                                                hasChild={(treeItem): boolean => treeItem?.groupCount}
                                                getAvatarChild={renderGroupHierarchyIcons}
                                                labelKey="name"
                                                autoChildSelection
                                                loadingSkeleton={<GroupHierarchySkeleton row={1} />}
                                            />
                                        </Select>
                                    </FormControl>
                                </Stack>
                            )}
                        </Stack>

                        <Stack className="border-bottom-1">
                            <List disablePadding>
                                <InfoListItem
                                    hidePadding
                                    title={
                                        <Typography variant="subtitle2" fontSize={'14px'} color="primary">
                                            <CustomTransComponent translationKey={'COMMON:TAGS'} />
                                        </Typography>
                                    }
                                    rightComponent={
                                        <IconButton id="expand-tag" onClick={(): void => setExpandTag(!expandTag)}>
                                            <KeyboardArrowDown
                                                className={`${expandTag ? 'rotated' : 'not-rotated'}`}
                                                color="primary"
                                            />
                                        </IconButton>
                                    }
                                />
                            </List>

                            {expandTag && (
                                <Stack p={2} pb={4}>
                                    <AutoCompleteTags
                                        id="tags"
                                        value={
                                            filters?.tags?.map((item: any) =>
                                                deviceTagsList?.data?.records?.find(
                                                    (subItem: any) => subItem.id === item
                                                )
                                            ) ?? []
                                        }
                                        disabled={isDeviceTagFetching || isDeviceTagLoading}
                                        onChange={selectedTagsHandler}
                                        options={deviceTagsList?.data?.records ?? []}
                                        handleTagDelete={handleTagDelete}
                                        onKeyDown={handleKeyDown}
                                    />
                                </Stack>
                            )}
                        </Stack>
                        {}

                        <Stack className="border-bottom-1">
                            <List disablePadding>
                                <InfoListItem
                                    hidePadding
                                    title={
                                        <Typography variant="subtitle2" fontSize={'14px'} color="primary">
                                            <CustomTransComponent translationKey={'COMMON:UPCOMMING'} />
                                        </Typography>
                                    }
                                    rightComponent={
                                        <IconButton
                                            id="expand-upcoming"
                                            onClick={(): void => setExpandUpcoming(!expandUpcoming)}
                                        >
                                            <KeyboardArrowDown
                                                className={`${expandUpcoming ? 'rotated' : 'not-rotated'}`}
                                                color="primary"
                                            />
                                        </IconButton>
                                    }
                                />
                            </List>

                            {expandUpcoming && (
                                <List className="padding-x-8">
                                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                        <Checkbox id="upcoming-check" />
                                        <Typography variant="body1">3 years</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                        <Checkbox />
                                        <Typography variant="body1">2 years</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                        <Checkbox />
                                        <Typography variant="body1">1 year</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                        <Checkbox />
                                        <Typography variant="body1">180 days</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                        <Checkbox />
                                        <Typography variant="body1">60 days</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                        <Checkbox />
                                        <Typography variant="body1">30 days</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                        <Checkbox />
                                        <Typography variant="body1">Now</Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                        <Checkbox />
                                        <Typography variant="body1">None</Typography>
                                    </Stack>
                                </List>
                            )}
                        </Stack>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                        p={2}
                        className="border-top-1"
                    >
                        <Button
                            id="clearFilter"
                            variant="outlined"
                            disabled={!deviceName && !Object.keys(filters)?.length}
                            onClick={(): void => {
                                dispatch(resetDeviceFilters());
                                setDeviceName(undefined);
                                dispatch(resetDeviceChips());
                            }}
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.[50],
                                },
                            }}
                        >
                            {<CustomTransComponent translationKey={'COMMON:CLEAR_FILTER'} />}
                        </Button>
                    </Stack>
                </Stack>
            </Drawer>
        </React.Fragment>
    );
});
