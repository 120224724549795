import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleChannelRemove, handleReallocateChannels, moveWithinList } from './helpers';

const detailsInitialState: any = {
    secondary: [],
    isTotalCountVisible: false,
    viewAllButton: false,
    secondaryChannelCount: 6,
};

const detailsWidgetSlice = createSlice({
    name: 'details',
    initialState: detailsInitialState,
    reducers: {
        resetConfiguration: () => {
            return detailsInitialState;
        },
        setPredefinedConfiguration: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key === 'primary' || key === 'secondary') {
                    state[key] = action.payload[key]?.map((item: any) => item.channels)?.flat(Infinity);
                    return;
                }
                state[key] = action.payload[key];
            });
        },
        setChannels: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action);
        },

        reAllocateChannels: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        moveBetweenLists: (state: RootState, action: PayloadAction<any>) => {
            moveWithinList(state, action);
        },
        removeChannel: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action);
        },
        setDisplayPreferences: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            if (state.primary) {
                return { ...detailsInitialState, primary: [] };
            }
            return detailsInitialState;
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                const stateClone = current(state);
                if (stateClone?.secondary?.length) {
                    state.secondary = stateClone?.secondary?.map((item: any) => ({
                        ...item,
                        deviceId: action.payload?.data?.id,
                    }));
                }
                if (stateClone?.primary && stateClone?.primary?.length) {
                    state.primary = stateClone?.primary?.map((item: any) => ({
                        ...item,
                        deviceId: action.payload?.data?.id,
                    }));
                }
            }
        });
        builder.addCase('widgetConfiguration/setWidgetSource', (state) => {
            state.secondary = [];
        });
    },
});

export const {
    resetConfiguration,
    setChannels,
    reAllocateChannels,
    setPredefinedConfiguration,
    moveBetweenLists,
    removeChannel,
    setDisplayPreferences,
} = detailsWidgetSlice.actions;
export default detailsWidgetSlice;
