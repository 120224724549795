import React from 'react';
import { Stack, Skeleton } from '@mui/material';

export const EditDevicesSkeleton = (): JSX.Element => (
    <Stack mt={3}>
        <Skeleton variant="text" animation="wave" sx={{ fontSize: '16px', width: '90%' }} />
        <Skeleton variant="text" animation="wave" sx={{ fontSize: '16px', width: '33%', mt: 3 }} />
        <Skeleton variant="text" animation="wave" sx={{ fontSize: '16px', width: '40%' }} />

        <Stack direction={'row'} flexWrap={'wrap'} gap={2} mt={2}>
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: '16px', width: '84px', height: '36px' }}
            />
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: '16px', width: '84px', height: '36px' }}
            />
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: '16px', width: '84px', height: '36px' }}
            />
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: '16px', width: '84px', height: '36px' }}
            />
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: '16px', width: '84px', height: '36px' }}
            />
            <Skeleton
                variant="rectangular"
                animation="wave"
                sx={{ borderRadius: '16px', width: '84px', height: '36px' }}
            />
        </Stack>
    </Stack>
);
