import { RootState } from '../../app/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { handleChannelRemove, handleGroupDevicesFilter, handleReallocateChannels } from './helpers';

const mapInitialState = {
    filters: {},
    secondary: [],
    clustering: true,
    selectedNodes: undefined,
    viewAllButton: false,
    isPinned: false,
};
const mapWidgetSlice = createSlice({
    name: 'map',
    initialState: mapInitialState,
    reducers: {
        resetMapConfiguration: () => {
            return mapInitialState;
        },
        setPredefinedMapConfiguration: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key !== 'filters') {
                    state[key] = action.payload[key];
                }
            });
        },
        setMapData: (state: RootState, action: PayloadAction<any>) => {
            state['secondary'] = action.payload?.map((item: any) => ({
                coordinates: item.coordinates,
                deviceCount: item.deviceCount,
                name: item?.deviceList?.[0]?.name ?? '',
                id: item?.deviceList?.[0]?.id,
            }));
        },
        removeGroupLinkedToMap: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'id');
        },
        setMapfilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        setMapDynamicFilters: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            if (Array.isArray(action.payload.value)) {
                state['filters'][action.payload.key] = action.payload.value;
            } else {
                if (!stateClone['filters'][action.payload.key]?.length) {
                    state.filters[action.payload.key] = [action.payload.value?.id];
                } else {
                    const selectedDeviceTypeIndex = stateClone?.filters?.[action.payload.key]?.indexOf(
                        action.payload.value?.id
                    );
                    if (selectedDeviceTypeIndex !== -1) {
                        state?.filters?.[action.payload.key]?.splice(selectedDeviceTypeIndex, 1);
                    } else {
                        state?.filters?.[action.payload.key]?.push(action.payload.value?.id);
                    }
                }
            }
        },
        setMapSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleGroupDevicesFilter(state, action);
        },
        setMapPreferences: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        reallocateMapSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state: RootState) => {
            state.secondary = [];
            state.selectedNodes = [];
            state.filters = {};
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state: any, action: any) => {
            if (action.payload.reset) state.data = { ...(state.data ?? {}), deviceId: action.payload.data.id };
        });
    },
});

export const {
    resetMapConfiguration,
    setMapPreferences,
    setMapData,
    removeGroupLinkedToMap,
    setPredefinedMapConfiguration,
    reallocateMapSelectedNodes,
    setMapDynamicFilters,
    setMapSelectedNodes,
    setMapfilters,
} = mapWidgetSlice.actions;

export default mapWidgetSlice;
