import React, { useEffect, useImperativeHandle } from 'react';

import { useConfirm } from '@fiji/common/src/hooks';
import { CustomModal, CustomTransComponent } from '../../../components';
import {
    FormControlLabel,
    FormHelperText,
    List,
    ListItem,
    Radio,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ModalActions } from '../../../components/CustomModal';
import { useCreateDashboardMutation } from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { useDispatch } from 'react-redux';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useGetDeviceByIdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { usePayloadActions } from '@fiji/common/src/hooks/usePayloadActions';
import { handleWhiteSpaces } from 'utils/helpers';
import { useRBAC } from 'hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';

type DashboardProps = {
    header?: string;
    actionButtonName?: string;
    [key: string]: any;
};

export const CreateDashboardModal = React.forwardRef((props: DashboardProps, ref): JSX.Element => {
    const dispatch = useDispatch();
    const theme: any = useTheme();
    const { deviceId } = useParams();
    const navigate = useNavigate();
    const [cleanPayload] = usePayloadActions();
    const [payload, setPayload] = React.useState({
        name: '',
        description: '',
        isSystemDashboard: true,
        defaultSource: false,
    });
    const { data: deviceData }: any = useGetDeviceByIdQuery(deviceId, { skip: !deviceId });
    const currentRealmName = useTypedSelector((rootstate) => rootstate.common.selectedRealm);
    const [createDashboard, { isSuccess: dashboardCreationSuccess, data: dashboardData, isLoading }] =
        useCreateDashboardMutation();
    const permissions = useTypedSelector(selectCurrentPermission);

    const { hasPermission } = useRBAC(permissions);
    const canCreateUserDashboard = hasPermission('create-user-dashboards');
    const canCreateSystemDashboard = hasPermission('create-system-dashboards');

    const handleDashboardCreation = async (data: any): Promise<void> => {
        const payloadClone = JSON.parse(JSON.stringify(payload));
        delete payloadClone['defaultSource'];
        if (payloadClone['id']) {
            payloadClone['referenceDashboardId'] = payloadClone['id'];
            delete payloadClone['status'];
            delete payloadClone['id'];
            delete payloadClone['createdAt'];
            delete payloadClone['createdBy'];
            delete payloadClone['updatedAt'];
            delete payloadClone['updatedBy'];
            delete payloadClone['widgetIdList'];
            delete payloadClone['widgetLayout'];
            delete payloadClone['associatedCount'];
            delete payloadClone['sourceId'];
            delete payloadClone['sourceName'];
            delete payloadClone['assignment'];
        }
        const { error }: any = await createDashboard({
            ...cleanPayload(payloadClone),
            assignType: payload?.defaultSource ? 'SOURCE' : data?.assignType,
            assignTypeIds: payload?.defaultSource
                ? [deviceData?.data?.deviceTypeId ?? data?.sourceId]
                : data?.assignTypeIds ?? [],
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: `${payload?.name}${
                        props?.actionButtonName === 'Clone' ? ' has been created.' : ' has been saved'
                    }`,
                    duration: 3000,
                })
            );
            onCancel();
        }
    };
    const { data, onClick, onCancel, isVisible, onConfirm } = useConfirm(handleDashboardCreation as any);

    React.useEffect(() => {
        if (canCreateUserDashboard && !canCreateSystemDashboard) {
            setPayload((prev: any) => ({ ...prev, isSystemDashboard: false }));
        }
    }, [canCreateSystemDashboard, canCreateUserDashboard]);

    React.useEffect(() => {
        if (dashboardCreationSuccess && dashboardData && !data?.id) {
            navigate(`/${currentRealmName}/editDashboard/${data?.assignTypeIds[0]}/${dashboardData?.data?.id}`, {
                state: {
                    assignType: dashboardData?.data?.assignType,
                    assignId: dashboardData?.data?.assignTypeIds[0],
                },
            });
        }
    }, [dashboardCreationSuccess, dashboardData]);

    useEffect(() => {
        if (data?.id) {
            setPayload({
                ...data,
                name: `${data?.name}-copy`,
            });
        }
    }, [data]);
    useImperativeHandle(
        ref,
        () => ({
            handleModalAction: (type: boolean, selectedData: any) => handleModalAction(type, selectedData),
        }),
        []
    );

    const handleModalAction = (type: boolean, selectedData?: any): void => {
        if (type) {
            if (selectedData?.assignType === 'SOURCE') {
                setPayload((prev: any) => ({ ...prev, defaultSource: true }));
            }
            onClick(selectedData);
            return;
        }
        onCancel();
    };
    const createDashboardActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: onCancel,
        },
        {
            key: 'save',
            label: props?.actionButtonName ?? <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />,
            variant: 'contained',
            isLoading: isLoading,
            disabled: isLoading || !payload?.name || !handleWhiteSpaces(payload?.name),
            handleClick: onConfirm,
        },
    ];
    const handlePayloadChange = (type: any, value: any): void => {
        setPayload((prev: any) => ({ ...prev, [type]: value }));
    };

    return (
        <CustomModal
            isOpen={isVisible}
            isLoading={false}
            type="primary"
            actions={createDashboardActions}
            header={props?.header ?? <CustomTransComponent translationKey={'DASHBOARDS:CREATE_DASHBOARD'} />}
        >
            <Stack py={2}>
                <Typography variant="body1" fontWeight={'600'} mb={2} className="black-500">
                    <CustomTransComponent translationKey={'DASHBOARDS:CLONE_MODAL_DASHBOARD.DESCRIPTION'} />
                </Typography>
                <TextField
                    fullWidth
                    name="name"
                    label="Name"
                    value={payload?.name}
                    inputProps={{ maxLength: 32 }}
                    onChange={(e: any): void => handlePayloadChange(e.target.name, e.target.value)}
                    variant="filled"
                    className="margin-top-18"
                    required
                    id="outlined-required"
                />
                <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                    <FormHelperText>
                        <CustomTransComponent translationKey={'COMMON:REQUIRED_LABEL'} />
                    </FormHelperText>
                    <FormHelperText>{`${payload?.name?.length ?? 0}/32`}</FormHelperText>
                </Stack>
                <TextField
                    id="outlined-password-input"
                    fullWidth
                    value={payload?.description}
                    inputProps={{ maxLength: 256 }}
                    onChange={(e: any): void => handlePayloadChange(e.target.name, e.target.value)}
                    name="description"
                    className="margin-top-18"
                    variant="filled"
                    multiline
                    label={<CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />}
                    rows={4}
                />
                <FormHelperText className="text-align-end padding-x-16">{`${
                    payload?.description?.length ?? 0
                }/256`}</FormHelperText>
                {canCreateSystemDashboard && (
                    <>
                        <Typography variant="body1" fontWeight={'600'} className="black-500">
                            <CustomTransComponent
                                translationKey={'DASHBOARDS:CLONE_MODAL_DASHBOARD.SYSTEM_USER_DESCRIPTION'}
                            />
                        </Typography>
                        <List className="margin-top-18">
                            <ListItem disablePadding>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={payload.isSystemDashboard}
                                            onChange={(): void => handlePayloadChange('isSystemDashboard', true)}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" className="black-500">
                                            <b>
                                                {' '}
                                                <CustomTransComponent
                                                    translationKey={'DASHBOARDS:CLONE_MODAL_DASHBOARD.SYSTEM_DASHBOARD'}
                                                />
                                            </b>{' '}
                                            <CustomTransComponent
                                                translationKey={
                                                    'DASHBOARDS:CLONE_MODAL_DASHBOARD.SYSTEM_DASHBOARD_DESCRIPTION'
                                                }
                                            />
                                        </Typography>
                                    }
                                    className="f-14 fw-400"
                                />
                            </ListItem>
                            <ListItem disablePadding>
                                <FormControlLabel
                                    className="f-14 fw-400"
                                    control={
                                        <Radio
                                            checked={!payload.isSystemDashboard}
                                            onChange={(): void => handlePayloadChange('isSystemDashboard', false)}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" className="black-500">
                                            <b>
                                                {' '}
                                                <CustomTransComponent
                                                    translationKey={'DASHBOARDS:CLONE_MODAL_DASHBOARD.USER_DASHBOARD'}
                                                />
                                            </b>{' '}
                                            <CustomTransComponent
                                                translationKey={
                                                    'DASHBOARDS:CLONE_MODAL_DASHBOARD.USER_DASHBOARD_DESCRIPTION'
                                                }
                                            />
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </>
                )}
                {(deviceData?.data?.deviceTypeName || data?.assignType !== 'GROUP') && (
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'} className="black-500">
                            {!canCreateSystemDashboard ? (
                                `Set as your personal default dashboard for ${
                                    deviceData?.data?.deviceTypeName ?? data?.sourceName
                                } `
                            ) : (
                                <CustomTransComponent
                                    translationKey={'DASHBOARDS:CLONE_MODAL_DASHBOARD.SET_AS_DEFAULT'}
                                    replace={{ deviceType: deviceData?.data?.deviceTypeName ?? data?.sourceName }}
                                />
                            )}
                        </Typography>
                        <Switch
                            checked={payload?.defaultSource}
                            onChange={(): void => {
                                setPayload((prev: any) => ({ ...prev, defaultSource: !prev.defaultSource }));
                            }}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: theme?.palette?.primary?.main,
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: theme?.palette?.primary?.main,
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                                    color: theme?.palette?.primary?.[50],
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: theme?.palette?.primary?.[200],
                                    },
                                },
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        </CustomModal>
    );
});
