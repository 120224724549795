import { Skeleton, Stack } from '@mui/material';
import React from 'react';

export const RowSkeleton = React.memo(
    (): JSX.Element => (
        <Stack className="border-right-1">
            <Stack
                direction={'row'}
                className="border-bottom-1"
                py={2}
                alignItems={'center'}
                justifyContent={'space-around'}
            >
                <Skeleton animation="wave" variant="text" width={128} height={18} />
                <Skeleton animation="wave" variant="text" width={128} height={18} />
                <Skeleton animation="wave" variant="text" width={128} height={18} />
            </Stack>
            <Stack
                direction={'row'}
                className="border-bottom-1"
                py={2}
                alignItems={'center'}
                justifyContent={'space-around'}
            >
                <Skeleton animation="wave" variant="text" width={128} height={18} />
                <Skeleton animation="wave" variant="text" width={128} height={18} />
                <Skeleton animation="wave" variant="text" width={128} height={18} />
            </Stack>
        </Stack>
    )
);
