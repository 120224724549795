import React from 'react';

import clsx from 'clsx';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTypedSelector } from '@fiji/common';

export const EventGrid = ({ event, hideName }: { event: any; hideName?: boolean }): JSX.Element => {
    const startHour = new Date(event?.schedules[0])?.getHours();
    const startMinutes = new Date(event?.schedules[0])?.getMinutes();

    const currentTime = useTypedSelector((state) => state.schedule.currentTime);

    return event?.actionEndTime ? (
        <Grid
            item
            key={event?.id}
            xs={0.5}
            style={{
                position: 'absolute',
                height: '50%',
                minWidth: `${(((event?.actionEndTime / 1000 - event?.schedules[0] / 1000) / 60 / 60) * 100) / 24}%`,
                left: `${((startHour * 60 + startMinutes) / 1440) * 100}%`,
                zIndex: 1,
            }}
            className={clsx({
                'ongoing-event':
                    event?.schedules[0] <= new Date(currentTime)?.getTime() &&
                    event?.actionEndTime >= new Date(currentTime)?.getTime(),
                'margin-y-8': true,
                'future-event': new Date(currentTime)?.getTime() < event?.schedules[0],
                'past-event': new Date(currentTime)?.getTime() > event?.actionEndTime,
            })}

            // onClick={() => handleScheduleClick(event?.eventId, event?.startTime)}
        >
            <Typography variant="body2" className="text-truncate">
                {!hideName && event?.name}
            </Typography>
        </Grid>
    ) : (
        <Stack
            sx={{
                position: 'absolute',
                height: '50%',
                left: `${((startHour * 60 + startMinutes) / 1440) * 100}%`,
                zIndex: 1,
            }}
            key={event?.id}
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            className={clsx({
                'margin-y-8': true,
                // 'min-width=150 ': true,
                'past-text-event': new Date(currentTime)?.getTime() > event?.schedules[0],
                'future-text-event': new Date(currentTime)?.getTime() < event?.schedules[0],
                'ongoing-text-event': new Date(currentTime)?.getTime() === event?.schedules[0],
            })}
        >
            <Box
                className="dot"
                //  onClick={() => handleScheduleClick(event?.eventId, event?.startTime)}
            />
            <Typography variant="body2" className="text-truncate">
                {event?.name}
            </Typography>
        </Stack>
    );
};
