import React, { ReactNode } from 'react';

import Add from '@mui/icons-material/Add';
import { styled, useTheme } from '@mui/material/styles';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Button, ListItemIcon, ListItemText, MenuItem, Table } from '@mui/material';
import { TableSkeleton } from '../TableSkeleton';
import { ColumnVisibilityMenu } from './ColumnVisiblityMenu';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    setAllPinnedColumns,
    setAllVisibleColumns,
    setPagination,
} from '@fiji/common/src/features/component/dataGridSlice';
import {
    DataGridPro,
    GridColDef,
    useGridApiRef,
    gridColumnsTotalWidthSelector,
    GridRowSelectionModel,
    GridColumnMenuItemProps,
    GridColumnMenuProps,
    GridColumnMenu,
} from '@mui/x-data-grid-pro';
import { shallowEqual } from 'react-redux';
import { FilterList } from '@mui/icons-material';

type GenericDataType = Array<{ [key: string]: any }>;

type DataGridType = {
    paginationLabel?: string;
    rows: GenericDataType;
    columns: GridColDef[];
    noDataFoundIcon: ReactNode;
    noDataFoundTitle: string;
    total: number;
    noDataFoundDescription?: string;
    noDataFoundButtonAction?: () => void;
    handleFilterChange?: () => void;
    handleSortChange?: () => void;
    noDataFoundButtonText?: string;
    isLoading: boolean;
    selectedIds: GridRowSelectionModel;
    setSelectedIds: any;
    isSelectable?: boolean;
    onFilterClick?: any;
};

const StyledDataGridPro = styled(DataGridPro)(({ theme }: any) => ({
    '& .MuiDataGrid-pinnedColumns, & .MuiDataGrid-pinnedColumnHeaders': {
        transition: theme.transitions.create('box-shadow', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    '&.hasScrollToLeft .MuiDataGrid-pinnedColumns--left, &.hasScrollToRight .MuiDataGrid-pinnedColumns--right': {
        boxShadow: 'none',
    },
    '&.hasScrollToLeft .MuiDataGrid-pinnedColumnHeaders--left, &.hasScrollToRight .MuiDataGrid-pinnedColumnHeaders--right':
        {
            boxShadow: 'none',
        },
}));

export const DataGrid = ({
    rows,
    columns,
    selectedIds,
    setSelectedIds,
    onFilterClick,
    ...props
}: DataGridType): JSX.Element => {
    const theme: any = useTheme();
    const apiRef = useGridApiRef();
    const dispatch = useAppDispatch();
    const rootRef = React.useRef<HTMLDivElement>(null);
    const rowCountRef = React.useRef(props?.total || 0);

    const { configurablePayload, paginationPayload } = useTypedSelector((state) => state.dataGrid, shallowEqual);

    React.useEffect(
        () =>
            apiRef?.current?.subscribeEvent('scrollPositionChange', ({ left }) => {
                if (!rootRef?.current) {
                    return;
                }
                const columnsTotalWidth = gridColumnsTotalWidthSelector(apiRef);
                const dimensions = apiRef?.current?.getRootDimensions();

                if (Math.round(left + dimensions?.viewportOuterSize?.width) < columnsTotalWidth) {
                    rootRef?.current?.classList.add('hasScrollToRight');
                } else {
                    rootRef?.current?.classList.remove('hasScrollToRight');
                }

                if (left !== 0 && Math.round(left + dimensions?.viewportOuterSize?.width) <= columnsTotalWidth) {
                    rootRef?.current?.classList.add('hasScrollToLeft');
                } else {
                    rootRef?.current?.classList.remove('hasScrollToLeft');
                }
            }),
        [apiRef]
    );

    const rowCount = React.useMemo(() => {
        if (props?.total !== undefined) {
            rowCountRef.current = props?.total;
        }
        return rowCountRef.current;
    }, [props?.total]);

    function CustomUserItem(userItemProps: GridColumnMenuItemProps): JSX.Element {
        const { myCustomHandler, myCustomValue } = userItemProps;
        return (
            <MenuItem onClick={myCustomHandler}>
                <ListItemIcon>
                    <FilterList fontSize="small" />
                </ListItemIcon>
                <ListItemText>{myCustomValue}</ListItemText>
            </MenuItem>
        );
    }

    function CustomColumnMenu(gridColumnMenuProps: GridColumnMenuProps): JSX.Element {
        return (
            <GridColumnMenu
                {...gridColumnMenuProps}
                slots={{
                    // Add new item
                    columnMenuUserItem: CustomUserItem,
                }}
                slotProps={{
                    columnMenuUserItem: {
                        displayOrder: 15,
                        myCustomValue: 'Filter',
                        myCustomHandler: (e: any) => {
                            onFilterClick(e);
                            gridColumnMenuProps?.hideMenu(e);
                        },
                    },
                }}
            />
        );
    }

    return (
        <StyledDataGridPro
            rows={props?.isLoading ? [] : rows}
            rowSelectionModel={selectedIds}
            onRowSelectionModelChange={setSelectedIds}
            ref={rootRef}
            initialState={{
                pagination: {
                    paginationModel: { pageSize: paginationPayload?.pageSize, page: paginationPayload?.page },
                },
            }}
            autoHeight
            rowHeight={64}
            rowCount={rowCount}
            disableColumnFilter
            apiRef={apiRef}
            pagination
            paginationMode="server"
            paginationModel={paginationPayload}
            onPaginationModelChange={(data: any): void => {
                dispatch(setPagination(data));
            }}
            columns={columns}
            loading={props.isLoading}
            {...(rows?.length && { pinnedColumns: configurablePayload?.pinnedColumns })}
            {...(rows?.length && {
                onPinnedColumnsChange: (columnData: any): void => {
                    dispatch(setAllPinnedColumns(columnData));
                },
            })}
            {...(props?.isSelectable && {
                checkboxSelection: true,
            })}
            sx={{
                '.MuiDataGrid-cell': {
                    background: '#fff',
                },
                '.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft': {
                    backgroundColor: '#fff',
                },
                '.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight': {
                    backgroundColor: '#fff',
                },

                '.MuiDataGrid-columnHeader': {
                    background: '#fff',
                },

                '.MuiDataGrid-container--top [role=row]': {
                    background: '#fff',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                    outline: 'none !important',
                },
                ' .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none !important',
                },
                '.MuiDataGrid-row.Mui-selected': {
                    background: '#fff',
                    backgroundColor: '#fff !important',
                },
                '.MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedLeft': {
                    backgroundColor: '#fff !important',
                },
                '.MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedRight': {
                    backgroundColor: '#fff !important',
                },
                '.MuiDataGrid-overlayWrapperInner': {
                    height: '600px !important',
                },
                ...(!rows?.length && {
                    '.MuiDataGrid-virtualScroller': {
                        height: '600px !important',
                    },
                    '.MuiDataGrid-root.MuiDataGrid-autoHeight': {
                        height: '600px !important',
                    },
                }),
            }}
            slotProps={{
                ...(props?.paginationLabel && {
                    pagination: {
                        showFirstButton: true,
                        showLastButton: true,
                        labelRowsPerPage: props?.paginationLabel,
                    },
                }),
            }}
            localeText={{
                columnMenuSortAsc: 'Sort by Ascending',
                columnMenuSortDesc: 'Sort by Descending',
            }}
            slots={{
                columnsPanel: (): JSX.Element => <ColumnVisibilityMenu columns={columns} />,
                noRowsOverlay: (): JSX.Element => (
                    <EmptyState
                        // className="min-height-400"
                        icon={props?.noDataFoundIcon}
                        title={props?.noDataFoundTitle}
                        {...(props?.noDataFoundDescription && {
                            description: props.noDataFoundDescription,
                        })}
                        actions={
                            props?.noDataFoundButtonAction && (
                                <Button
                                    id="addDevice"
                                    sx={{
                                        backgroundColor: theme?.palette?.primary?.main,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.main,
                                        },
                                    }}
                                    variant={'contained'}
                                    startIcon={<Add />}
                                    onClick={props?.noDataFoundButtonAction}
                                >
                                    {props?.noDataFoundButtonText ?? ''}
                                </Button>
                            )
                        }
                    />
                ),
                loadingOverlay: (): JSX.Element => (
                    <Table>
                        <TableSkeleton headers={columns} />,
                    </Table>
                ),
                columnMenu: CustomColumnMenu,
            }}
            pageSizeOptions={[5, 10, 25]}
            disableRowSelectionOnClick
            sortingMode="server"
            onSortModelChange={props.handleSortChange}
            onFilterModelChange={props.handleFilterChange}
            columnVisibilityModel={configurablePayload?.visibleColumns}
            onColumnVisibilityModelChange={(columnData: any): void => {
                dispatch(setAllVisibleColumns(columnData));
            }}
            // sortingMode="s"
            autosizeOnMount
            getCellClassName={(): string => 'flex-center-align'}
        />
    );
};
