import React from 'react';

import { useGetAllSchedulesQuery } from '@fiji/common/src/features/schedule/scheduleApi';
import { Schedule, useHandleSchedule } from 'components/Schedule';
import { DevicesGrid, DeviceTypesGrid } from './ViewSchedules';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { setScheduledEvents, toggleScheduleLoader } from '@fiji/common/src/features/schedule/schedulerSlice';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { getFromToFormat } from './utils/helpers';
import { useDebounce } from 'hooks';

export const ScheduleCalendar = React.memo(({ searchKey }: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const { viewMode, groupingEnabled, selectedDate } = useHandleSchedule();
    const currentOrg = useTypedSelector(selectedOrg);
    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const selectedNode = useTypedSelector((state) => state['common']['selectedNode']);

    const selectedDeviceType = useTypedSelector((state) => state['scheduler']['selectedDeviceType']);
    const dailyScheduledEvents = useTypedSelector((state) => state['scheduler']['dailyScheduledEvents']);

    const {
        isError,
        isSuccess,
        currentData: allSchedules,
    } = useGetAllSchedulesQuery(
        {
            page: 0,
            size: 1000,
            filters: {
                ...(!selectedNode?.wrapperDeviceType || selectedNode?.wrapperDeviceType === 'Gateway'
                    ? { groupId: selectedNode?.id ?? currentOrg?.id }
                    : { deviceId: [selectedNode?.id] }),
                ...getFromToFormat(viewMode, selectedDate),
            },
            ...(debouncedValue && { searchKey: debouncedValue }),
        },
        { skip: !viewMode, refetchOnMountOrArgChange: true }
    );

    React.useEffect(() => {
        if (allSchedules?.data && viewMode === 'day') {
            dispatch(setScheduledEvents({ isGrouped: groupingEnabled, value: allSchedules?.data?.records }));
        }
    }, [allSchedules, groupingEnabled, viewMode]);

    React.useEffect(() => {
        if (isSuccess || isError) {
            dispatch(toggleScheduleLoader(false));
        }
    }, [isSuccess, isError]);
    return (
        <Schedule
            {...(viewMode === 'day' && {
                groupingEnabled: true,
            })}
            groupingLabel="Group by Device Type"
            gridLabel="Device"
            selectedDeviceType={selectedDeviceType?.id}
            schedules={viewMode === 'day' ? dailyScheduledEvents : allSchedules?.data?.records}
            leftGrid={groupingEnabled ? <DeviceTypesGrid /> : <DevicesGrid />}
        />
    );
});
