import { useTypedSelector } from '@fiji/common/src/app/store';
import {
    useCreateVirtualLoadMutation,
    useGetAllVirtualLoadTypesQuery,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { CustomModal, CustomTransComponent } from 'components';
import { ModalActions } from 'components/CustomModal';
import React from 'react';
import { useHandleVirtualLoads } from './hooks';
import { useDispatch } from 'react-redux';
import { resetVirtualLoadPayload } from '@fiji/common/src/features/deviceManagement/virtualLoadSlice';

export const AddVirtualModal = (props: any): JSX.Element => {
    const dispatch = useDispatch();
    const { data } = useGetAllVirtualLoadTypesQuery();
    const { payload } = useTypedSelector((state) => state['virtualLoad']);

    const { handlePayloadChange } = useHandleVirtualLoads();

    const [, { isLoading }] = useCreateVirtualLoadMutation({
        fixedCacheKey: 'createVirtualLoad',
    });

    const actions: ModalActions[] = [
        {
            testId: 'cancel',
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                dispatch(resetVirtualLoadPayload());
                props?.onCancel();
            },
        },
        {
            testId: 'save',
            key: 'save',
            label: <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />,
            variant: 'contained',
            disabled: isLoading || !payload?.name?.length || !payload?.loadType?.length,
            handleClick: props?.onConfirm,
        },
    ];
    return (
        <CustomModal
            isOpen={props?.isVisible}
            isLoading={isLoading}
            type="primary"
            header={`${props?.mode === 'edit' ? 'Edit' : 'Add'} Virtual Load`}
            actions={actions}
            hidePadding
        >
            <Stack component="form" spacing={2} noValidate autoComplete="off" m={2}>
                <TextField
                    id="name"
                    required
                    label={<CustomTransComponent translationKey={'COMMON:NAME'} />}
                    name="name"
                    variant="filled"
                    value={payload?.name}
                    onChange={(e): void => handlePayloadChange(e, 24)}
                />
                <Typography variant="body2" className="custom-add-device-field-lengths-style">
                    {`${payload?.name?.length ?? 0}/24`}
                </Typography>
                <TextField
                    id="assetName"
                    label={<CustomTransComponent translationKey={'COMMON:ASSET_NAME_TAG'} />}
                    name="assetName"
                    variant="filled"
                    value={payload?.assetName}
                    onChange={(e): void => handlePayloadChange(e, 24)}
                />
                <Typography variant="body2" className="custom-add-device-field-lengths-style">
                    {`${payload?.assetName?.length ?? 0}/24`}
                </Typography>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel required id="demo-simple-select-filled-label">
                        <CustomTransComponent translationKey={'LOADS:TYPE_OF_LOAD'} />
                    </InputLabel>
                    <Select
                        labelId="load-type-label"
                        id="load-type"
                        name="loadType"
                        value={payload?.loadType}
                        onChange={(e: any) => handlePayloadChange(e)}
                    >
                        {data?.data?.map((item: { name: string; id: string }) => (
                            <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    id="description"
                    multiline={true}
                    maxRows={4}
                    variant="filled"
                    value={payload?.description}
                    onChange={(e: any) => handlePayloadChange(e, 256)}
                    label={<CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />}
                    name="description"
                />
                <Typography variant="body2" className="custom-add-device-field-lengths-style">
                    {`${payload?.description?.length ?? 0}/256`}
                </Typography>
            </Stack>
        </CustomModal>
    );
};
