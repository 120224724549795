import React from 'react';

import { AccordionDetails, Box, InputAdornment, Stack, TextField, Typography } from '@mui/material';

export const EventSummaryDetails = ({ events, category }: { events: any; category: any }): JSX.Element => (
    <AccordionDetails className="padding-0">
        {events?.data['alarmOccurred']?.[category]?.map((channel: any) => (
            <Stack
                key={channel?.channelId}
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                className="flex-100 border-bottom-1"
                pl={2}
                py={1}
            >
                <Stack direction={'row'} alignItems={'center'} className="flex-30" spacing={1}>
                    <Box className="border-radius-50 width-24 height-24 bg-content" />
                    <Typography variant="body2" fontSize={'14px'}>
                        {channel?.displayName ?? '-'}
                    </Typography>
                </Stack>
                <Stack direction={'row'} className="flex-60" spacing={1}>
                    <TextField
                        id="channel-value"
                        variant="filled"
                        className="delayed-input-class channel-value-textfield flex-46"
                        value={channel?.value ?? 0}
                        sx={{ minWidth: 132 }}
                        inputProps={{
                            style: { textAlign: 'end', marginRight: '2px' },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment sx={{ mb: '20px' }} position="start">
                                    {channel?.unit ?? ''}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        id="delay"
                        variant="filled"
                        className="delayed-input-class channel-value-textfield flex-46"
                        value={
                            events?.data?.['alarmUpdated']?.[category]?.find(
                                (subChannel: any) => subChannel.channelId === channel.channelId
                            )?.value ?? 0
                        }
                        sx={{ minWidth: 132 }}
                        inputProps={{
                            style: { textAlign: 'end', marginRight: '2px' },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment sx={{ mb: '20px' }} position="start">
                                    {
                                        events?.data?.['alarmUpdated']?.[category]?.find(
                                            (subChannel: any) => subChannel.channelId === channel.channelId
                                        )?.unit
                                    }
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
            </Stack>
        ))}
    </AccordionDetails>
);
