import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
export type LoadPayload = {
    details: any;
    command?: string;
    isOpen: boolean;
    deviceId?: string;
    widgetId?: any;
};

export type PayloadState = {
    page: number;
    size: number;
    filters: any;
    searchKey?: string | null;
};

const initialPayloadState: PayloadState = {
    page: 0,
    size: 10,
    filters: {},
};

const initialLoadPayload: LoadPayload = {
    command: '',
    isOpen: false,
    details: undefined,
    deviceId: undefined,
    widgetId: undefined,
};

const initialState = {
    loadModal: initialLoadPayload,
    payload: initialPayloadState,
    searchKey: null,
};

const loadSlice = createSlice({
    name: 'load',
    initialState: initialState,
    reducers: {
        setLoadModal: (state, { payload }: PayloadAction<LoadPayload>) => {
            state.loadModal = payload;
        },
        setPayload: (state, action) => {
            state.payload = { ...state.payload, ...action.payload };
        },
        setSearchKey: (state, action) => {
            state.searchKey = action.payload;
        },
        resetFilters: (state, action) => {
            delete state.payload.filters[action.payload];
        },
        resetAllFilters: () => {
            return initialState;
        },
    },
});

export const { setLoadModal, setPayload, resetFilters, resetAllFilters, setSearchKey } = loadSlice.actions;
export const selectLoadModal = (state: RootState): LoadPayload => state.load.loadModal;
export const selectLoadPayload = (state: RootState): PayloadState => state.load.payload;
export const selectSearchKey = (state: RootState): string => state.load.searchKey;

export default loadSlice;
