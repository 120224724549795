import { List, Divider, Stack, Typography, Paper } from '@mui/material';
import React from 'react';
import SimpleCardSkeleton from '../SimpleCardSkeleton';
import { useTheme } from '@mui/material/styles';

export const AlarmOccurred = ({
    isLoading,
    data,
    convertDateTime,
}: {
    isLoading: boolean;
    data: any;
    convertDateTime: any;
}): JSX.Element => {
    const theme: any = useTheme();
    return (
        <Paper>
            <List disablePadding>
                {isLoading ? (
                    <SimpleCardSkeleton count={6} />
                ) : (
                    <>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            className="border-bottom-1"
                            p={2}
                        >
                            <Typography
                                sx={{ fontSize: '14px', color: theme?.palette?.primary?.main }}
                                fontWeight={'600'}
                            >
                                Last Update Details
                            </Typography>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Typography className="text-secondary" fontWeight={'600'} fontSize={'14px'}>
                                    {convertDateTime({
                                        timestamp: parseInt(data?.data?.alarmOccurred),
                                    }) || '-'}
                                    ,
                                </Typography>
                                <Typography className="text-secondary" fontWeight={'600'} fontSize={'14px'}>
                                    {convertDateTime({
                                        timestamp: parseInt(data?.data?.alarmOccurred),
                                        customFormat: 'HH:mm:ss',
                                    }) || '-'}
                                </Typography>
                            </Stack>
                        </Stack>

                        {data?.data?.occurredTrends && Object.keys(data?.data?.occurredTrends)?.length ? (
                            Object.keys(data?.data?.occurredTrends)?.map((item: any) => (
                                <>
                                    <Divider />
                                    <Stack
                                        p={2}
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography className="text-secondary" variant="body1" fontSize={'14px'}>
                                            {item}
                                        </Typography>
                                        <Typography className="text-secondary" fontWeight={'600'} fontSize={'14px'}>
                                            {data?.data?.occurredTrends[item] ?? 0}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                </>
                            ))
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </List>
        </Paper>
    );
};
