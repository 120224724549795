import React from 'react';

import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setUpdateProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useStyles } from 'pages/AddDevice/styles';
import { useTheme } from '@mui/styles';

export const DateUpdateModal = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const classes = useStyles(theme);
    const { propertiesPayload } = useTypedSelector((state) => state.device);
    const [openSelectBox, setOpenSelectBox] = React.useState(false);
    const handleToggleSelectBox = (): void => {
        setOpenSelectBox(!openSelectBox);
    };

    return (
        <FormControl variant={'filled'} className={classes.formControl} fullWidth>
            <InputLabel id={'select-Assign-to-Group'} required={false}>
                Date
            </InputLabel>
            <Select
                displayEmpty
                id="installdate"
                labelId="demo-customized-select-label"
                renderValue={(): any => (
                    <>{moment(propertiesPayload?.batteryInstallDate ?? 1641038661000)?.format('LL')}</>
                )}
                value={propertiesPayload?.batteryInstallDate ?? 1641038661000}
                open={openSelectBox}
                onClose={handleToggleSelectBox}
                onOpen={handleToggleSelectBox}
            >
                {openSelectBox && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateCalendar
                            minDate={moment(propertiesPayload?.batteryInstallDate ?? 1641038661000)}
                            disableFuture
                            value={moment(propertiesPayload?.batteryInstallDate) ?? null}
                            onChange={(date): void =>
                                dispatch(setUpdateProperties({ key: 'batteryInstallDate', value: date }))
                            }
                        />
                    </LocalizationProvider>
                )}
            </Select>
        </FormControl>
    );
};
