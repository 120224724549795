import React, { useEffect, useState } from 'react';
import { TextField, Stack, Typography, InputAdornment, IconButton, Button } from '@mui/material';
import { Autocomplete } from '@react-google-maps/api';
import { LocationType, fetchLocation, MapComponent } from '../../WidgetManagement/Widgets/map/Component';
import MyLocation from '@mui/icons-material/MyLocation';
import Search from '@mui/icons-material/Search';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setUpdateProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { DECIMAL_REGEX } from '@fiji/common';

export const LocationUpdateModal = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const [location, setLoaction] = useState<LocationType>({
        lat: null,
        lng: null,
    });
    const [coordinates, setCoordinates] = useState<LocationType>({
        lat: null,
        lng: null,
    });
    const [autocomplete, setAutocomplete] = useState<any>(null);
    const [isAutocomplete, setIsAutocomplete] = useState(false);
    const [locationName, setLocationName] = useState('');
    const googleMapLoaded = useTypedSelector((state) => state.common.googleMapLoaded);

    const locationChangeHandler = (e: any): void => {
        if (!DECIMAL_REGEX.test(e.target.value)) {
            return;
        }
        setLoaction((prev: any) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
    };

    const handleMapClick = (): void => {
        try {
            if (coordinates?.lat && coordinates?.lng) {
                const geocoder = new google.maps.Geocoder();
                geocoder
                    .geocode({ location: coordinates as any })
                    .then((response) => {
                        setIsAutocomplete(true);
                        setLocationName(response.results[0]?.formatted_address ?? '');
                    })
                    .catch(() => {});
            }
        } catch (error) {
            throw new Error('Error fetching location details');
        }
    };

    useEffect(() => {
        if (coordinates?.lat && !isAutocomplete) handleMapClick() as any;
    }, [coordinates, isAutocomplete]);

    useEffect(() => {
        dispatch(
            setUpdateProperties({
                key: 'location',
                value: {
                    lat: coordinates?.lat ? +coordinates?.lat : null,
                    lng: coordinates?.lng ? +coordinates?.lng : null,
                },
            })
        );
    }, [coordinates]);

    const onPlaceSelect = (): void => {
        setIsAutocomplete(true);
        setLocationName('');
        const lat = autocomplete?.getPlace()?.geometry?.location.lat();
        const lng = autocomplete?.getPlace()?.geometry.location.lng();
        setCoordinates({ lat, lng });
        setLoaction({ lat, lng });
    };

    const getLoaction = (): void => {
        void fetchLocation().then(({ lat, lng }) => {
            setCoordinates({ lat, lng });
            setIsAutocomplete(false);
            setLoaction({ lat, lng });
        });
    };

    return (
        <>
            <Typography variant="body1">Enter your location or search using latitude and longitude.</Typography>
            <Stack
                direction={'row'}
                pb={2}
                my={2}
                width={'100%'}
                justifyContent={'space-between'}
                className="border-bottom-1"
            >
                {googleMapLoaded && (
                    <Autocomplete
                        className="w-100"
                        onLoad={(autocompleteData: any): void => {
                            setAutocomplete(autocompleteData);
                        }}
                        onPlaceChanged={onPlaceSelect}
                    >
                        <Stack direction={'row'}>
                            {locationName ? (
                                <TextField
                                    data-cy="location"
                                    onChange={(event): void => {
                                        setLocationName(event.target.value);
                                        setIsAutocomplete(true);
                                    }}
                                    value={locationName ?? ''}
                                    label="Location"
                                    sx={{
                                        '& .Mui-focused': {
                                            color: '#424e54',
                                        },
                                        '& .Mui-focused:after': {
                                            borderBottom: 'none',
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="location" onClick={getLoaction}>
                                                    <MyLocation />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="filled"
                                    fullWidth
                                    focused
                                />
                            ) : (
                                <TextField
                                    data-cy="location"
                                    label="Location"
                                    variant="filled"
                                    fullWidth
                                    onChange={(): void => {
                                        setIsAutocomplete(true);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="location" onClick={getLoaction}>
                                                    <MyLocation />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Stack>
                    </Autocomplete>
                )}
            </Stack>
            <Stack direction={'row'} spacing={2} width={'100%'}>
                <Stack justifyContent={'space-between'} width={'50%'}>
                    <Stack spacing={2}>
                        <TextField
                            label="Latitude"
                            name="lat"
                            variant="filled"
                            value={location?.lat ?? ''}
                            onChange={locationChangeHandler}
                            fullWidth
                        />
                        <TextField
                            label="Longitude"
                            name="lng"
                            variant="filled"
                            value={location?.lng ?? ''}
                            onChange={locationChangeHandler}
                            fullWidth
                        />
                    </Stack>
                    <Button
                        variant="outlined"
                        onClick={(): void => {
                            setCoordinates(location);
                            setIsAutocomplete(false);
                        }}
                        startIcon={<Search />}
                    >
                        Search
                    </Button>
                </Stack>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    height={'232px'}
                    width={'365px'}
                >
                    {googleMapLoaded && (
                        <MapComponent
                            widgetData={{ secondary: [{ coordinates }] }}
                            clustering={false}
                            showInfoWindow={false}
                            markerSelector
                        />
                    )}
                </Stack>
            </Stack>
        </>
    );
};
