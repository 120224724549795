import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { channelsHandler, handleChannelRemove, handleGroupDevicesFilter, handleReallocateChannels } from './helpers';

const loadsInitialState: any = {
    filters: {},
    selectedNodes: [],
    secondary: [],
    viewAllButton: false,
    maxCount: 6,
    isTotalCountVisible: false,
    countMode: 'custom',
};

const loadsWidgetSlice = createSlice({
    name: 'loads',
    initialState: loadsInitialState,
    reducers: {
        resetLoadsConfiguration: () => {
            return loadsInitialState;
        },
        setPredefinedLoads: (state: RootState, action: PayloadAction<any>) => {
            Object.keys(action.payload)?.forEach((key: string) => {
                if (key === 'selectedNodes') {
                    state[key] = action.payload[key]?.map((item: any) => ({
                        ...item,
                        id: `${item.loadId}${item.deviceId}`?.replace(/-/g, '_'),
                    }));
                } else state[key] = action.payload[key];
            });
        },
        setLoads: (state: RootState, action: PayloadAction<any>) => {
            channelsHandler(state, action, 'loadId');
        },
        setLoadData: (state: RootState, action: PayloadAction<any>) => {
            state['secondary'] = action.payload;
        },
        setLoadsFilters: (state: RootState, action: PayloadAction<any>) => {
            state['filters'] = action.payload;
        },
        reAllocateLoads: (state: RootState, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        removeLoad: (state: RootState, action: PayloadAction<any>) => {
            handleChannelRemove(state, action, 'loadId');
        },
        setLoadsNodes: (state: RootState, action: PayloadAction<any>) => {
            handleGroupDevicesFilter(state, action);
        },
        setLoadsPreferences: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('widgetConfiguration/toggleDeviceLinkMode', (state) => {
            if (state.primary) {
                return { ...loadsInitialState, primary: [] };
            }
            return loadsInitialState;
        });
        builder.addCase('widgetConfiguration/setWidgetDevice', (state, action: any) => {
            if (action.payload.reset) {
                state.secondary = [];
                state.primary = [];
            }
        });
        builder.addCase('widgetConfiguration/setWidgetSource', (state) => {
            state.secondary = [];
        });
    },
});

export const {
    resetLoadsConfiguration,
    setLoadsFilters,
    setLoadsNodes,
    reAllocateLoads,
    setPredefinedLoads,
    removeLoad,
    setLoads,
    setLoadData,
    setLoadsPreferences,
} = loadsWidgetSlice.actions;
export default loadsWidgetSlice;
