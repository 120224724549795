import { api } from '../../app/api/baseApi';

export const dashboardApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllDashboards: builder.query<object, any>({
            query: ({ body, headers }: any) => ({
                url: 'ds/v1/dashboards/filter',
                method: 'POST',
                ...(headers && { headers }),
                body,
            }),
            providesTags: ['Dashboards'],
        }),
        getActiveDashboards: builder.query<object, any>({
            query: ({ params, headers }: any) => ({
                url: 'ds/v1/dashboards/active',
                ...(headers && { headers }),
                params,
            }),
            providesTags: ['ActiveDashboards'],
        }),
        deleteDashboard: builder.mutation({
            query: ({ id, headers }) => ({
                url: `ds/v1/dashboards/${id}`,
                method: 'DELETE',
                ...(headers && { headers }),
            }),
            invalidatesTags: (result, error): any => (!error ? ['Dashboards'] : []),
        }),
        createDashboard: builder.mutation({
            query: (body) => ({
                url: 'ds/v1/dashboards',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Dashboards', 'ActiveDashboards'] : []),
        }),
        updateDashboard: builder.mutation({
            query: ({ body, params }: any) => ({
                url: `ds/v1/dashboards/${params?.dashboardId}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['Dashboards', 'DashboardById', 'ActiveDashboards'] : [],
        }),
        addWidgetsToDashboard: builder.mutation({
            query: (body) => ({
                url: 'ds/v1/dashboards/widgets',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['DashboardById'] : []),
        }),
        getDashboardById: builder.query<object, any>({
            query: (dashboardId) => ({
                url: `ds/v1/dashboards/${dashboardId}`,
            }),
            keepUnusedDataFor: 0.000001,
            providesTags: ['DashboardById'],
        }),
        getDashboardsByWidgetId: builder.mutation({
            query: (id) => ({
                url: `ds/v1/dashboards/widget/${id}`,
            }),
        }),
        getAllTabs: builder.query<any, any>({
            query: (body) => ({
                url: 'ds/v1/models/tabs',
                method: 'POST',
                body,
            }),
        }),
        associateDashboards: builder.mutation({
            query: ({ body, headers }: any) => ({
                url: 'ds/v1/dashboards/associate',
                method: 'POST',
                ...(headers && { headers }),
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Dashboards'] : []),
        }),
    }),
});

export const {
    useCreateDashboardMutation,
    useGetDashboardByIdQuery,
    useGetAllDashboardsQuery,
    useDeleteDashboardMutation,
    useUpdateDashboardMutation,
    useAddWidgetsToDashboardMutation,
    useGetAllTabsQuery,
    useGetActiveDashboardsQuery,
    useGetDashboardsByWidgetIdMutation,
    useLazyGetAllDashboardsQuery,
    useAssociateDashboardsMutation,
    useLazyGetDashboardByIdQuery,
} = dashboardApi;
