import { useDebounce } from './useDebounce';
import { useGetSearchedDeviceOrGroupQuery } from '../features/deviceManagement/deviceApi';
import { isNullOrUndefined } from '../utils/helpers';

type ReturnProps = {
    isLoading: boolean;
    currentData: any;
};

export const useSearchDeviceOrGroup = ({ searchKey }: { searchKey: any }): ReturnProps => {
    const [, debouncedValue] = useDebounce(undefined, 1000, searchKey);

    const { currentData, isLoading, isFetching } = useGetSearchedDeviceOrGroupQuery(
        { searchKey: debouncedValue },
        {
            skip: isNullOrUndefined(debouncedValue) || isNullOrUndefined(searchKey),
            refetchOnMountOrArgChange: true,
        }
    );
    return { currentData: currentData?.data?.records, isLoading: isLoading || isFetching };
};
