/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import { CustomModal, CustomTransComponent } from '../../../components';
import { useConfirm } from '@fiji/common/src/hooks';
import { Avatar, Stack, Typography } from '@mui/material';
import WarningAmber from '@mui/icons-material/WarningAmber';
import { ModalActions } from '../../../components/CustomModal';
import { RenameModal } from './RenameModal';

export const RenameWidgetModal = React.forwardRef((_props: any, ref: any): JSX.Element => {
    const renameRef = React.useRef<any>(null);
    const { data, isVisible, onCancel, onClick } = useConfirm();
    const deleteModalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel();
            },
        },
        {
            key: 'continue',
            label: <CustomTransComponent translationKey={'COMMON:CONTINUE'} />,
            variant: 'contained',
            handleClick: (): void => {
                onCancel();
                renameRef?.current?.handleModalAction(true, data);
            },
        },
    ];

    const handleModalAction = (action: boolean, widgetData: any): any => {
        if (action) {
            onClick(widgetData);
            return;
        }

        renameRef?.current?.handleModalAction(true, widgetData);

        return;
    };
    React.useImperativeHandle(ref, () => ({
        handleModalAction: (action: boolean, widgetData: any): void => handleModalAction(action, widgetData),
    }));

    return (
        <>
            <CustomModal
                isOpen={isVisible}
                isLoading={false}
                type="primary"
                actions={deleteModalActions}
                customHeader={
                    <>
                        <Stack>
                            <Typography variant="body1" fontSize={'20px'} fontWeight={'600'} mb={2}>
                                <CustomTransComponent translationKey={'WIDGETS:RENAME_WIDGET_MODAL.HEADER'} />
                            </Typography>
                            <Avatar className="bg-red-50 height-96 width-96 margin-auto">
                                <WarningAmber className="color-danger height-56 width-56" />
                            </Avatar>
                        </Stack>
                        <Stack mt={2}>
                            <Typography variant="body1" fontWeight={'700'}>
                                <CustomTransComponent
                                    translationKey={'WIDGETS:RENAME_WIDGET_MODAL.WARN_TXT'}
                                    replace={{
                                        count: data?.usedCount,
                                    }}
                                />
                            </Typography>
                            <Typography variant="body1" fontWeight={'400'} mt={2}>
                                <CustomTransComponent translationKey={'WIDGETS:RENAME_WIDGET_MODAL.WARN_TXT_DESC'} />
                            </Typography>
                        </Stack>
                    </>
                }
                noDividers
                actionsDivider
            ></CustomModal>
            <RenameModal ref={renameRef} />
        </>
    );
});
