import dayjs from 'dayjs';

function getActionType(action: any): string {
    switch (true) {
        case parseInt(action?.max ?? 0) - parseInt(action?.min) > 0:
            return 'slider';
        case Boolean(action?.dataValue?.length):
            return 'dropdown';
        case parseInt(action?.max ?? 0) - parseInt(action?.min) === 0 && action?.value:
            return 'textfield';
        default:
            return 'command';
    }
}

function getPredefinedSchedule(payload: any): any {
    const newPayload = JSON.parse(JSON.stringify(payload));
    newPayload['startDate'] = new Date(newPayload['startDate'])?.toISOString();
    if (newPayload['endDate']) {
        newPayload['endDate'] = new Date(newPayload['endDate'])?.toISOString();
    }
    newPayload['actionStartTime'] = new Date(newPayload['actionStartTime'])?.toISOString();
    if (!newPayload['groupIds']?.length) {
        newPayload['isDeviceSelectable'] = true;
    }
    newPayload['groupIds'] = newPayload['groupIds']?.map((item: any) => item?.id);
    newPayload['deviceIds'] = newPayload['deviceIds']?.map((item: any) => item?.id);
    delete newPayload['deviceTypeId'];
    delete newPayload['lastRun'];
    delete newPayload['schedules'];
    delete newPayload['deviceCount'];
    delete newPayload['isEnabled'];
    delete newPayload['nextRun'];
    delete newPayload['status'];
    delete newPayload['actions'];
    for (const key in newPayload) {
        switch (key) {
            case 'endDate':
                if (newPayload[key]) {
                    newPayload['repeat'] = true;
                }
                break;

            case 'repeatOccurence':
                if (newPayload[key]) {
                    newPayload['repeatUntil'] = true;
                }
                break;

            case 'actionEndTime':
                if (newPayload[key]) {
                    newPayload['isActionEndTimeRequired'] = true;
                    newPayload['actionEndTime'] = new Date(newPayload['actionEndTime'])?.toISOString();
                }
                break;

            case 'frequency':
                switch (newPayload[key]) {
                    case 'MONTHLY':
                        break;

                    case 'WEEKLY':
                        break;

                    case 'YEARLY':
                        break;
                    default:
                        break;
                }
                break;

            default:
                break;
        }
    }

    return newPayload;
}

function constructSchedulePayload(payload: any): any {
    const newPayload = {
        name: payload?.name,
        frequency: payload.frequency,
        deviceTypeId: payload.deviceTypeId,
        startDate: new Date(payload.startDate)?.getTime(),
        endDate: new Date(payload.endDate)?.getTime(),
        actionStartTime: new Date(payload.startTime)?.getTime(),
        repeatForever: payload.repeatForever,
        repeatOccurrence: payload.repeatOccurrence,
        repeatOnDay: payload.repeatOnDay,
        repeatMonthDay: new Date(payload.repeatMonthDay)?.getTime(),
        repeatOn: payload.repeatOn,
        repeatEvery: payload.repeatEvery,
        ...(payload['repeatOn'] === 'HOLIDAY' && { yearlyHolidayCountry: payload?.frequencyHolidayCountry }),
        ...(payload['exceptionType'] === 'holiday' && { exceptionHolidayCountry: payload?.selectedCountry }),
        ...(payload?.timezone && { timezone: payload.timezone }),
        ...(payload?.repeatEveryDays && { repeatEveryDays: payload.repeatEveryDays }),
        ...(payload?.repeatEveryWeek && { repeatEveryWeek: payload.repeatEveryWeek }),
        ...(payload?.repeatEveryMonth && { repeatEveryMonth: payload.repeatEveryMonth }),
        ...(payload?.deviceIds?.length && { deviceIds: payload.deviceIds }),
        ...(payload?.groupIds?.length && { groupIds: payload.groupIds }),
        ...(payload?.isActionEndTimeRequired && { actionEndTime: new Date(payload.endTime)?.getTime() }),
        ...(payload?.excludeHolidays?.length &&
            payload?.excludeHolidays?.every((item: any) => typeof item === 'object') && {
                excludeHolidays: payload.excludeHolidays?.map((item: any) => new Date(item?.date)?.getTime()),
            }),
        ...(payload?.holidays?.length &&
            payload?.excludeHolidays?.every((item: any) => typeof item === 'object') && {
                holidays: payload.holidays?.map((item: any) => new Date(item?.date)?.getTime()),
            }),
        ...(payload?.weekDay?.length && { weekDay: payload.weekDay }),
        ...(payload?.monthList?.length && { monthList: payload.monthList }),
    };
    for (const key in payload) {
        switch (key) {
            case 'actions':
                newPayload[key] = payload[key].map((item: any) => ({
                    id: item?.key,
                    startValue: parseInt(item?.startValue) ?? parseInt(item?.defaultValue),
                    ...(item?.endValue && { endValue: parseInt(item?.endValue) }),
                }));
                break;
            case 'repeat':
                if (payload[key] === true) {
                    delete newPayload['endDate'];
                }
                break;
            case 'repeatUntil':
                if (payload[key] === true) {
                    delete newPayload['repeatOccurrence'];
                }
                break;

            case 'repeatForever':
                if (payload[key] === true) {
                    delete newPayload['endDate'];
                    delete newPayload['repeatOccurrence'];
                }
                break;

            default:
                break;
        }
        if (key === 'frequency') {
            switch (payload[key]) {
                case 'ONCE':
                    delete newPayload['endDate'];
                    delete newPayload['weekDay'];
                    delete newPayload['repeatOn'];
                    delete newPayload['monthList'];
                    delete newPayload['repeatOnDay'];
                    delete newPayload['repeatEvery'];
                    delete newPayload['holidayList'];
                    delete newPayload['repeatForever'];
                    delete newPayload['repeatMonthDay'];
                    delete newPayload['repeatEveryDays'];
                    delete newPayload['repeatEveryWeek'];
                    delete newPayload['repeatEveryMonth'];
                    delete newPayload['repeatOccurrence'];
                    break;
                case 'DAILY':
                    delete newPayload['repeatOn'];
                    delete newPayload['weekDay'];
                    delete newPayload['monthList'];
                    delete newPayload['repeatOnDay'];
                    delete newPayload['repeatEvery'];
                    delete newPayload['holidayList'];
                    delete newPayload['repeatMonthDay'];
                    delete newPayload['repeatEveryWeek'];
                    delete newPayload['repeatEveryMonth'];
                    break;

                case 'WEEKLY':
                    delete newPayload['repeatOn'];
                    delete newPayload['repeatEveryDays'];
                    delete newPayload['repeatEveryMonth'];
                    delete newPayload['monthList'];
                    delete newPayload['repeatOnDay'];
                    delete newPayload['repeatEvery'];
                    delete newPayload['holidayList'];
                    delete newPayload['repeatMonthDay'];
                    break;

                case 'MONTHLY':
                    if (payload['repeatDay']) {
                        delete newPayload['weekDay'];
                        delete newPayload['repeatEvery'];
                    } else if (payload['repeatEvery']) {
                        delete newPayload['repeatOnDay'];
                    }
                    delete newPayload['repeatEveryDays'];
                    delete newPayload['repeatEveryWeek'];
                    delete newPayload['holidayList'];
                    delete newPayload['repeatOn'];
                    delete newPayload['repeatMonthDay'];
                    break;
                case 'YEARLY':
                    if (payload['repeatDay']) {
                        delete newPayload['weekDay'];
                        delete newPayload['monthList'];
                        delete newPayload['repeatEvery'];
                    } else if (payload['repeatEvery']) {
                        delete newPayload['repeatOn'];
                        delete newPayload['holidays'];
                        delete newPayload['repeatMonthDay'];
                    }
                    delete newPayload['repeatOnDay'];
                    delete newPayload['repeatEveryDays'];
                    delete newPayload['repeatEveryWeek'];
                    delete newPayload['repeatEveryMonth'];
                    break;
                default:
                    break;
            }
        }
    }
    return newPayload;
}

function getFromToFormat(type: string, selectedDate: any): any {
    if (type === 'week') {
        return {
            from: dayjs(selectedDate)?.clone()?.startOf('week').valueOf(),
            to: dayjs(selectedDate)?.clone()?.endOf('week').valueOf(),
        };
    }
    return {
        to: new Date(selectedDate).setHours(23, 59, 59, 0),
        from: new Date(selectedDate)?.setHours(0, 0, 0, 0),
    };
}

export { getActionType, constructSchedulePayload, getFromToFormat, getPredefinedSchedule };
