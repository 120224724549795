import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { SnackbarProvider, enqueueSnackbar, closeSnackbar, SnackbarKey } from 'notistack';
import { useTypedSelector, RootState } from '@fiji/common/src/app/store';
import { Button, useTheme } from '@mui/material';
import NotificationWithIcon from './NotificationWithIcon';

export const GlobalToast = (): JSX.Element => {
    const oktaAuthData = useOktaAuth();
    const theme: any = useTheme();
    const toastData = useTypedSelector((state: RootState) => state.common.message);

    React.useEffect(() => {
        if (toastData?.message && toastData?.message?.length) {
            if (toastData?.isUnauthorized) {
                oktaAuthData?.oktaAuth
                    ?.signOut()
                    .then()
                    .catch((error) => console.error('okta error', error));
            }
            if (Array.isArray(toastData?.message)) {
                toastData?.message?.forEach((message: string) => {
                    enqueueSnackbar(message, toastData?.extraArgs);
                });
            } else {
                enqueueSnackbar(toastData?.message, toastData?.extraArgs);
            }
        }
    }, [toastData]);

    const renderSnackActionButton = (snackId: SnackbarKey): JSX.Element => (
        <Button
            size="small"
            sx={{ color: theme?.palette?.primary?.[200] }}
            onClick={(): void => closeSnackbar(snackId)}
        >
            OK
        </Button>
    );

    return (
        <SnackbarProvider
            autoHideDuration={toastData?.duration || 3000}
            maxSnack={9}
            action={(snackId: SnackbarKey): JSX.Element => renderSnackActionButton(snackId)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            preventDuplicate
            Components={{
                notificationWithIcon: NotificationWithIcon,
            }}
        />
    );
};
