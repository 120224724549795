import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

export const DevicesSubGrid = ({ devices, isGrouped }: { devices: any; isGrouped: any }): JSX.Element =>
    devices?.map((device: any) => (
        <Grid item key={device?.id}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                style={{
                    minHeight: 40,
                    margin: 0,
                }}
                className={`border-right-1 ${isGrouped ? '' : 'border-bottom-1'} padding-left-${
                    isGrouped ? '40' : '16'
                } `}
            >
                <Typography variant="body2">{device?.name ?? '-'}</Typography>
            </Stack>
        </Grid>
    ));
