import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common';
import { selectedOrg, setIsOrgInvalidated } from '@fiji/common/src/features/orgManagement/orgSlice';
import { BackdropLoader } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const OrgHome = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const currentRealmName = useTypedSelector((state: RootState) => state.common.selectedRealm);

    React.useEffect(() => {
        if (`/${currentRealmName}` === location.pathname) {
            navigate(`/${currentRealmName}/group/${currentOrg?.id}`);
            dispatch(setIsOrgInvalidated(false));
        }
    }, [currentOrg, currentRealmName]);

    return <BackdropLoader isOpen={true} />;
};
