import React from 'react';

import { AutoCompleteTags } from 'pages/AddDevice';
import { FormHelperText, Stack } from '@mui/material';
import { useGetTagsQuery } from '@fiji/common/src/features/TagManagement/tagManagementApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { TAGS_LIMIT } from '@fiji/common/src/constants';
import { setUpdateProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';

export const UpdateTagsModal = (props: { data: any; createHandler: any }): JSX.Element => {
    const {
        data: deviceTagsList,
        isLoading: isDeviceTagLoading,
        isFetching: isDeviceTagFetching,
    } = useGetTagsQuery<{
        data: any;
        isLoading: boolean;
        isFetching: boolean;
    }>({
        page: 0,
        size: 100,
    });

    const dispatch = useAppDispatch();
    const { propertiesPayload } = useTypedSelector((state) => state.device);
    React.useEffect(() => {
        if (props?.data?.isDeviceTagUpdate)
            dispatch(setUpdateProperties({ key: 'tags', value: props?.data?.tags?.map((item: any) => item?.name) }));
    }, [props?.data]);

    const selectedTagsHandler = (e: any, values: any): void => {
        if (values?.length > TAGS_LIMIT) {
            return;
        }

        dispatch(setUpdateProperties({ key: 'tags', value: values }));
    };

    let isEnteredTagValue = false;
    const handleKeyDown = async (e: any): Promise<void> => {
        if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
            isEnteredTagValue = true;
        } else if (e.code !== 'Enter' && e.code !== 'ArrowDown' && e.code !== 'ArrowUp') {
            isEnteredTagValue = false;
        }
        if (
            !isEnteredTagValue &&
            e.code === 'Enter' &&
            propertiesPayload?.tags &&
            propertiesPayload?.tags?.length < TAGS_LIMIT
        ) {
            const tagValue = e.target.value;
            const selectedTags = deviceTagsList?.data?.records?.find((item: any) => item?.name === tagValue);
            const propertiesClone = JSON.parse(JSON.stringify(propertiesPayload));

            propertiesClone.tags.push(tagValue);
            if (selectedTags) {
                dispatch(setUpdateProperties({ key: 'tags', value: propertiesClone?.tags }));
                return;
            }
            const { error }: any = await props.createHandler({
                name: tagValue,
            });

            if (!error) {
                dispatch(setUpdateProperties({ key: 'tags', value: propertiesClone?.tags }));
            }
        }
    };

    const handleTagDelete = (data: any, index: number): void => {
        const propertiesClone = JSON.parse(JSON.stringify(propertiesPayload));
        propertiesClone.tags.splice(index, 1);
        dispatch(setUpdateProperties({ key: 'tags', value: propertiesClone?.tags }));
    };

    return (
        <>
            <AutoCompleteTags
                value={propertiesPayload?.tags ?? []}
                disabled={isDeviceTagFetching || isDeviceTagLoading}
                onChange={selectedTagsHandler}
                options={deviceTagsList?.data?.records?.map((tag: any) => tag?.name) ?? []}
                handleTagDelete={handleTagDelete}
                onKeyDown={handleKeyDown}
            />
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <FormHelperText className="padding-x-16">Max of 6 tags</FormHelperText>
                <FormHelperText className="padding-x-16">{propertiesPayload?.tags?.length} /6</FormHelperText>
            </Stack>
        </>
    );
};
