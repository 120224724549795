import { Stack } from '@mui/material';
import { CustomTransComponent } from 'components/CustomTransComponent';
import React from 'react';

export const CustomPlaceholder = ({
    placeholder,
    hasValue,
}: {
    placeholder: string;
    hasValue: boolean;
}): JSX.Element => (
    <Stack
        sx={{ position: 'relative', top: '14px', fontWeight: '600' }}
        className={hasValue ? 'display-none' : 'gray-300'}
    >
        <CustomTransComponent
            translationKey={placeholder}
            size={{
                width: '130px',
                height: '10px',
            }}
        />
    </Stack>
);
